$screen-xxxl:               1560px !default;
$screen-xxxl-min:           $screen-xxxl !default;

$screen-xxl:                 1330px !default;
$screen-xxl-min:             $screen-xxl !default;
$screen-xxl-max:             ($screen-xxxl-min - 1) !default;

$screen-xl:                 1200px !default;
$screen-xl-min:             $screen-xl !default;
$screen-xl-max:             ($screen-xxl-min - 1) !default;

$screen-lg:                 992px !default;
$screen-lg-min:             $screen-lg !default;
$screen-lg-max:             ($screen-xl-min - 1) !default;

$screen-md:                 768px !default;
$screen-md-min:             $screen-md !default;
$screen-md-max:             ($screen-lg-min - 1) !default;

$screen-sm:                 576px !default;
$screen-sm-min:             $screen-sm !default;
$screen-sm-max:             ($screen-md-min - 1) !default;

$screen-xs:                 0px !default;
$screen-xs-min:             $screen-xs !default;
$screen-xs-max:             ($screen-sm-min - 1) !default;

$desktop-min: $screen-lg-min;
$mobile-max: $desktop-min - 1;
$desktop-md-min: $screen-xl-min;
$desktop-lg-min: $screen-xxl-min;
$desktop-xl-min: $screen-xxxl-min;

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$gray-e4:#e4e4e4; 
$gray-dc:#dcdcdc;
$gray-d1:#d1d1d1;
$gray-97:#979797;
$gray-4a:#4a4a4a;
$gray-36:#363636;
$gray-f8:#f8f8f8;
$gray-64:#646464;

$blue: #007bb9 !default;
$blue-1: #d6e8ec !default;
$orange: #ff8520 !default;
$purple: #b55f8e !default;
$gray-1: #282828 !default;
$gray-2: #363636 !default;
$gray-3: #484848 !default;
$gray-4: #ddd !default;
$green: #5daa51 !default;
$yellow: #efe4bf !default;
$buttermilk-yellow: #fff8b5 !default;


$dark-blue:#1f365c;
$powder-blue:#3b4859;
$lighter-blue:#cce5f1;

$hover-purple: #660066;
$hover-yellow: #ffcc00;
$hover-aquamarine:#00cccc; 
$hover-lime:#99cc66;
$hover-orange: #ff6633;


$primary: $blue;
$font-family-sans-serif:      'Nunito Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif !default;
$font-family-open-sans: 'Open Sans', sans-serif;

$font-weight-black:   900 !default;
$font-weight-bold:   800 !default;
$font-weight-medium: 700 !default;
$font-weight-600: 600 !default;
$font-weight-300: 300 !default;

@mixin font-family-fa() {display:inline-block; font:normal normal normal 14px/1 FontAwesome; font-size:inherit; text-rendering:auto; -webkit-font-smoothing:antialiased; -moz-osx-font-smoothing:grayscale;}
@mixin font-family-idsa() {font-family:'icomoon' !important; speak:none; font-style:normal; font-weight:normal; font-variant:normal; text-transform:none; line-height:1; -webkit-font-smoothing:antialiased; -moz-osx-font-smoothing:grayscale;}

$heading-1-font-size: 2.5rem   !default;
$heading-2-font-size: 1.875rem !default;
$heading-3-font-size: 1.375rem !default;
$heading-4-font-size: 1.125rem !default;

$grid-breakpoint-xl:     $screen-xl-min !default;
$container-max-width-xl: $screen-xl-min !default;

$grid-breakpoint-xxl:     1390px !default;
$container-max-width-xxl: 1330px !default;

$grid-breakpoint-xxxl:     1560px !default;
$container-max-width-xxxl: 1560px !default;



$table-thead-bg: $primary !default;
$table-thead-color: $white !default;

$breadcrumb-padding-y:              .75rem !default;
$breadcrumb-padding-x:              0 !default;

$breadcrumb-bg:                     transparent !default;
$breadcrumb-divider-color:          $gray-900 !default;
$breadcrumb-active-color:           $gray-900 !default;
$breadcrumb-divider:                quote(">") !default;

$card-cap-bg: $primary;

$facebook: #3B5998 !default;
$linkedin: #0077B5 !default;
$twitter: #00aced !default;
$instagram: #c84274 !default;
$androidgreen:#a4c639;
$appleblue:#147efb;
$appleredA:#fc3158;
$appleredB:#fc3d39;

$blueboxshadow:#163850;

$teal: #009193;
$dark-gray: #404040;
$footer-gray: #2c2c2c;
$border-light-gray: #DADADA;

@mixin placeHolderColor($color) {
    //these must be individual lines otherwise it will not work
    //pseudo classes placeholder text
    &::-webkit-input-placeholder {color:$color; font-style:normal;}
    &::-moz-placeholder {color:$color; font-style:normal;}
    &:-ms-input-placeholder {color:$color; font-style:normal;}
    &:-moz-placeholder {color:$color; font-style:normal;}
    &::placeholder {color:$color; font-style:normal;}
    //input element
    &:placeholder-shown {color:$color; font-style:normal;}
}

@mixin filter-gradient($start-color, $end-color) {
    background:rgba($start-color, 1);
    background:-moz-linear-gradient(left, rgba($start-color,1) 0%, rgba($end-color,1) 100%);
    background:-webkit-linear-gradient(left, rgba($start-color,1) 0%,rgba($end-color,1) 100%);
    background:linear-gradient(to right, rgba($start-color,1) 0%,rgba($end-color,1) 100%);
    filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}',GradientType=1 );
}

@mixin createBodyGradientTB ($colorA, $opacityA, $posA, $colorB, $opacityB, $posB, $colorC, $opacityC, $posC) {
    background: -moz-linear-gradient(top, rgba($colorA,$opacityA) $posA, rgba($colorB,$opacityB) $posB, rgba($colorC, $opacityC) $posC);
    background: -webkit-linear-gradient(top, rgba($colorA,$opacityA) $posA, rgba($colorB,$opacityB) $posB, rgba($colorC, $opacityC) $posC);
    background: linear-gradient(to bottom, rgba($colorA,$opacityA) $posA, rgba($colorB,$opacityB) $posB, rgba($colorC, $opacityC) $posC);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str(rgba($colorA,$opacityA))}', endColorstr='#{ie-hex-str(rgba($colorC,$opacityC))}', gradientType=0);
}