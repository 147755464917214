@use '_variables' as *;
@use '_mixins' as *;

:root {
    --toc-gradient-percent: 0%;
}

.toc-wrapper {
    --block-padding: #{toRem(36)};
    --inline-padding: #{toRem(24)};
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    display: none;
    flex-direction: row-reverse;

    &.is-open {
        display: flex;
        background-color: rgb(0, 0, 0, .33);
    }

    @media all and (min-width:$screen_lg_min) {
        --block-padding: #{toRem(48)};
        --inline-padding: #{toRem(42)};
        display: flex !important;
        position: sticky;
        top: toRem(80);
        left: auto;
        height: auto;
        z-index: auto;
        background-color: transparent;
        flex-direction: row;
    }
    @media screen and (min-width: $screen-lg-min) {
        max-height: 85vh;
    }
    .toc-table {
        padding-block: var(--block-padding);
        padding-inline: 0;
        background-color: $white;
        height: 100%;
        width: 80%;

        @media all and (min-width:$screen_lg_min) {
            border-radius: toRem(36);
            height: auto;
            width: auto;
            flex: 1;
        }

        &__btn-wrapper {
            display: flex;
            justify-content: end;
            margin-block-end: toRem(24);

            @media all and (min-width:$screen_lg_min) {
                display: none;
            }

            .toc-btn-close {
                padding: 0;
                border: none;
                background-color: transparent;
                width: toRem(20);
                height: toRem(20);
            }
        }

        &__title {
            font-size: toRem(30);
            line-height: 0.99;
            font-weight: $fw_regular;
            font-style: normal;
            margin-block-end: toRem(28);
            padding-left: var(--inline-padding);

            @media all and (min-width:$screen_lg_min) {
                margin-block-end: toRem(18);
            }
        }

        &__progress {
            display: none;
            margin-block-end: toRem(32);
            background: linear-gradient(90deg, $purple var(--toc-gradient-percent), $beige var(--toc-gradient-percent));
            border-radius: toRem(100);
            height: toRem(8);
            width: calc(100% - (var(--inline-padding) * 2));
            justify-self: center;
            margin-left: auto;
            margin-right: auto;

            @media all and (min-width:$screen_lg_min) {
                display: block;
            }
        }

        &__chapters {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: toRem(24);
            list-style-type: none;
            padding: 0;
            margin: 0;
            overflow-y: scroll;
            height: calc(100dvh - 144px);

            // Scrollbar styling
            &::-webkit-scrollbar {
                width: 10px;
            }

            &::-webkit-scrollbar-track {
                background: transparent;
                border-radius: 10px; // Rounded corners
            }

            &::-webkit-scrollbar-thumb {
                background: #b0b0b0; // Medium gray thumb
                border-radius: 10px; // Rounded corners
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #909090; // Darker gray on hover
            }
            .toc-chapter {
                color: $grayish-green;
                font-size: toRem(16);
                font-weight: $fw_regular;
                line-height: 1.25;
                letter-spacing: toRem(0.32);
                width: calc(100% - (var(--inline-padding) * 2));

                a {
                    color: inherit;
                    font-weight: inherit;
                    font-size: toRem(16);
                }

                &.active {
                    color: $green;
                    font-weight: $fw_bold;
                    position: relative;

                    &::before {
                        position: absolute;
                        left: calc(-1 * var(--inline-padding));
                        width: toRem(4);
                        height: 100%;
                        border-radius: toRem(12);
                        background-color: $green;
                        content: '';
                        
                        @media all and (min-width:$screen_lg_min) {
                            left: calc(-1 * var(--inline-padding) + #{toRem(1)});
                        }
                    }
                }
            }
            @media screen and (min-width: $screen-lg-min) {
                height: calc(100% - 6rem);
            }

        }
    }
}

.toc-mobile-cntrl {
    @media all and (min-width:$screen_lg_min) {
        display: none;
    }

    &__progress {
        position: fixed;
        bottom: 0;
        left: 0;
        background: linear-gradient(90deg, $purple var(--toc-gradient-percent), $white var(--toc-gradient-percent));
        height: toRem(8);
        width: 100%;
    }

    &__btn {
        position: fixed;
        bottom: toRem(54);
        right: toRem(28);
        padding: 0;
        border: none;
        border-radius: 100%;
        background-color: $white;
        width: toRem(82);
        height: toRem(82);
        box-shadow: rgb(0, 0, 0, 0.12) 0 toRem(4) toRem(44) 0;
    }
}
