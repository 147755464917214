@use '_variables' as *;
@use '_font-face.scss' as *;
@use "libraries/bootstrap-5.3.3/scss/bootstrap.scss" as bs;

html,
body {
    @include inter;
    color: $body-color;
    font-size: toRem(16);
    line-height: 1.49;
}

.text {

    &-large,
    &-lead {
        font-size: toRem(20);
        line-height: 1.60;
    }

    @media all and (min-width:$desktop_min) {

        &-large,
        &-lead {
            font-size: toRem(24);
        }
    }

    &-bold {
        font-weight: $fw_bold;
    }
}

p {
    &.small {
        font-size: toRem(14);
    }

    &.large {
        font-size: toRem(20);
        line-height: 1.60;
    }
}

ul {
    li {
        &::marker {
            color: var(--site-primary-color);
        }
    }
}

//b, strong {font-weight:$fw_bold;}

a {
    color: var(--site-primary-color);
    font-weight: $fw_semibold;
    text-decoration: none;

    &:hover,
    &:focus {
        @include textUnderlineCustom;
    }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    color: var(--site-primary-color);
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
    @include quadrant_400;

    em,
    i,
    .em,
    .i {
        font-family: $quadrant-family-italic;
        font-style: italic;
    }
}

h1,
.h1 {
    font-size: toRem(60);
    line-height: 1;
}

h2,
.h2 {
    font-size: toRem(40);
    line-height: 1.1;
    scroll-margin-block-start: toRem(72);
}

h3,
.h3 {
    font-size: toRem(30);
    line-height: 1.14;
}

h4,
.h4 {
    font-size: toRem(26);
    line-height: 1.1;
}



h5, .h5, h6, .h6 {@include inter;}
h5,
.h5 {
    font-size: toRem(20);
    font-weight: $fw_semibold;
    line-height: 1.44;
    letter-spacing: toRem(0.4);
}

h6,
.h6 {
    font-size: toRem(15);
    font-weight: $fw_semibold;
    line-height: 1.06;
    letter-spacing: toRem(0.3);
}

.h3-wysiwyg {
    @extend .h3;
    font-size: 23px;
}
.h4-wysiwyg {
    @extend .h4;
    font-size: 18px;
    font-weight: bs.$font-weight-semibold;
}
.h5-wysiwyg {
    @extend .h5;
    font-size: 17px;
    font-weight: bs.$font-weight-semibold;
}
.h6-wysiwyg {
    @extend .h6;
    font-size: 15px;
    font-weight: bs.$font-weight-medium;
}

//h4, .h4, h5, .h5, h6, .h6 {line-height:1.5;}
//h3, .h3, h4, .h4, h5, .h5, h6, .h6 {font-weight:$fw_semibold;}
@media all and (min-width:$screen_xxl_min) {

    html,
    body {}

    .idsa-main-content {
        font-size: toRem(18);
    }

    p {
        &.small {
            font-size: toRem(16);
        }

        &.large {
            font-size: toRem(24);
        }
    }

    h1,
    .h1 {
        font-size: toRem(85);
    }

    h2,
    .h2 {
        font-size: toRem(55);
        scroll-margin-block-start: toRem(80);
    }

    h3,
    .h3 {
        font-size: toRem(40);
        line-height: 1.2;
    }

    h4,
    .h4 {
        font-size: toRem(30);
        line-height: 1.2;
    }

    h5,
    .h5 {
        font-size: toRem(22);
        line-height: 1.41;
        letter-spacing: toRem(0.44);
    }

    h6,
    .h6 {
        font-size: toRem(18);
        letter-spacing: toRem(0.36);
    }
}

strong,
b {
    font-weight: $fw_bold;
}

blockquote,
.blockquote {
    @include quadrant;
    color: var(--site-accent-text-color);
    font-size: toRem(40);
    line-height: 1.45;
    //&::before {display:inline; content:"“";}
    //&::after {display:inline; content:"”";}
}

.blockquote-footer {
    display: inline-flex;
    align-items: center;
    margin-top: toRem(16);
    color: var(--site-accent-text-color);
    font-size: toRem(16);
    font-weight: $fw_medium;
    line-height: .99;

    &::before {
        display: block;
        margin-right: toRem(12);
        width: toRem(50);
        height: 2px;
        background: var(--site-accent-text-color);
        content: "";
    }
}

$cta-w: 14px;
$cta-h: $cta-w;
$cta-timer: 0.5s;
$fas-w: 8;

%btn-cta-arrow {
    $fas-w: 10;

    .btn-cta-arrow {
        flex-grow: 0;
        flex-shrink: 0;
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        margin-left: toRem(8);
        width: $cta-w;
        height: $cta-h;
        text-align: center;
        font-size: toEm(12, 16);
        line-height: toRem(16);

        &::before,
        &::after {
            display: block;
            text-decoration: none;
            flex-shrink: 0;
            flex-grow: 0;
        }

        &::before {
            order: 2;
            width: #{$fas-w + px};
            height: #{$fas-w + px};
            border: 2px solid currentColor;
            border-left: none;
            border-top: none;
            border-radius: 1px;
            content: "";
            transform: rotate(-45deg);
        }

        &::after {
            order: 1;
            margin-right: #{-$fas-w + px};
            width: 0;
            height: 2px;
            border-radius: 2px;
            background: currentColor;
            content: "";
            transition: width $cta-timer ease-in-out;
        }
    }

    &:not(.disabled):not(:disabled) {

        &:hover,
        &:focus,
        &:focus:not(:focus-visible),
        &:focus-visible,
        &:focus-within {
            .btn-cta-arrow {
                &::after {
                    width: $cta-w;
                }
            }
        }
    }
}

%btn-cta-arrow-lg {
    @extend %btn-cta-arrow;
    $fas-w: 12;
    $cta-w: 14px;
    $cta-h: $cta-w;

    .btn-cta-arrow {
        width: $cta-w;
        height: $cta-h;

        &::before,
        &::after {}

        &::before {
            width:#{$fas-w + px};
            height:#{$fas-w + px};
        }

        &::after {
            margin-right:#{-$fas-w + px};
        }
    }

    &:not(.disabled):not(:disabled) {

        &:hover,
        &:focus,
        &:focus:not(:focus-visible),
        &:focus-visible,
        &:focus-within {
            .btn-cta-arrow {
                &::after {
                    width: $cta-w;
                }
            }
        }
    }
}

@mixin btnSm {--bs-btn-padding-x:#{toRem(29)}; --bs-btn-padding-y:#{toRem(9)}; --bs-btn-font-size:#{toRem(14)}; --bs-btn-font-weight:#{$fw_semibold}; --bs-btn-line-height:1; --bs-btn-border-radius:#{toRem(8)}; font-size: toRem(14);}

.btn {
    font-size: toRem(16);

    &:hover,
    &:focus,
    &:focus:not(:focus-visible),
    &:focus-visible,
    &:focus-within {
        @include textUnderlineCustom;
    }

    &.btn-cta {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        //.fas.fa-chevron-right, .fa-solid.fa-chevron-right {position:relative; display:flex; flex-wrap:nowrap; justify-content:center; align-items:center; margin-//left:toRem(8); width:toRem($cta-w); height:toRem($cta-h); text-align:center; font-size:toEm(12,16); line-height:toRem(16);
        //    &::before, &::after {display:block; text-decoration:none;}
        //    &::before {order:2; margin-left:toRem(-$fas-w);}
        //    &::after {order:1; margin-right:toRem(2); width:0; height:toRem(2); border-radius:toRem(2); background:currentColor; content:""; transition:width $cta-/timer /ease-in-out;}
        //}
        //&:hover, &:focus, &:focus:not(:focus-visible), &:focus-visible, &:focus-within {
        //    .fas.fa-chevron-right, .fa-solid.fa-chevron-right {
        //        &::after {width:toRem($cta-w);}
        //    }
        //}
        .fas.fa-chevron-right,
        .fa-solid.fa-chevron-right {
            position: relative;
            display: flex;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            margin-left: toRem(8);
            width: $cta-w;
            height: $cta-h;
            text-align: center;
            font-size: toEm(12, 16);
            line-height: toRem(16);

            &::before,
            &::after {
                display: block;
                text-decoration: none;
                flex-shrink: 0;
                flex-grow: 0;
            }

            &::before {
                order: 2;
                width: #{$fas-w + px};
            }

            &::after {
                order: 1;
                margin-right: #{-$fas-w + px};
                width: 0;
                height: 2px;
                border-radius: 2px;
                background: currentColor;
                content: "";
                transition: width $cta-timer ease-in-out;
            }
        }

        &:not(.disabled):not(:disabled) {

            &:hover,
            &:focus,
            &:focus:not(:focus-visible),
            &:focus-visible,
            &:focus-within {

                .fas.fa-chevron-right,
                .fa-solid.fa-chevron-right {
                    &::after {
                        width: $cta-w;
                    }
                }
            }
        }

        @extend %btn-cta-arrow;

        &:not([href^="/"], [href^="#"], [href^="~"]) {
            .btn-cta-arrow {transform: rotate(-45deg) translateY(-0.1em);}
        }
        //$fas-w:10;
        //.btn-cta-arrow {position:relative; display:flex; flex-wrap:nowrap; justify-content:center; align-items:center; margin-left:toRem(8); width:$cta-w; height:$cta-/h;/ text-align:center; font-size:toEm(12,16); line-height:toRem(16);
        //    &::before, &::after {display:block; text-decoration:none; flex-shrink:0; flex-grow:0;}
        //    &::before {order:2; width:#{$fas-w + px}; height:#{$fas-w + px}; border:2px solid currentColor; border-left:none; border-top:none; border-radius:1px; //content:"";  transform:rotate(-45deg);}
        //    &::after {order:1; margin-right:#{-$fas-w + px}; width:0; height:2px; border-radius:2px; background:currentColor; content:""; transition:width $cta-timer //ease-in-out;}
        //}
        //&:not(.disabled):not(:disabled) {
        //    &:hover, &:focus, &:focus:not(:focus-visible), &:focus-visible, &:focus-within {
        //        .btn-cta-arrow {
        //            &::after {width:$cta-w;}
        //        }
        //    }
        //}
    }

    &.btn-primary {
        --bs-btn-color: var(--site-accent-text-color);
        --bs-btn-bg: #{$yellow};
        --bs-btn-border-color: #{$yellow};
        --bs-btn-hover-color: var(--site-accent-text-color);
        --bs-btn-hover-bg: #{$active-yellow};
        --bs-btn-hover-border-color: #{$active-yellow};
        //--bs-btn-focus-shadow-rgb:49, 132, 253;
        --bs-btn-active-color: var(--site-primary-color);
        --bs-btn-active-bg: #{$active-yellow};
        --bs-btn-active-border-color: #{$active-yellow};
        --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        --bs-btn-disabled-color: #{$text-disabled};
        --bs-btn-disabled-bg: #{$bg-disabled};
        --bs-btn-disabled-border-color: #{$bg-disabled};

        &:hover,
        &:focus,
        &:focus:not(:focus-visible),
        &:focus-visible,
        &:focus-within {
            text-decoration: none;
        }

        &:not(.disabled):not(:disabled) {

            &:hover,
            &:focus,
            &:focus:not(:focus-visible),
            &:focus-visible,
            &:focus-within {
                border-radius: toRem(28);
            }
        }
    }

    &.btn-secondary {
        --bs-btn-color: var(--site-accent-text-color);
        --bs-btn-bg: none;
        --bs-btn-border-color: none;
        --bs-btn-hover-color: var(--site-accent-text-color);
        --bs-btn-hover-bg: none;
        --bs-btn-hover-border-color: none;
        //--bs-btn-focus-shadow-rgb:49, 132, 253;
        --bs-btn-active-color: var(--site-accent-text-color);
        --bs-btn-active-bg: none;
        --bs-btn-active-border-color: none;
        --bs-btn-active-shadow: none;
        --bs-btn-disabled-color: #{$text-disabled};
        --bs-btn-disabled-bg: none;
        --bs-btn-disabled-border-color: none;

        &.btn-invert {
            --bs-btn-color: #{$white};
            --bs-btn-hover-color: #{$white};
            --bs-btn-active-color: #{$white};
        }
    }

    &.btn-text {

        &.btn-invert {
            --bs-btn-color: #{$white};
            --bs-btn-hover-color: #{$white};
            --bs-btn-active-color: #{$white};
        }
    }

    &.btn-sm, &.btn-small {@include btnSm;}

    & + .btn {
        margin-left: var(--bs-btn-padding-x);
    }
}