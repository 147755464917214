@use '_variables' as *;

:root {
    //--bs-gutter-x:#{toRem(32)};
}
.idsa-main-content {
    .container-idsa {--bs-gutter-x:#{toRem(64)}; margin-left:auto; margin-right:auto; max-width:$main-content-container-max-width; padding-left:calc(var(--bs-gutter-x) * 0.5); padding-right:calc(var(--bs-gutter-x) * 0.5);}

    .container-xxxl, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container, .container-fluid, .container-idsa {max-width: $main-content-container-max-width;
        .container-xxxl, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container, .container-fluid, .container-idsa {--bs-gutter-x:0;}
    }

        @media all and (min-width:$screen_xxl_min) {
    
            .container-xxxl,
            .container-xxl,
            .container-xl,
            .container-lg,
            .container-md,
            .container-sm,
            .container,
            .container-fluid,
            .container-idsa {
                --bs-gutter-x:#{$desktop-main-content-internal-side-padding};
    
                .container-xxxl,
                .container-xxl,
                .container-xl,
                .container-lg,
                .container-md,
                .container-sm,
                .container,
                .container-fluid,
                .container-idsa {
                    --bs-gutter-x: 0;
                }
            }
        }
}
.col-12 {
    > .ctablock-main, > .content-block {
        padding-left: 2rem;
        padding-right: 2rem;
        max-width: $main-content-container-max-width;
        margin: 0 auto $main-content-container-margin-bottom auto;
    }

}
//.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {--bs-gutter-x:#{toRem(32)};}
//.container-xxxl {1652}