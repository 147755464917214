@use '_variables' as *;

.eventmultimediapage-container {
    margin-bottom: toRem(64);

    .container, .container-fluid, .container-xxxl, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
    }
}

.eventmultimediapage-row {
}

.eventmultimediapage-col-left {
    flex: 1 1 100%;
}

.eventmultimediapage-col-right {
    flex: 1 1 100%;
}

.eventmultimediapage {
    &-external {
        display: flex;
        flex-direction: column;
        margin-bottom: toRem(32);
        gap: toRem(16);

        &-link {
            background: $white;
            padding: toRem(10);
            width: 100%;
            display: inline-flex;
            color: $text-black;
            align-items: center;
            border-radius: toRem(10);
            font-weight: 400;

            &-logo {
                height: toRem(33);
                width: toRem(33);
                margin-right: toRem(10);
            }
        }

        &-link:hover {
            text-decoration: none;
        }
    }

    &-transcript {
        &-link {
            margin-bottom: toRem(20);
            display: inline-flex;
        }
    }

    &-header {
        color: $green;
        margin-bottom: toRem(12);
        font-weight: $fw_semibold;
        line-height: .99;
    }

    &-details {
        margin-bottom: toRem(36);

        &-title {
            font-weight: $fw_bold;
        }

        &-icon {
            width: toRem(16);
            height: toRem(18);

            &-bg {
                background: $grey-purple;
                border-radius: 50%;
                margin-right: toRem(10);
                display: inline-flex;
                height: toRem(36);
                width: toRem(36);
                justify-content: center;
                align-items: center;
            }
        }

        &-section {
            margin-bottom: toRem(20);
            align-items: center;
        }

        &-tags {
            display: inline-flex;
            flex-wrap: wrap;
            gap: toRem(10);

            &-tag, &-members, &-sponsored, &-datechange {
                padding: 6px 12px;
                font-size: toRem(10);
                font-weight: $fw_bold;
                text-transform: uppercase;
                border-radius: toRem(25);
                letter-spacing: toRem(1.8);
            }

            &-tag {
                background: $light-green;
                color: $green;
            }

            &-members {
                background: $grey-purple;
                color: $purple;
            }

            &-sponsored {
                background: transparent;
                color: $green;
            }

            &-datechange {
                background: $buttermilk-yellow;
                color: $green;
            }
        }
    }
}

.eventmultimediapage-contact {
    margin-bottom: toRem(36);
}

.eventmultimediapage-contact-header {
    color: $green;
    margin-bottom: toRem(12);
    font-weight: $fw_semibold;
    line-height: .99;
}

.eventmultimediapage-contact-email {
    display: block;

    a {
        color: $green;
        font-weight: $fw_semibold;
        letter-spacing: toRem(0.36);
        text-decoration: none;

        &:hover, &:focus {
            text-decoration: underline;
        }
    }
}

@media all and (min-width:$screen_xl_min) {
    .eventmultimediapage-row {
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    .eventmultimediapage-col-left {
        max-width: toRem(940);
    }

    .eventmultimediapage-col-right {
        flex: 0 0 toRem(400);
        width: toRem(400);
    }
}

.pagenotfound-image {
    img {
        max-width: 100%;
        border-radius: 900px;
    }
}
