@use '_variables' as *;

%lineclamp {display:-webkit-box; -webkit-box-orient:vertical; overflow:hidden; text-overflow:ellipsis;}
//@mixin lineclamp-set {display:-webkit-box; -webkit-box-orient:vertical; overflow:hidden; text-overflow:ellipsis;}
//@mixin lineclamp-unset {-webkit-box-orient:initial; overflow:visible; text-overflow:initial;}
@each $lc in (0,1,2,3,4,5,6,7,8,9,10) {
    .line-clamp-#{$lc} {@extend %lineclamp; -webkit-line-clamp:#{$lc};}
}

$idsa-colors: (
    "site-primary" : 'var(--site-primary-color)',
    "site-accent" : 'var(--site-accent-color)',
    "site-accent-active" : 'var(--site-accent-active-color)',
    "site-accent-text" : 'var(site-accent-text-color)',
    "green": $green,
    "buttermilk-yellow": $buttermilk-yellow,
    "grayish-green": $grayish-green,
    "light-green": $light-green,
    "purple": $purple,
    "light-purple": $light-purple,
    "red": $red,
    "yellow": $yellow,
    "active-yellow": $active-yellow,
    "grey-disabled": $grey-disabled,
    "grey-purple": $grey-purple,
    "dark-grey": $dark-grey,
    "beige": $beige,
    "dark-beige": $dark-beige,
    "white": $white,
    "offwhite": $offwhite,
    "neutral-50": $neutral-50,
    "neutral-100": $neutral-100,
    "neutral-200": $neutral-200,
    "neutral-300": $neutral-300,
    "neutral-400": $neutral-400,
    "neutral-500": $neutral-500,
    "neutral-600": $neutral-600,
    "neutral-700": $neutral-700,
    "neutral-800": $neutral-800,
    "neutral-900": $neutral-900,
    "pure-black": $black,
    "grey": $text-grey,
    "black": $text-black,
    "disabled-text": $text-disabled,
    "disabled-bg": $bg-disabled,
    "body-color": $body-color,
    "body-alt-color": $body-alt-color,
    "green-100": $green-100,
    "green-200": $green-200,
    "green-300": $green-300,
    "green-400": $green-400,
    "yellow-100": $yellow-100,
    "yellow-200": $yellow-200,
    "yellow-300": $yellow-300,
    "yellow-400": $yellow-400,
    "orange-100": $orange-100,
    "orange-200": $orange-200,
    "orange-300": $orange-300,
    "orange-400": $orange-400,
    "purple-100": $purple-100,
    "purple-200": $purple-200,
    "purple-300": $purple-300,
    "purple-400": $purple-400,
    "blue-100": $blue-100,
    "blue-200": $blue-200,
    "blue-300": $blue-300,
    "blue-400": $blue-400,
    "gray-100": $gray-100,
    "gray-200": $gray-200,
    "gray-300": $gray-300,
    "gray-400": $gray-400,
) !default;

@each $cname, $cvalue in $idsa-colors {
    .text-#{$cname}, .#{$cname}-text {color:#{$cvalue} !important;}
    .bg-#{$cname} {background-color:#{$cvalue};}
    .border-#{$cname} {border-color:#{$cvalue} !important;}
}

$custom-breakpoints:(
    sm:$screen_sm_min,
    md:$screen_md_min,
    lg:$screen_lg_min,
    xl:$screen_xl_min,
    xxl:$screen_xxl_min,
    3xl:$screen_3xl_min
) !default;
@each $key, $value in $custom-breakpoints {
    @media all and (min-width:$value) {
        @each $cname, $cvalue in $idsa-colors {
            .text-#{$key}-#{$cname}, .#{$cname}-text-#{$key} {color:#{$cvalue} !important;}
            .bg-#{$key}-#{$cname} {background-color:#{$cvalue};}
            .border-#{$key}-#{$cname} {border-color:#{$cvalue} !important;}
        }

        %lineclamp-#{$key} {display:-webkit-box; -webkit-box-orient:vertical; overflow:hidden; text-overflow:ellipsis;}
        @each $lc in (0,1,2,3,4,5,6,7,8,9,10) {
            .line-clamp-#{$key}-#{$lc} {@extend %lineclamp-#{$key}; -webkit-line-clamp:#{$lc};}
        }
    }
}