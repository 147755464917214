@use "variables" as *;
@use "./idsociety/variables" as id_variables;
@use "./idsociety/mixins" as *;

.event-listing {
    .event-listing-header-title {
        font-style: normal;

        >span {
            font-style: italic;
        }
    }

    >ul {
        list-style-type: none;
        padding-left: 0;
        margin-bottom: 0.5rem;

        .event-list-item {
            @include cardArrowAnimated();
            position: relative;
            display: flex;
            margin-bottom: 2rem;
            background-color: $white;
            border-radius: 1.5rem;
            padding-top: 2rem;
            padding-bottom: 2rem;
            overflow: hidden;

            .card-calendar {
                width: auto;
                flex: initial;
                padding-top: 0;

                .events-featuredlisting-calendar {
                    margin-bottom: 0;
                    // this magic number was also present in the original calendar implementation so I kept it.
                    min-width: 8.375rem;
                }

                .events-featuredlisting-calendar-yy,
                .events-featuredlisting-item-title {
                    display: none;
                }
            }

            >div {
                .event-list-item-tag-container {
                    list-style-type: none;
                    padding-left: 0;
                    margin-bottom: 0.5rem;

                    .event-list-item-tag {
                        text-transform: uppercase;

                        // pill-green is applied as default but then we style
                        // each tag differently.
                        &.Event {}

                        &.sponsored {
                            background-color: transparent;

                            &:first-child {
                                padding-left: 0;
                            }
                        }

                        &.member-only {
                            background-color: id_variables.$light-purple;
                            color: $purple;

                            .virus-icon {
                                color: $purple;
                                margin-right: 0.25rem;
                                font-size: 1rem;
                            }
                        }
                    }
                }

                .event-list-item-title-section {
                    display: block;
                    width: 100%;
                    margin-bottom: 1rem;

                    .event-list-item-title {
                        font-size: 1.5rem;
                        line-height: 1.5rem;
                        color: var(--bs-body-color) !important;

                        &:hover,
                        &:focus {
                            color: var(--bs-green) !important;
                        }

                        .event-list-item-date {
                            font-size: 1rem;
                            font-weight: 500;
                        }
                    }
                }

                .event-list-item-location {
                    .marker-icon {
                        background: id_variables.$light-purple;
                        width: 1.5rem;
                        height: 1.5rem;
                        border-radius: 50%;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        color: $purple;
                    }

                    .event-list-item-location-text {
                        margin-top: 0.5rem;
                        display: inline;
                    }
                }
            }
        }
    }

    .event-listing-pagination {
        list-style-type: none;
        padding-left: 0;
        display: flex;

        .page-button {
            width: 2.5rem;
            height: 2.5rem;
            margin-right: 1rem;
            background: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0.5rem;

            &.prev,
            &.next {

                &:hover,
                &:focus {
                    text-decoration: none !important;
                }
            }

            >a {
                color: var(--bs-body-color) !important;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &.active {
                border: 2px solid var(--bs-green);

                >a {
                    color: var(--bs-green) !important;
                }
            }


            &.disabled {
                border: 2px solid var(--bs-gray-600) !important;
                pointer-events: none;

                >a {
                    color: var(--bs-gray-600) !important;
                    pointer-events: none;
                }
            }
        }
    }
}