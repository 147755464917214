@use "sass:math";
@use 'sass:color';

$remBase:16;

$main-content-container-padding-bottom: 3rem;
$main-content-container-margin-bottom: 2rem;
$main-content-container-max-width: 1760px;
$desktop-main-content-internal-side-padding: 10.75rem;

$header-utility-height: 46px;
$screen_3xl:                1600px !default;
$screen_3xl_min:            $screen_3xl !default;

$screen_xxl:                1440px !default;
$screen_xxl_min:            $screen_xxl !default;
$screen_xxl_max:            ($screen_3xl_min - 1) !default;

$screen_xl:                 1200px !default;
$screen_xl_min:             $screen_xl !default;
$screen_xl_max:             ($screen_xxl_min - 1) !default;

$screen_lg:                 992px !default;
$screen_lg_min:             $screen_lg !default;
$screen_lg_max:             ($screen_xl_min - 1) !default;

$screen_md:                 768px !default;
$screen_md_min:             $screen_md !default;
$screen_md_max:             ($screen_lg_min - 1) !default;

$screen_sm:                 576px !default;
$screen_sm_min:             $screen_sm !default;
$screen_sm_max:             ($screen_md_min - 1) !default;

$screen_xs:                 0px !default;
$screen_xs_min:             $screen_xs !default;
$screen_xs_max:             ($screen_sm_min - 1) !default;

$desktop_min:$screen_lg_min;

$gutter-x:32;

@mixin sectionMargins ($smg-offset: 0, $smg-offset-lg:0) {margin-bottom:toRem(72 + $smg-offset);
    @media all and (min-width:$screen_lg_min) {
        & {margin-bottom:toRem(120 + $smg-offset-lg);
            .standardpage-col-right & {margin-bottom:toRem(72);}
        }
    }
}

$green-100:#c0e8b6;
$green-200:#7ecf69;
$green-300:#47763c;
$green-400:#33542b;

$yellow-100:#fff8b5;
$yellow-200:#fef151;
$yellow-300:#fce700;
$yellow-400:#e8d500;

$orange-100:#fcdb99;
$orange-200:#fbc65f;
$orange-300:#fab630;
$orange-400:#a16b00;

$purple-100:#e0dbff;
$purple-200:#c2bced;
$purple-300:#a79fdc;
$purple-400:#66648f;

$blue-100:#daedf7;
$blue-200:#a2c3d5;
$blue-300:#6f92a4;
$blue-400:#526f7e;

$gray-100:#f0efe9;
$gray-200:#d0cfca;
$gray-300:#6b6a68;
$gray-400:#242a22;

$plum-400:#6a2554;

$green:$green-400; //#255427
$buttermilk-yellow: $yellow-100;
$grayish-green: #667A67;
$light-green:#ddf1dd;
$purple:#686798;
$light-purple:#ede8f8;
$red:#e55454;
$yellow:$yellow-200; //#fef472;
$active-yellow:$yellow-300; //$yellow-200; //#fff319
$grey-disabled:#d6d6d6;
$grey-purple:#e7e5ec;
$dark-grey:#707070; //#757575;
$select-grey:#757575;
$beige:#f6f4ee;
$dark-beige:#f0ece0;

$white:#ffffff;
$offwhite:$beige;
$neutral-50:#f8f8f9;
$neutral-100:#f3f4f4;
$neutral-200:#e7e8e9;
$neutral-300:#d9d9d9;
$neutral-400:#b4b4b4;
$neutral-500:#9ea4a9;
$neutral-600:#868d93;
$neutral-700:#566068;
$neutral-800:#25333d;
$neutral-900:#0d1c27;
$black:#000000;

$text-grey:$dark-grey;
$text-black:#393939;
$text-green:$green;
$text-disabled:#858585;
$bg-disabled:#eeeeee;

$body-color:$text-black;
$body-alt-color:#404040;

$table-bg:#fbfaf7;
$table-border:#e6e6e6;

$dot-grey: #6B6B6B;
$dot-blue: $blue-300;
$dot-yellow: #FAB631;
$dot-green: $green-200;

$fw_thin:100;
$fw_extralight:200;
$fw_light:300;
$fw_regular:400;
$fw_medium:500;
$fw_semibold:600;
$fw_bold:700;
$fw_extrabold:800;
$fw_black:900;

$hivma-purple: $purple-400;
$hivma-purple-dark: #484664;

$foundation-purple:$plum-400;
$foundation-purple-dark:$text-black;

$inter-family:"Inter", sans-serif;
@mixin inter() {font-family:$inter-family; font-optical-sizing:auto; font-style:normal; font-variation-settings:"slnt" 0;}
@mixin inter_i() {font-family:$inter-family; font-optical-sizing:auto; font-style:italic; font-variation-settings:"slnt" -10;}
@mixin inter_100() {@include inter; font-weight:$fw_thin;}
@mixin inter_200() {@include inter; font-weight:$fw_extralight;}
@mixin inter_300() {@include inter; font-weight:$fw_light;}
@mixin inter_400() {@include inter; font-weight:$fw_regular;}
@mixin inter_500() {@include inter; font-weight:$fw_medium;}
@mixin inter_600() {@include inter; font-weight:$fw_semibold;}
@mixin inter_700() {@include inter; font-weight:$fw_bold;}
@mixin inter_800() {@include inter; font-weight:$fw_extrabold;}
@mixin inter_900() {@include inter; font-weight:$fw_black;}
@mixin inter_thin() {@include inter_100;}
@mixin inter_extralight() {@include inter_200;}
@mixin inter_light() {@include inter_300;}
@mixin inter_regular() {@include inter_400;}
@mixin inter_medium() {@include inter_500;}
@mixin inter_semibold() {@include inter_600;}
@mixin inter_bold() {@include inter_700;}
@mixin inter_extrabold() {@include inter_800;}
@mixin inter_black() {@include inter_900;}

$quadrant-family:"Quadrant Text Regular";
$quadrant-family-italic:"Quadrant Text Italic";
@mixin quadrant_i() {font-family:$quadrant-family_italic; font-optical-sizing:auto; font-style:italic; font-variation-settings:"slnt" -10;}
@mixin quadrant() {
    font-family: $quadrant-family;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings: "slnt" 0;

    em,
    .em,
    &em,
    &.em,
    i,
    .i,
    &i,
    &.i
    {
        @include quadrant_i;
    }
}
@mixin quadrant_100() {@include quadrant; font-weight:$fw_thin;}
@mixin quadrant_200() {@include quadrant; font-weight:$fw_extralight;}
@mixin quadrant_300() {@include quadrant; font-weight:$fw_light;}
@mixin quadrant_400() {@include quadrant; font-weight:$fw_regular;}
@mixin quadrant_500() {@include quadrant; font-weight:$fw_medium;}
@mixin quadrant_600() {@include quadrant; font-weight:$fw_semibold;}
@mixin quadrant_700() {@include quadrant; font-weight:$fw_bold;}
@mixin quadrant_800() {@include quadrant; font-weight:$fw_extrabold;}
@mixin quadrant_900() {@include quadrant; font-weight:$fw_black;}
@mixin quadrant_thin() {@include quadrant_100;}
@mixin quadrant_extralight() {@include quadrant_200;}
@mixin quadrant_light() {@include quadrant_300;}
@mixin quadrant_regular() {@include quadrant_400;}
@mixin quadrant_medium() {@include quadrant_500;}
@mixin quadrant_semibold() {@include quadrant_600;}
@mixin quadrant_bold() {@include quadrant_700;}
@mixin quadrant_extrabold() {@include quadrant_800;}
@mixin quadrant_black() {@include quadrant_900;}

@mixin font-family-fa() {font-family:'Font Awesome 6 Free'; -moz-osx-font-smoothing:grayscale; -webkit-font-smoothing:antialiased; font-style:normal; font-variant:normal; line-height:1; text-rendering:auto;}
@mixin font-family-fas() {@include font-family-fa; font-weight:$fw_black;}

@function toRGBValue ($color) {
    @return #{red($color) + "," + green($color) + "," + blue($color)};
}

@function toRem($value) {
    $remValue:math.div($value, $remBase) + rem; 
    @return $remValue;
}

@function toRemValue($value) {
    @return math.div($value, $remBase);
}

@function toEm($value, $root:$remBase) {
    $emValue:FourDigitDecimal(math.div($value, $root)) + em; 
    @return $emValue;
}

@function FourDigitDecimal($value) {
    @return math.div(round($value * 10000), 10000);
}

@function stripInt($value) {
	@return math.div($value, $value * 0 + 1);
}

@function toPx($value, $root:$remBase) {
    $pxValue:toPxValue($value) * 1px; 
    @return $pxValue;
}

@function toPxValue($value, $root:$remBase) {
    $pxValue:round((stripInt($value)) * $root); 
    @return $pxValue;
}

@function if-important($important){
    @return #{if($important, '!important', '')};
}

@function if-inset($inset){
    @return #{if($inset, 'inset ', '')};
}

@mixin boxshadow($xof:0px, $yoff:5px, $blur:15px, $spread:0px, $color:rgba($neutral-800,0.05), $important: false, $inset: false) {
    box-shadow:if-inset($inset)$xof $yoff $blur $spread $color if-important($important);
}

@mixin textshadow($xof:0px, $yoff:3px, $blur:6px, $color:rgba($black,0.16)) {
    text-shadow:$xof $yoff $blur $color;
}

@mixin dropshadow($xof:0px, $yoff:3px, $blur:6px, $color:rgba($black,0.8)) {
    filter:drop-shadow($xof $yoff $blur $color);
}

@mixin textUnderlineCustom($offset:0.2em, $thickness:1px, $style:solid, $line:underline, $color:currentcolor, $important: false) {
    text-underline-offset:$offset if-important($important);
    text-decoration-thickness: $thickness if-important($important);
    text-decoration-line: $line if-important($important);
    text-decoration-style: $style if-important($important);
    text-decoration-color: $color if-important($important);
}

@mixin createBodyGradientLR ($colorA, $opacityA, $posA, $colorB, $opacityB, $posB, $colorC:false, $opacityC:false, $posC:false, $bg-type:'background') {
    @if $colorC and $opacityC and $posC {
    #{$bg-type}: -moz-linear-gradient(left, rgba($colorA,$opacityA) $posA, rgba($colorB,$opacityB) $posB, rgba($colorC, $opacityC) $posC);
    #{$bg-type}: -webkit-linear-gradient(left, rgba($colorA,$opacityA) $posA, rgba($colorB,$opacityB) $posB, rgba($colorC, $opacityC) $posC);
    #{$bg-type}: linear-gradient(to right, rgba($colorA,$opacityA) $posA, rgba($colorB,$opacityB) $posB, rgba($colorC, $opacityC) $posC);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str(rgba($colorA,$opacityA))}', endColorstr='#{ie-hex-str(rgba($colorC,$opacityC))}', gradientType=1);
    }
    @else {
    #{$bg-type}: -moz-linear-gradient(left, rgba($colorA,$opacityA) $posA, rgba($colorB,$opacityB) $posB);
    #{$bg-type}: -webkit-linear-gradient(left, rgba($colorA,$opacityA) $posA, rgba($colorB,$opacityB) $posB);
    #{$bg-type}: linear-gradient(to right, rgba($colorA,$opacityA) $posA, rgba($colorB,$opacityB) $posB);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str(rgba($colorA,$opacityA))}', endColorstr='#{ie-hex-str(rgba($colorB,$opacityB))}', gradientType=1);
    }
}

@mixin createBodyGradientTB ($colorA, $opacityA, $posA, $colorB, $opacityB, $posB, $colorC:false, $opacityC:false, $posC:false, $bg-type:'background') {
    @if $colorC and $opacityC and $posC {
    #{$bg-type}: -moz-linear-gradient(top, rgba($colorA,$opacityA) $posA, rgba($colorB,$opacityB) $posB, rgba($colorC, $opacityC) $posC);
    #{$bg-type}: -webkit-linear-gradient(top, rgba($colorA,$opacityA) $posA, rgba($colorB,$opacityB) $posB, rgba($colorC, $opacityC) $posC);
    #{$bg-type}: linear-gradient(to bottom, rgba($colorA,$opacityA) $posA, rgba($colorB,$opacityB) $posB, rgba($colorC, $opacityC) $posC);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str(rgba($colorA,$opacityA))}', endColorstr='#{ie-hex-str(rgba($colorC,$opacityC))}', gradientType=0);
    }
    @else {
    #{$bg-type}: -moz-linear-gradient(top, rgba($colorA,$opacityA) $posA, rgba($colorB,$opacityB) $posB);
    #{$bg-type}: -webkit-linear-gradient(top, rgba($colorA,$opacityA) $posA, rgba($colorB,$opacityB) $posB);
    #{$bg-type}: linear-gradient(to bottom, rgba($colorA,$opacityA) $posA, rgba($colorB,$opacityB) $posB);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str(rgba($colorA,$opacityA))}', endColorstr='#{ie-hex-str(rgba($colorB,$opacityB))}', gradientType=0);
    }
}

@mixin autocolumn($colcount:1, $colwidth:100%) {
    -webkit-column-count:$colcount;
    -moz-column-count:$colcount;
    column-count:$colcount;
    -webkit-column-width:$colwidth;
    -moz-column-width:$colwidth;
    column-width:$colwidth;
}

@mixin columnbreak($colbreak:avoid) {
    @-moz-document url-prefix() { //@supports (-moz-appearance:none) {
        display:inline-grid; width:100%;
    }
    break-inside:$colbreak;
    -webkit-column-break-inside:$colbreak;
    page-break-inside:$colbreak;
}

@function if-important($important){
    @return #{if($important, '!important', '')};
}

@mixin scrollBarHack($trackcolor:transparent, $barcolor:rgba(0,0,0,.2), $barsize:.375rem, $barminlength:1.5rem) {
    &::-webkit-scrollbar {height:$barsize; width:$barsize;}
    &::-webkit-scrollbar-track {background-color:$trackcolor;}
    &::-webkit-scrollbar-thumb {background-color:$barcolor; border-radius:$barsize * 0.5; /* Add :hover, :active if needed */}
    &::-webkit-scrollbar-thumb:vertical {min-height:$barminlength;}
    &::-webkit-scrollbar-thumb:horizontal {min-width:$barminlength;}
}

@mixin placeHolderColor($color, $fstyle:#{'normal'}) {
    //these must be individual lines otherwise it will not work
    //pseudo classes placeholder text
    &::-webkit-input-placeholder {color:$color; font-style:$fstyle;}
    &::-moz-placeholder {color:$color; font-style:$fstyle;}
    &:-ms-input-placeholder {color:$color; font-style:$fstyle;}
    &:-moz-placeholder {color:$color; font-style:$fstyle;}
    &::placeholder {color:$color; font-style:$fstyle;}
    //input element
    &:placeholder-shown {color:$color; font-style:$fstyle;}
}

@mixin customGutter ($gutter:24) {--bs-gutter-x:#{toRem($gutter)};}

@mixin customLegacyGutter ($gutter:$gutter-x, $column-element:'div') {margin-left:toRem(-$gutter); margin-right:toRem(-$gutter);
    & > #{$column-element} {padding-left:toRem($gutter); padding-right:toRem($gutter);}
}

@mixin iconClassnames {
    & {
        .icon, .fa, .fas, .far, .fab, .fal, .fat, .fa-classic, .fa-sharp, .fa-solid, .fa-regular, .fa-brands, .fa-light, .fa-thin {@content;}
    }
}

@function encodecolor($string) {
	@if type-of($string) == 'color' {
        $hex: str-slice(ie-hex-str($string), 4);
        $string:unquote("#{$hex}");
    }
    $string: '%23' + $string;
	@return $string;
}

$select-icon-chevron-down:url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEwIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik05Ljk2ODc1IDEuNjIzNjFDOS42NDc3NiAxLjMzMDY2IDkuMjYxOTQgMC45Nzg1NzEgOC43Njk5NSAwLjUyOTY1OUM3LjU2NzY0IDEuODE1NjUgNi4zNTIwNCAzLjExNjA5IDUuMDE1MDkgNC41NDYwN0MzLjYzNTA1IDMuMDcwODIgMi40MTYxIDEuNzY4MDQgMS4xNDUwMSAwLjQwOTE4QzAuNjQzMDg0IDAuOTMxMjU2IDAuMjgwMzMzIDEuMzA4NzYgLTUuNDYwMWUtMDUgMS42MDAzOUMxLjcwNzI0IDMuMzAwMDkgMy4zODk4NiA0Ljk3NTU2IDUuMDEyNDYgNi41OTFDNi41MzUxNyA1LjA2NTA3IDguMTk0MjggMy40MDIxNyA5Ljk2ODc1IDEuNjIzNjFaIiBmaWxsPSIjMUYyMTJEIi8+Cjwvc3ZnPgo=');