@use '_variables' as *;
@use '_mixins' as *;

.content-block {
    display:flex;
    flex:1 1 100%;
    position: relative;
    overflow: hidden;
    text-decoration:none;
    color:$text-black;
    font-weight:$fw_regular;
    //margin-bottom: 2rem;
    min-height: 15rem;
    @include sectionMargins;

    &[role="listitem"] {margin-bottom:0;}

    .content-block-body {flex:1 1 100%; display:flex; flex-direction:column; padding:toRem(55) toRem(39) 0; background:$white; border-radius:toRem(35); }
    .card-title, .content-body, .image-container, .content-block-bottom {width:100%;}

    .card-link {
        color: var(--site-primary-color);
        font-weight: $fw_bold;
    }

    img {
        width: 100%;
        max-width: 100%;
    }
    picture {
        img {width:auto;}
    }

    .card-title {margin-bottom:0; @include quadrant_400; color: var(--site-primary-color);}
    .content-body {}

    .image-container {
        &.isCarouselItem {text-align:center;}
        &.video-thumb {position:relative;
            &::before {position:absolute; left:50%; top:50%; transform:translate(-50%, -50%); @include font-family-fas; color:rgba(var(--site-primary-color), 0.9); font-size:toRem(72); content:"\f144";}
        }
    }

    .content-block-top {display:flex; flex-direction:column; gap:toRem(16);}

    .content-block-bottom {
        display: flex;
        align-items: center;
        margin-top: auto;
        padding: toRem(24) toRem(52) toRem(24) 0;
        min-height: toRem(55);
    }
    &.hasCta {
        .content-block-bottom {min-height:toRem(90);}
    }
    
    &:hover, &:focus, &:focus-within {text-decoration:none;}
    @include cardArrowAnimated();
}

.container-block-redesigned {@include sectionMargins; overflow: hidden;
    .headline {
        width: fit-content;
    }
    .container-block-redesigned-swiper {
        .swiper {
            display: none;
            overflow: visible;
            padding-bottom: toRem(84);

            &, & * {
                user-select: none;
            }

            &.swiper-initialized {
                display: block;
            }
        }

        .swiper-slide {
            height: auto;

            .content-block {
                height: 100%;
            }
        }

        .swiper-button-navigation {display:none;}
        .swiper-button-prev {}
        .swiper-button-next {}
    }

    &.img-auto {
        .content-block {
            img {
                width: auto;
            }
            .image-container {
                &.isCarouselItem {text-align:left;}
            }
        }
    }

    &.img-round {
        .content-block {
            img {
                border-radius: toRem(49);
            }
        }
    }

    &.cards-image-top {
        .content-block {
            display: flex;
            flex-direction: column-reverse;
        }

        .content-block-top {
            .card-title {order:2;}
            .content-body {order:3;}
            .image-container {order:1;}
        } 
    }

    @media all and (min-width:$screen_xl_min) {
        & {
            --swiper-navigation-size:#{toRem(48)};

            .container-block-redesigned-swiper {
                //.swiper-button-navigation {display:block;}
                &:hover, &:focus, &:focus-within {
                    .swiper-button-navigation {display:block;}
                }
                .swiper-button-prev, .swiper-button-next {top:calc(50% - #{toRem(42)}); display:flex; justify-content:center; align-items:center; width:var(--swiper-navigation-size); height:var(--swiper-navigation-size); border:1px solid var(--site-accent-text-color); border-radius:50%;
                    &::after {@include font-family-fas; color:var(--site-accent-text-color); font-size:toRem(18);}
                    .swiper-button-disabled {opacity:0;}
                }
                .swiper-button-prev {
                    &::after {content:"\f060";}
                }
                .swiper-button-next {
                    &::after {content:"\f061";}
                }
            }
        }
    }
}

.section-byline {
    color: var(--site-accent-text-color);
    font-size: 16px;
    font-weight:$fw_medium;

    svg {
        fill: var(--site-accent-color);

        circle {
            fill: var(--site-accent-color);
        }
    }
}

.swiper-scrollbar {
    background: none;
}

.swiper-scrollbar-drag {
    height: 9px;
    background: #D5D2CB;
}

#main-calendar {
    .fc-toolbar-chunk {
        &:first-child {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;

            div {
                display: flex;
                align-items: center;
            }

            .fc-toolbar-title {
                margin-left: 15px;
                color: var(--site-accent-text-color);

                @media (max-width:767px) {
                    font-size: 1.25rem;
                }
            }
        }
    }

    .fc-button-group {
        overflow: hidden;
        border-radius: 5px;

        button {
            text-transform: capitalize;
            border: none;
            background: $white;
            color: var(--site-accent-color);

            &.fc-button-active {
                background: var(--site-accent-color);
                color: $white;
            }
        }
    }

    .fc-customLink-button {
        background: none;
        border: none;
        color: var(--site-accent-color);
        font-weight: 600;
        display: flex;
        align-items: center;

        &:after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='" + encodecolor($green) + "' d='M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-size: contain;
            height: 15px;
            width: 15px;
            content: "";
            margin-left: 5px;
            display: block;
        }
    }

    .fc-theme-standard {
        th {
            border: #e8e8e8;
        }
    }

    th {
        text-align: left;
    }

    .fc-col-header-cell-cushion {
        padding: 5px 10px;
        color: $text-black;
        font-size: 16px;
        font-weight: 400;
        cursor: pointer;
        text-transform: uppercase;
        text-decoration: none;
    }

    .fc-daygrid-day-frame {
        padding: 10px;
    }

    .fc-daygrid-day-top {
        flex-direction: row;
    }

    .fc-daygrid-day-number {
        color: #757575;
        text-decoration: none;
        font-size: 21px;
    }

    .fc-list-day {
        .fc-list-day-cushion {
            background: $light-green;
            color: $green;
        }

        a {
            color: $green;
            text-decoration: none;
            font-weight: 400;
        }
    }

    .fc-event {
        padding: 3px;
        background: $light-green;
        border: none;
        color: $green;
        font-weight: 400;

        &.sponsored-event {
            padding-top: 30px;
            color: $purple;
            background: $light-purple;
        }

        &.fc-daygrid-dot-event {
            &.sponsored-event {
                &:before {
                    content: 'Sponsored:';
                    font-weight: 600;
                    position: absolute;
                    top: 5px;
                }
            }
        }
    }

    .fc-list-event {
        &:not(.sponsored-event) {
            background: #ffffff;
            color: $text-black;
        }
    }

    .fc-h-event {
        .fc-event-main {
            color: $green;
            font-size: 14px;
            font-weight: 400;
        }
    }

    .fc-prev-button {
        margin-right: 5px;
    }
}

#main-calendar .fc-scrollgrid,
#main-calendar .fc-scroller,
#main-calendar .fc-list-empty {
    background: $white;
    border-radius: 5px;
}

#main-calendar .fc-daygrid-event-dot,
#main-calendar .fc-list-event-dot {
    background: $purple;
    border-color: $purple;
}

#main-calendar .fc-event .fc-event-time,
#main-calendar .fc-event .fc-event-title {
    color: $green;
    font-weight: 400;
}

#main-calendar .fc-event.sponsored-event .fc-event-time,
#main-calendar .fc-event.sponsored-event .fc-event-title {
    color: $purple;
}

#main-calendar .fc-prev-button,
#main-calendar .fc-next-button {
    height: 34px;
    width: 34px;
    border-radius: 4px;
    background: $white;
    border: none;
    padding: 0;
}

#main-calendar .fc-prev-button .fc-icon,
#main-calendar .fc-next-button .fc-icon {
    font-size: 18px;
    color: $green;
}

@media(max-width:991px) {
    #main-calendar {
        .fc-toolbar {
            align-items: flex-start;
        }

        .fc-toolbar-chunk:first-child {
            flex-direction: column;
            align-items: flex-start;

            .fc-customLink-button {
                margin: 0;
                padding: 10px 0;
            }
        }

        .fc-button-group button:first-child,
        .fc-button-group button:nth-child(2) {
            display: none;
        }
    }
}