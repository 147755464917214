@use '_variables' as *;

.az-listing-page {
    .page-title {
        min-height: auto;

        &-heading {
            grid-row: auto;
        }

        &-azlisting {
            .alpha-filters {
                border-top: 1px solid $neutral-400;

                @media all and (min-width:$screen_lg_min) {
                    border-top: none;
                }

                .btn {
                    padding: 0;
                    padding-block-start: toRem(14);
                    font-weight: $fw_semibold;

                    @media all and (min-width:$screen_lg_min) {
                        pointer-events: none;
                        text-decoration: none;
                        padding-block-start: 0;
                    }

                    i {
                        color: #1F212D;
                    }

                    &[aria-expanded="true"] {
                        i {
                            transform: rotate(180deg);
                        }
                    }
                }

                @media all and (min-width:$screen_lg_min) {
                    .collapse {
                        display: block;
                    }
                }

                .alpha-filters-grid {
                    --letter-size: #{toRem(40)};
                    --letter-gap: #{toRem(24)};
                    list-style-type: none;
                    padding: 0;
                    margin: 0;
                    margin-block-start: toRem(40);
                    display: grid;
                    grid-template-columns: repeat(auto-fit, var(--letter-size));
                    gap: var(--letter-gap);

                    @media all and (min-width:$screen_lg_min) {
                        max-width: calc((9 * var(--letter-size)) + (8 * var(--letter-gap)));
                    }

                    .alpha-letter {
                        display: flex;
                        width: var(--letter-size);
                        height: var(--letter-size);
                        border-radius: 50%;
                        border: 2px solid $green;
                        color: $green;
                        font-weight: $fw_regular;
                        overflow: hidden;
                        align-items: center;
                        justify-content: center;
                        font-size: 1.25rem;
                        line-height: 1;
                        transition: background-color ease-in 100ms, color ease-in 100ms;

                        &:not(.disabled):hover {
                            background-color: $green;
                            color: $beige;
                        }

                        a {
                            font-weight: $fw_regular;
                            color: inherit;
                            text-decoration: none;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            line-height: 1;
                        }

                        &.disabled {
                            opacity: 0.3;
                            pointer-events: none;
                            user-select: none;
                        }
                    }
                }
            }
        }
    }

    .alpha-listing {
        display: grid;
        column-gap: toRem(34);

        @media all and (min-width:$screen_lg_min) {
            grid-template-columns: repeat(2, 1fr);
        }

        .alpha-group {
            display: flex;
            border-top: 1px solid $neutral-400;
            padding-block: toRem(38);

            .alpha {
                line-height: 0.9;
                width: 5rem;
                flex-shrink: 0;
            }

            .list-pages {
                list-style-type: none;
                padding: 0;
                margin: 0;
                display: flex;
                flex-direction: column;
                gap: toRem(24);

                &__categories {
                    padding: 0;
                    margin-block-end: toRem(8);
                    list-style-type: none;
                    display: flex;
                    gap: toRem(12);

                    .category-dot {
                        display: flex;
                        align-items: center;
                        color: $green;
                        font-size: toRem(10);
                        font-weight: $fw_bold;
                        letter-spacing: toRem(1.44);
                        line-height: 1;

                        &:before {
                            content: '';
                            display: inline-block;
                            width: toRem(10);
                            height: toRem(10);
                            margin-inline-end: toRem(6);
                            border-radius: 50%;
                            overflow: hidden;
                        }
                    }

                    .category-dot-archived:before {
                        background-color: $dot-grey;
                    }

                    .category-dot-endorsed:before {
                        background-color: $dot-blue;
                    }

                    .category-dot-in-development:before {
                        background-color: $dot-yellow;
                    }

                    .category-dot-current:before {
                        background-color: $dot-green;
                    }
                }

                &__year {
                    margin-inline-start: toRem(4);
                    color: $green;
                    font-size: toRem(12);
                    font-weight: $fw_bold;
                    letter-spacing: toRem(2.3);
                    vertical-align: middle;
                }
            }
        }
    }
}
