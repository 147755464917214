@use '_variables' as *;

.interior-toc-page-container {
    margin-bottom: toRem(64);

    .container, .container-fluid, .container-xxxl, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
    }
}

.interior-toc-page-row {
}

.interior-toc-page-col-left {
    flex: 1 1 100%;
}

.interior-toc-page-col-right {
    flex: 1 1 100%;
}

@media all and (min-width:$screen_lg_min) {
    .interior-toc-page-row {
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    .interior-toc-page-col-left {
        max-width: toRem(900);
    }

    .interior-toc-page-col-right {
        flex: 0 0 toRem(368);
        width: toRem(368);
    }
}
