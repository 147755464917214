@use '_variables' as *;

.blog-page {
    .page-title {
        @media all and (min-width:$screen_lg_min) {
            justify-content: space-between;
            grid-template-columns: repeat(2, auto);
        }

        &-heading {
            &__backlink {
                margin-block-end: toRem(24);
            }

            &__title {
                line-height: 1.08;

                @media all and (min-width:$screen_xxl_min) {
                    font-size: toRem(55);
                }
            }

            &__info {
                margin-block-start: toRem(20);
                flex-direction: column;

                @media all and (min-width:$screen_xxl_min) {
                    flex-direction: row;
                }

                .info-item {
                    align-self: self-start;

                    &__icon {
                        align-self: self-start;
                    }

                    &__heading {
                        line-height: 1.1;
                        font-size: toRem(16);
                    }

                    &__content {
                        font-size: toRem(14);

                        p {
                            margin: 0;
                        }

                        ul {
                            list-style-type: none;
                            margin: 0;
                            padding: 0;
                        }
                    }
                }

                .info-btn {
                    align-self: self-start;
                    color: $green;
                    font-size: toRem(14);
                    font-weight: $fw_semibold;
                    border: 1.25px solid $green;
                    border-radius: toRem(900);
                    padding-block: toRem(10);
                    padding-inline: toRem(20);

                    i {
                        margin-inline-end: toRem(8);
                    }
                }
            }
        }

        &-masonry {
            grid-template-columns: auto;

            @media all and (min-width:$screen_lg_min) {
                min-width: toRem(328);
            }

            &__item {
                border-radius: toRem(900);

                img {
                    aspect-ratio: 2 / 1;
                }
            }
        }
    }

    .toc-content {
        *:last-child {
            margin-block-end: 0;
        }
    }

    .most-recent-posts {
        margin-block-start: toRem(68);
        margin-block-end: toRem(44);

        @media all and (min-width:$screen_lg_min) {
            margin-block-start: toRem(132);
            margin-block-end: toRem(108);
        }

        &__title {
            margin-block-end: toRem(24);

            @media all and (min-width:$screen_lg_min) {
                margin-block-end: toRem(36);
            }
        }
    }
}
