@use '_variables' as *;
@use "typography" as *;
@use '_mixins.scss' as *;

html {
    &.nav-expanded {
        &, & > body {overflow:hidden;}
    }
}

.idsa-header {
    background: $beige;
    position: relative;
    z-index: 998;

    .idsa-header-utility {
        background: var(--site-primary-color);
        height: $header-utility-height;

        .idsa-header-utility-container {
            padding-left: toRem($gutter-x);
        }

        nav {
            display: flex;

            [class*="col-"] {
            }
        }

        ul, li {
            margin: 0;
            padding: 0;
            list-style-type: none;
        }

        ul {
            display: flex;
            flex-wrap: nowrap;
            @include customLegacyGutter(8, 'li');
        }

        li {
            display: flex;
        }

        a, button {
            &:not(.idsa-header-utility-btn) {
                position: relative;
                display: flex;
                align-items: center;
                color: $white;
                font-weight: $fw_medium;
                line-height: .99;
                letter-spacing: toRem(0.28);
                text-decoration: none;

                &.active {
                    font-weight: $fw_semibold; //text-shadow:0 0 1px rgba(255,255,255,1);
                    &::before {
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        height: 2px;
                        background: $white;
                        content: "";
                    }
                }
            }
        }

        .idsa-header-utility-sites {
            display: flex;
            flex: 0 1 auto;
            padding-right: toRem($gutter-x);

            a {
                font-size: toRem(14);
                padding: toRem(16) 0;

                &::after {
                    display: inline-block;
                    margin-left: toRem(6);
                    transform: rotate(-45deg);
                    @include font-family-fas();
                    font-size: 0.65em;
                    line-height: 1.693em;
                    content: "\f061";
                }

                &.active {
                    &::after {
                        display: none;
                        content: none;
                    }
                }

                &.active, &:hover, &:focus {
                    &::before {
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        height: 2px;
                        background: $white;
                        content: "";
                    }
                }

                &.active {
                    font-weight: $fw_semibold;
                }
                //text-shadow:0 0 1px rgba(255,255,255,1);
            }
        }

        .idsa-header-utility-links {
            display: none;
            flex: 0 1 auto;
            padding-right: toRem(8);

            a:not(.idsa-header-utility-btn) {
                font-size: toRem(12);
                padding: toRem(16) 0 toRem(18);

                &:hover, &:focus {
                    @include textUnderlineCustom;
                }
            }
        }

        .idsa-header-utility-login {
            margin-left: toRem(12);
            padding-right: 0;
            position: relative;

            & > a, & > button {
                padding: toRem(16) toRem(28) toRem(16) toRem(32);
                background: $yellow;
                border-radius: toRem(24) 0 0 toRem(24);
                color: var(--site-accent-text-color);
                font-size: toRem(14);
                line-height:0.99;
                font-weight: $fw_semibold;
                border: none;

                &:hover, &:focus {
                    background: $active-yellow;
                }

                &#member-dropdown-toggle {
                    .fa-solid.fa-chevron-down {
                        // animated rotation
                        transition: transform 0.3s ease;
                        margin-left: 0.33rem;
                    }

                    + .member-dropdown-menu-container {
                        display: none;
                    }

                    &[aria-expanded="true"] {
                        .fa-solid.fa-chevron-down {
                            transform: rotate(180deg);
                        }

                        + .member-dropdown-menu-container {
                            display: block;
                            position: absolute;
                            top: $header-utility-height;
                            left: -50%;
                            z-index: 100;
                            width: 150%;
                            background-color: white;
                            min-height: 12rem;
                            border-bottom-left-radius: 2rem;
                            border-bottom-right-radius: 2rem;
                            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1), 0 2px 6px rgba(0, 0, 0, 0.05);

                            .member-dropdown-menu {
                                margin: auto;
                                flex-direction: column;
                                padding-top: 0.5rem;

                                > li {
                                    @extend %btn-cta-arrow;
                                    position: relative;

                                    > a {
                                        width: 100%;
                                        color: var(--site-primary-color);
                                        border-bottom: 1px solid rgba(0,0,0, 0.15);
                                        padding-left: 1rem;
                                        font-weight: 600;
                                        height: auto;
                                        font-size: 0.9rem;
                                        padding: 11px 0px 11px 1rem;
                                    }

                                    .btn-cta-arrow {
                                        color: var(--site-primary-color);
                                        position: absolute;
                                        top: calc(50% - 7px); // 50% - half of the height of the icon
                                        right: 28px; // twíce the width of the icon
                                    }

                                    &.isExternalLink {
                                        .btn-cta-arrow {
                                            transform: rotate(-45deg);
                                            transform-origin: left;
                                        }
                                    }
                                    // and is last, no border bottom
                                    &:last-child {
                                        > a {
                                            border-bottom: none;
                                        }
                                    }
                                }
                            }

                            > .btn {
                                color: $text-green;
                                padding-left: 1.5rem;
                                padding-right: 1.5rem;
                                display: flex;
                                justify-content: center;
                                align-content: center;
                                margin-bottom: 1.5rem;
                                margin-left: 1.5rem;
                                margin-right: 1.5rem;
                                margin-top: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }

    .idsa-header-utility-row {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    .idsa-header-utility-donate {align-items:center;}
    .idsa-header-utility-donate-link {display:block; margin-top:toRem(16); margin-bottom:toRem(7);}

    .idsa-header-main {
        position: relative;
        background: $beige;
        transition: background-color 0.3s ease;

        &:has(.lvl-0.hasChildren:hover, .lvl-0.hasChildren:focus, .main-nav-dropdown:hover, .main-nav-dropdown:focus-within) {
            background: $white;
            transition: none;
            transition: background-color 0.15s ease;
        }

        button {
            margin: 0;
            padding: 0;
            background: none;
            border: none;
        }
    }

    .idsa-header-main-row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    .idsa-header-main-logo {
        flex: 1 1 auto;
        display: flex;
        min-width: toRem(132);
        max-width: toRem(132);
        transition: width 0.3s ease, max-width 0.3s ease;

        a {
            display: flex;
            padding: toRem(14) 0;
            align-items: center;
            text-decoration: none;
            transition: padding 0.3s ease;
        }

        img, svg {
            width: 100%;
        }
    }

    .idsa-header-main-nav {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        flex-direction: column;
        padding: toRem(8) toRem(36) toRem(36);
        background: $white;
        overflow-y: auto;
        z-index: 99;
        @include scrollBarHack();

        &, &.collapsing {
            transition: none;
        }

        &.show {
            display: flex;
        }

        .idsa-header-main-nav-trigger {
            position: absolute;
            right: toRem(36);
            top: toRem(16);
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: toRem(48);
            height: toRem(48);

            @include iconClassnames {
                padding: 0;
                font-size: toRem(20);
            }
        }

        .idsa-header-main-nav-head {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            padding-top: toRem(20);
            margin-bottom: toRem(26);

            .idsa-header-main-logo {
                a {
                    padding: 0;
                }
            }
        }

        #idsa-main-nav {
            width: 100%;
        }
        //margin-bottom:toRem(32);
        .idsa-header-utility-links-nav {
            width: 100%;
            display: block;
            margin-top: auto;
            padding-top: toRem(14);
            padding-bottom: toRem(54);
            border-top: 1px solid $neutral-300;

            ul, li {
                display: block;
                margin: 0;
                padding: 0;
                list-style-type: none;
            }

            ul {
            }

            li {
                padding: 1px 0;
            }

            a.idsa-header-utility--link {
                display: inline-block;
                padding: toRem(8) 0;
                color: $body-alt-color;
                font-size: toRem(14);
                font-weight: $fw_medium;
                line-height: 1.5;
                letter-spacing: toRem(0.28);
                text-decoration: none;
            }

            .idsa-header-utility-login {
                margin-top: toRem(20);

                & > a {
                    @extend .btn, .btn-primary;
                    display: block;
                }
            }
        }
        // mobile
        .idsa-header-utility-login {
            border-top: 1px solid $neutral-300;

            a {
                &#login-link, &#logout-link {margin-top:toRem(24);}
            }

            #member-dropdown-toggle {
                width: 100%;
                font-weight: 500;
                display: flex;
                justify-content: space-between;
                line-height: 0.99;
                letter-spacing: 0.02rem;
                padding-top: 1.5rem;
                padding-bottom: 1.5rem;

                .fa-solid.fa-chevron-down {
                    transform: rotate(-90deg);
                }

                + .member-dropdown-menu-container {
                    display: none;
                }

                &[aria-expanded="true"] {padding-bottom:toRem(16);
                    &::before {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 2rem;
                        height: 2rem;
                        border-radius: 50%;
                        background: $text-green;
                        color: #ffffff;
                        content: "\f053";
                        font-size: 0.875rem;
                        font-family: "Font Awesome 6 Free";
                        font-weight: 900;
                    }

                    + .member-dropdown-menu-container {
                        display: block;

                        .member-dropdown-menu > li {
                            @extend %btn-cta-arrow;
                            position: relative;

                            .member-dropdown-menu-link {
                                display: inline-block;
                                padding: toRem(24) 0;
                                color: $body-alt-color;
                                letter-spacing: toRem(0.28);
                                text-decoration: none;
                                font-size: 1rem;
                                font-weight: 600;
                                width: 100%;
                                line-height:0.99;
                            }

                            .btn-cta-arrow {
                                position: absolute;
                                right: 0;
                                top: 50%;
                            }

                            &.isExternalLink {
                                .btn-cta-arrow {
                                    transform: rotate(-45deg);
                                    transform-origin: left;
                                }
                            }
                        }

                        > .btn {
                            // 2.25rem matches the internal padding
                            position: absolute;
                            width: calc(100% - 2.25rem * 2);
                            left: 2.25rem;
                            bottom: 2.25rem;
                            justify-content:center;
                        }
                    }
                }
            }
        }
    }

    .idsa-header-main-search {
        flex: 0 0 auto;
        display: flex;
        align-items: center;

        @include iconClassnames {
            display: flex;
            align-items: center;
            padding: toRem(12) 0 toRem(12) toRem(24);
            color: var(--site-primary-color);
            font-size: toRem(32);
        }
    }

    .idsa-header-main-nav-trigger {
        flex: 0 0 auto;
        display: flex;
        align-items: center;

        @include iconClassnames {
            display: flex;
            align-items: center;
            padding: toRem(12) toRem(24) toRem(12) 0;
            color: var(--site-primary-color);
            font-size: toRem(32);
        }
    }

    .idsa-header-search {
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        z-index: 999;
        justify-content: space-between;
        background: $white;
        @include boxshadow(3px, 15px, 33.8px, -11px, rgba($black, 0.12));
    }

    .idsa-header-search-wrap {
        padding: toRem(60) toRem(52) toRem(72);
    }

    .idsa-header-main-search-overlay-frame {
        display: flex;
        flex-wrap: nowrap;

        input {
            flex: 1 1 100%;
            order: 2;
            border: none;
            background: none;
            outline: none;
            color: $text-black;
            font-size: toRem(24);
            font-weight: $fw_semibold;
            line-height: 1.17;
            @include placeHolderColor($dark-grey);
        }

        button {
            border: none;
            background: none;
            outline: none;

            span {
                display: none;
            }

            @include iconClassnames() {
                display: block;
                padding: 0 toRem(14) 0 0;
                font-size: toRem(30);
                color: var(--site-primary-color);
            }
        }
    }

    .idsa-header-search-recommendations {
        h6 {
            margin-top: toRem(32);
            margin-bottom: toRem(16);
            color: var(--site-primary-color);
        }

        ul, li {
            display: block;
            margin: 0;
            padding: toRem(2) 0;
        }

        a {
            display: inline-block;
            padding: toRem(4) 0;
            color: $text-black;
            font-size: toRem(14);
            font-weight: $fw_medium;
            letter-spacing: toRem(-0.308);
            text-decoration: none;
        }
    }

    &.header-fixed {
        .idsa-header-main {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            background: $white;
            z-index: 998;
        }

        .idsa-header-main-logo {
            max-width: toRem(132);

            a {
                padding: toRem(16) 0;
            }
        }
    }

    @media all and (min-width:$desktop_min) {
        & {
            .idsa-header-main-logo {
                min-width: toRem(150);
                max-width: toRem(150);

                a {
                    padding: toRem(20) 0;
                }
            }

            .idsa-header-utility {
                .idsa-header-utility-sites {
                }

                .idsa-header-utility-links {
                    display: flex;
                }
            }

            .idsa-header-utility-donate-link {@include btnSm; display:inline-block; margin-top:toRem(7);}

            .idsa-header-main-nav {
                position: static;
                left: auto;
                right: auto;
                top: auto;
                bottom: auto;
                flex: 0 1 auto;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                padding: 0;
                background: none;
                overflow: visible;

                .idsa-header-main-nav-trigger {
                    display: none;
                }

                .idsa-header-utility-links-nav {
                    display: none;
                }

                .idsa-header-main-nav-head {
                    display: none;
                }

                #idsa-main-nav {
                    width: auto;
                }
                //margin-bottom:0;
            }

            .idsa-header-main-nav-trigger {
                display: none;
            }

            .idsa-header-search-wrap {
                padding: toRem(60) toRem(72) toRem(92);
            }

            .idsa-header-main-search {
                @include iconClassnames {
                    font-size: toRem(26);
                }
            }

            &.header-fixed {
                .idsa-header-main-logo {
                    max-width: toRem(150);
                }
            }
        }
    }

    @media all and (min-width:$screen_xl_min) {
        & {
            .idsa-header-main-logo {
                max-width: toRem(168);
            }

            .idsa-header-utility {
                .idsa-header-utility-sites {
                }

                .idsa-header-utility-links {
                }
            }
        }
    }
}

.foundation-theme {
    .idsa-header {
        .idsa-header-utility {
            .idsa-header-utility-container {padding-right:toRem($gutter-x);}
            .idsa-header-utility-links {padding-right:0;}
        }
    }
}