@use '_variables' as *;
@use '_mixins' as *;

.newsletter-signup-block {@include sectionMargins; //margin-block: toRem(32);

    @media all and (min-width:$screen_lg_min) {
        //margin-block: toRem(64);
    }

    .newsletter-signup {
        display: grid;
        row-gap: toRem(32);
        column-gap: toRem(100);
        min-height: toRem(318);

        @media all and (min-width:$screen_lg_min) {
            grid-template-columns: repeat(2, 1fr);
        }

        &-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            grid-row: 2;

            @media all and (min-width:$screen_lg_min) {
                grid-row: auto;
            }

            &__byline {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                margin-block-end: toRem(12);
                color: var(--site-accent-text-color);
                font-size: toRem(13);
                font-weight: $fw_medium;
                line-height: 1;

                @media all and (min-width:$screen_lg_min) {
                    font-size: toRem(16);
                }

                &-dot {
                    flex-grow: 0;
                    flex-shrink: 0;
                    display: block;
                    margin-inline-end: toRem(10);
                    width: toRem(12);
                    height: toRem(12);
                    border-radius: 50%;
                    background:var(--site-accent-color);

                    @media all and (min-width:$screen_lg_min) {
                        width: toRem(14);
                        height: toRem(14);
                    }
                }
            }

            &__title {
                margin-block-end: toRem(16);

                @media all and (min-width:$screen_lg_min) {
                    margin-block-end: toRem(12);
                }
            }

            &__body {
                *:last-child {
                    margin-block-end: 0;
                }
            }

            &__link {
                margin-block: toRem(20);
            }

            &__subtext {
                font-style: italic;
                font-size: toRem(14);
            }
        }

        &-multimedia {
            display: flex;
            align-items: center;

            @media all and (min-width:$screen_lg_min) {
                grid-row: auto;
            }

            &__item {
                overflow: hidden;
                border-radius: toRem(32);

                &-pill {
                    img {
                        width: 100%;
                        border-radius: toRem(181);
                    }
                }

                &-rounded {
                    img {
                        width: 100%;
                        border-radius: toRem(50);
                    }
                }
            }
        }

        &-masonry {
            display: grid;
            grid-template-columns: repeat(2, fit-content(toRem(400)));
            align-items: center;
            gap: toRem(22);
            grid-row: 1;

            @media all and (min-width:$screen_lg_min) {
                grid-row: auto;
            }

            &__item {
                overflow: hidden;
                border-radius: toRem(32);

                &:only-child {
                    @media all and (min-width:$screen_lg_min) {
                        grid-column-start: 2;
                    }
                }

                &:last-child {
                    border-radius: 50%;
                }

                img {
                    aspect-ratio: 1 / 1;
                    object-fit: cover;
                    width: 100%;
                }
            }
        }
    }
}

.staff-list-block {@include sectionMargins;
    //padding-block-end: toRem(28);

    @media all and (min-width:$screen_lg_min) {
        //padding-block-end: toRem(52);
    }

    .staff-list-byline {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin-bottom: toRem(20);
        color: var(--site-accent-text-color);
        font-size: toRem(13);
        font-weight: $fw_medium;
        line-height: 1;

        @media all and (min-width:$screen_lg_min) {
            font-size: toRem(16);
        }

        &__dot {
            flex-grow: 0;
            flex-shrink: 0;
            display: block;
            margin-right: toRem(10);
            width: toRem(12);
            height: toRem(12);
            border-radius: 50%;
            background: var(--site-accent-color);

            @media all and (min-width:$screen_lg_min) {
                margin-right: toRem(12);
                width: toRem(14);
                height: toRem(14);
            }
        }
    }

    .staff-list-title {
        margin-block: 0;
    }

    .staff-list-body {
        margin-block-start: toRem(15);
        text-wrap: pretty;
        max-width: 100%;

        @media all and (min-width:$screen_lg_min) {
            margin-block-start: toRem(20);
            max-width: 60%;
        }

        p:last-child {
            margin-block-end: 0;
        }
    }

    .staff-list-members {
        display: grid;
        row-gap: toRem(54);
        list-style-type: none;
        padding-block: 0;
        margin-block-start: toRem(50);
        padding-inline: 0;

        @media all and (min-width:$screen_lg_min) {
            column-gap: toRem(62);
            row-gap: toRem(124);
            grid-template-columns: repeat(2, 1fr);
        }

        @media all and (min-width:$screen_xxl_min) {
            grid-template-columns: repeat(3, 1fr);
        }

        .staff-member {
            display: grid;
            grid-template-columns: 114px 1fr;
            column-gap: toRem(32);

            &-img {
                width: toRem(114);
                aspect-ratio: 1/1;
                border-radius: 50%;
                overflow: hidden;
            }

            &-noimg {
                width: toRem(114);
                aspect-ratio: 1/1;
                border-radius: 50%;
                overflow: hidden;
                background-color: $white;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    flex-grow: 0;
                }
            }

            &-detail {
                &__name {
                    margin-block: 0;

                    a {
                        font-weight: inherit;

                        &::after {
                            @include font-family-fas();
                            display: inline-block;
                            margin-inline-start: toRem(6);
                            font-size: 0.65em;
                            line-height: 1.693em;
                            content: "\f054";
                        }

                        &.is-external {
                            &::after {
                                transform: rotate(-45deg) translateY(-0.2rem);
                                margin-inline-start: toRem(12);
                            }
                        }
                    }
                }

                &__title {
                    color: $dark-grey;
                    font-size: toRem(14);
                    font-weight: $fw_semibold;
                    margin-block: 0;
                }

                &__desc {
                    margin-block-start: toRem(8);
                    margin-block-end: 0;
                }
            }
        }
    }
}

.resource-list-block {@include sectionMargins; //padding-block-end: toRem(28);

    @media all and (min-width:$screen_lg_min) {
        //padding-block-end: toRem(52);
    }

    &__title {
        color: var(--site-accent-text-color);
        margin-block-end: toRem(40);
        padding-block-end: toRem(22);
        border-block-end: 1px solid $neutral-400;
    }

    &__items {
        display: grid;
        gap: toRem(32);
        list-style-type: none;
        padding-block: 0;
        margin-block: 0;
        padding-inline: 0;

        @media all and (min-width:$screen_md_min) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media all and (min-width:$screen_lg_min) {
            grid-template-columns: repeat(3, 1fr);
        }

        .resource-item {
            &__link {
                color: $green;
                text-decoration: none;
                margin-block-end: toRem(8);

                a {
                    vertical-align: middle;
                    color: $green;
                    text-decoration: none;
                    margin-inline-end: toRem(4);

                    &:hover {
                        text-decoration: underline;
                    }

                    i.fa {
                        margin-inline-start: toRem(4);
                        transform: rotate(-45deg);
                    }
                }

                .link-type {
                    font-size: toRem(10);
                    letter-spacing: toRem(2.3);
                    vertical-align: middle;
                    font-weight: $fw_bold;
                    line-height: 0.99;
                    color: $purple;
                    text-transform: uppercase;
                }
            }

            &__description {
                margin-block: 0;
            }
        }
    }
}