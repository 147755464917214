@use "variables" as *;
@use "mixins" as *;
@use "typography" as *;

.legacy-guideline-detail-page {
    .view-all-guidance {
        display: block;
        margin-top: 7rem;
        margin-bottom: 2.5rem;

        &::before {
            content: "\f053";
            font-family: "Font Awesome 6 Free";
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            font-style: normal;
            font-feature-settings: normal;
            font-variant: normal;
            line-height: 1;
            text-rendering: auto;
            font-weight: 900;
            margin-right: 0.25rem;
        }
    }

    .title-button-section {
        @media screen and (min-width: $screen-md-min) {
            margin-left: auto;
        }
    }

    .accordion {
        border-bottom-style: none !important;
        margin-bottom: 0 !important;

        >.card {
            background-color: transparent;
            border: 0;

            .card-header {
                border: 0;
                background-color: transparent;
                border-top: 1px solid var(--site-primary-color);
            }

            .collapse:not(.show) {
                display: block !important; // we are forcing the accordion to be open by default
            }
        }
    }

    // this is actually the TITLE BUTTON. I'll do my best not to modify JS.
    .js-gtm-practice-guidelines-accordion {
        // to look like titles
        text-decoration: none !important;
        font-size: toRem(40);
        line-height: 1.1;
        scroll-margin-block-start: toRem(72);
        text-align: left;
        padding-left: 0;
        color: var(--site-primary-color) !important;
        @include quadrant_400();

        >i {
            display: none !important;
        }
    }

    .js-transform-accordion-header {
        border-top: 2px solid var(--site-primary-color);
        padding-top: 1rem;
        margin-bottom: 2.5rem;
        @extend .h3;
    }

    .h3:not(.js-transform-accordion-header) {
        @extend .h3-wysiwyg;
    }
    .h4:not(.js-transform-accordion-header) {
        @extend .h4-wysiwyg;
    }
    .h5:not(.js-transform-accordion-header) {
        @extend .h5-wysiwyg;
    }
    .h6:not(.js-transform-accordion-header) {
        @extend .h6-wysiwyg;
    }

    .custom-select {
        width: 100% !important;
    }

    .status-section {
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 2rem;

        .status {
            font-size: 10px;
            font-weight: bold;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                top: calc(50% - 5px);
                left: -15px;
                width: 10px;
                height: 10px;
                border-radius: 50%;
            }

            &.current::before {
                background-color: $dot-blue;
            }

            &.endorsed::before {
                background-color: $dot-green;
            }

            &.archived::before {
                background-color: $dot-grey;
            }

            &.in-development::before {
                background-color: $dot-yellow;
            }
        }
    }

    @media screen and (min-width: $screen-lg-min) {
        .body-container {
            width: 65%;
            padding-right: 17rem;
        }
    }
}

#citeModal.legacy-pg .close {
    background-color: transparent;
    border: 0;
    margin-left: auto;
    font-size: 3rem;

    span {
        @include svg("close", var(--site-primary-color));
    }
}
