@use "variables" as *;

.tt-input, /* UPDATE: newer versions use tt-input instead of tt-query */
.tt-hint {
    width: 100%;
    height: 34px;
    font-size: 16px;
    line-height: 34px;
    outline: none;
    margin-top: 0px;
    border:none;
}
.tt-cursor {
    background-color: #e7e7e7;
}
.tt-input { /* UPDATE: newer versions use tt-input instead of tt-query */
    border: none;
    margin-left: 1px;
    width: 100%;
}
.tt-hint {
    color: #999;
    width: 100%;
}
.tt-menu { /* UPDATE: newer versions use tt-menu instead of tt-dropdown-menu */
    width: 100%;
    margin-top: 12px;
    padding: 8px 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}
.tt-suggestion {
    padding: 3px 20px;
    font-size: 18px;
    line-height: 24px;
    cursor:pointer;
    color:#495057;
    &.tt-is-under-cursor { /* UPDATE: newer versions use .tt-suggestion.tt-cursor */
        color: #fff;
        background-color: #0097cf;
    }
    p {
        margin: 0;
    }
}
.form-typeahead{
    .form-control{
        width:100%;
        &:focus{
            border-color:#fff;
            box-shadow: none;
        }
    }
}
.twitter-typeahead {
    height: 36px;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    flex-grow:1;

    input.search-input, input.pg-search-input {width:100%; font-size:14px;
        //these must be individual lines otherwise it will not work
        //pseudo classes placeholder text
        &::-webkit-input-placeholder {color:$grbc; font-style:italic;}
        &::-moz-placeholder {color:$grbc; font-style:italic;}
        &:-ms-input-placeholder {color:$grbc; font-style:italic;}
        &:-moz-placeholder {color:$grbc; font-style:italic;}
        &::placeholder {color:$grbc; font-style:italic;}
        //input element
        &:placeholder-shown {color:$grbc; font-style:italic;}
    }
}

.search-group {
    display: flex;

    a {
        &.btn {
            border-radius: 0;

            &.btn-primary {
                height: 36px;
                padding: 8px 30px;
            }
        }
    }
}

.input-group {
    .input-group-append, .input-group-prepend {
        button.btn {width:42px;}
    }
}

.mobile-results-header {
    .twitter-typeahead{
        flex-grow:1;
    }
    .results-input{
        display:flex;
        margin-bottom: $spacing * 2;
        .btn {
            &.btn-primary {width:45px;}
        }
    }
}

@media all and (-ms-high-contrast:none){
    .twitter-typeahead{
        .tt-input{
            margin-top:4px;
        }
    }
}