@use '_variables' as *;

.idsa-footer {margin-top:toRem(-48); padding-top:toRem(48); background:var(--site-primary-color);
    .idsa-footer-top {padding:toRem(65) 0 toRem(60);}
    .idsa-footer-info {
        img, svg {margin-bottom:toRem(29); width:toRem(194); max-width:100%;}
    }
    .idsa-footer-title {margin-bottom:toRem(42); color:$beige; font-size:toRem(22); line-height:1.38;}

    .idsa-footer-nav {display:flex; flex-wrap:wrap; @include customLegacyGutter(16, 'ul.lvl-0');
        button {margin:0; padding:0; background:none; border:none;}
        ul, li {margin:0; padding:0; list-style-type:none;}
        li {display:block;}
        ul {
            &.lvl-0 {flex:1 1 100%;}
            &.lvl-1 {padding-bottom:toRem(20);
                &, &.collapsing {transition:none;}
            }
        }
        li {
            &.lvl-0 {}
            &.lvl-1 {}
        }
        .idsa-footer-nav-item {display:inline-block; color:$beige; line-height:1.38; text-decoration:none; user-select:none;
            &.lvl-0 {flex:1 1 100%; padding:toRem(20) 0; font-size:toRem(20); font-weight:$fw_semibold; letter-spacing:toRem(0.36);}
            &.lvl-1 {padding:toRem(6) 0; font-size:toRem(16); font-weight:$fw_medium; letter-spacing:toRem(0.32);}
        }
    }
    .idsa-footer-nav-header {display:flex; flex-wrap:nowrap;
        .idsa-footer-nav-header-trigger {flex:0 0 toRem(40); display:flex; justify-content:flex-end; align-items:center; width:toRem(40); color:$beige; font-size:toRem(20);
            &.collapsed {
                @include iconClassnames() {
                    &::before {content:"\f078";}
                }
            }
        }
    }

    .idsa-footer-base {}
    .idsa-footer-social {}
    .idsa-footer-social-links {display:flex; justify-content:center;
        nav {flex:0 0 auto; position:relative; padding:toRem(12) toRem(22) 0; max-width:100%; border-radius:toRem(29) toRem(29) 0 0; background:$beige;
            .idsa-footer-social-link-clip-bl, .idsa-footer-social-link-clip-br {display:block; position:absolute; overflow:hidden; bottom:-1px; width:toRem(29); height:toRem(29);
                &::before {display:block; position:absolute; width:200%; height:200%; border-radius:50%; content:""; }
            }
            .idsa-footer-social-link-clip-bl {left:toRem(-28);
                &::before {box-shadow:toRem(15) toRem(15) 0 0 $beige; bottom:1px; right:1px;}
            }
            .idsa-footer-social-link-clip-br {right:toRem(-28);
                &::before {box-shadow:toRem(-15) toRem(15) 0 0 $beige; bottom:1px; left:1px;}
            }
        }
        ul, li {display:block; margin:0; padding:0; list-style-type:none;}
        ul {display:flex; flex-wrap:wrap; justify-content:center; align-items:center; @include customLegacyGutter(12, 'li');}
        li {padding-bottom:toRem(10);}
        a {display:flex; justify-content:center; align-items:center; width:toRem(40); height:toRem(40); background:var(--site-primary-color); border-radius:toRem(20); color:$beige; font-size:toRem(20); text-decoration:none;}
    }
    .idsa-footer-base-content {background:$beige; 
        &, a {color:var(--site-primary-color); font-size:toRem(16); font-weight:$fw_medium; letter-spacing:toRem(0.28); text-decoration:none;}
    }
    .idsa-footer-base-content-row {padding-top:toRem(70); padding-bottom:toRem(32);}
    .idsa-footer-base-content-col {display:flex; justify-content:center; align-items:center;}
    .idsa-footer-base-content-body {@include customLegacyGutter(12); flex-wrap:wrap;}
    .idsa-footer-base-content-logos {@include customLegacyGutter(8); flex-wrap: nowrap; justify-content: flex-end;
        & > div {flex:1 1 50%; display:flex; justify-content:center; align-items:center; padding-top:toRem(14); padding-bottom:toRem(14);
            .logo {max-height: 50px;}}
    }
    .idsa-footer-base-copy {flex:1 1 100%; margin-bottom:toRem(24); text-align:center;}
    .idsa-footer-base-legallinks {flex:1 1 100%; display:flex; justify-content:center; align-items:center; margin-bottom:toRem(32);
        ul, li {display:block; margin:0; padding:0; list-style-type:none;}
        ul {display:flex; @include customLegacyGutter(12, 'li');}
        li {position:relative;
            &::before {position:absolute; left:0; top:2px; bottom:2px; width:1px; background:$green; content:"";}
            &:first-child {
                &::before {display:none; content:none;}
            }
        }
    }

    @media all and (min-width:$screen_md_min)
    {
        & {
            .idsa-footer-base-content-logos {flex-wrap: nowrap; justify-content: center;
                & > div {flex:0 1 auto;}
            }
        }
    }

    @media all and (min-width:$screen_xl_min)
    {
        &.sticky {position:sticky; top:0; bottom:0;
            .idsa-footer-top {padding:toRem(86) 0 toRem(72);}
            .idsa-footer-title {margin-bottom:0; font-size:toRem(18); letter-spacing:toRem(0.36);}
            .idsa-footer-nav {
                ul {display:block;
                    &.lvl-0 {flex:0 0 33.333334%;padding-bottom:toRem(36);}
                    &.lvl-1 {}
                }
                li {
                    &.lvl-0 {}
                    &.lvl-1 {}
                }
                .idsa-footer-nav-item {
                    &.lvl-0 {
                            padding: 0;
                            font-size: 1.18rem;
                            font-weight: 800;
                            margin-bottom: 0.25rem;
                        }                    &.lvl-1 {}
                }
            }
            .idsa-footer-nav-header {display:flex; flex-wrap:nowrap;
                .idsa-footer-nav-header-trigger {display:none;}
            }
            
            .idsa-footer-social-links {justify-content:flex-start;
                nav {}
            }
            
            .idsa-footer-base-content {
                &, a {font-size:toRem(14);}
            }
            .idsa-footer-base-content-row {display:flex; flex-wrap:nowrap; justify-content:space-between; align-items:center; padding-top:0; padding-bottom:0;}
            .idsa-footer-base-content-col {justify-content:flex-start;}
            .idsa-footer-base-content-body {padding:toRem(32) 0;}
            .idsa-footer-base-content-logos {padding:toRem(6) 0; flex-wrap: nowrap; justify-content: end;
                & > div {
                    .logo {max-height: 50px;}
                }
            }
            .idsa-footer-base-copy {flex:0 1 auto; margin-bottom:0; text-align:left;}
            .idsa-footer-base-legallinks {flex:0 1 auto; justify-content:flex-start; margin-bottom:0;}
        }
    }
}