@use '../_variables.scss' as *;
@use 'bootstrap-5.3.3/scss/bootstrap.scss' as *;
@use 'fontawesome-free-6.6.0-web/scss/fontawesome.scss' as *;
@use 'slick-1.8.1/slick.scss' as *;
@use 'slick-1.8.1/slick-theme.scss' as *;

.slick-parent {}
.slick-slider {opacity:0; transition:opacity 0.15s ease;
    &.slick-initialized {opacity:1;}
}
.slick-list {}
.slick-track {display:flex; justify-content:flex-start; align-items:stretch; flex-direction:row; flex-wrap:nowrap;
    .slick-slide {float:none; height:auto; min-height:auto; flex-grow:0; flex-shrink:0; display:flex; align-items:center;}
}
.slick-arrow {
    &:hover, &.focus {
    }
    &, &.slick-disabled {
    }
    &.slick-disabled {opacity:0.62;}
}
ul.slick-dots {margin:0; padding:0; margin-block-start:0; margin-block-end:0; margin-inline-start:0; margin-inline-end:0; padding-inline-start:0;}