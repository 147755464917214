@use "variables" as *;

$header-color:$main-accent-color;
$facet-primary-background-color: #FFF;//#007db1;
$facet-disabled-background-color: #ccc;
$facet-highlight-background-color:$main-highlight-color;
$facet-text-color: $text-color;
$btn-text-color:#2d2d2d;
$facet-text-contrast-color: #FFF;
$facet-term-border: $primary-border;

.hidden{
    display:none;
}

.facets {color:$facet-text-color;
    .panel {
        border-radius: 0;
        box-shadow: none;
        margin-bottom: $spacing * 1.5;
    }
    .panel-default {
        .panel-heading {
            font-weight: bold;
            color: $facet-text-contrast-color;
            border-radius: 0;
            border-bottom: none;

            a {
                color: $facet-text-contrast-color;
                text-decoration: none;
            }
        }
    }
    .panel-heading{
        > a, > span {position:relative; display:block;font-size:18px; font-weight:bold; line-height:100%;
            span {display:block;}
            .fa {position:absolute; right:20px; top:14px; font-size:16px; line-height:20px;}
            &.collapsed {
                .fa-minus:before {content:'\f067';}
            }
//            @media (min-width: 992px)
//            {
//                & {padding:18px 52px 18px 22px; font-size:29px;
//                    .fa {right:20px; top:18px; font-size:20px; line-height:29px;}
//                }
//            }
        }
    }
    .panel-body {
        padding: 0;
        background-color: $facet-primary-background-color;

        .hide-overflow {
            .overflow {
                display: none;
            }
        }

        .hide-overflow.show-all {
            .overflow {
                display: block;
            }
        }
    }
    .checkbox + .checkbox {
        margin-top: 0;
    }
    .facet-group-show-more, .facet-group-show-less, .facets-term{
        cursor: pointer;
        margin: 0;
    }
    .facet-group-show-more, .facet-group-show-less{
        a{
            margin: $spacing 0;
        }
    }
    .facets-term {        
        border-top:$facet-term-border;
        &.checked, &:hover {
            background-color: $facet-highlight-background-color;
        }

        &.disabled, &.checked.disabled {
            background-color: $facet-disabled-background-color; opacity:0.7;
            //background:none;
            & > label {cursor:default;
                .fa {display:none;}
            //    &::before {display:block; position:absolute; right:20px; top:16px; @include font-family-fas; font-size:14px; line-height:16px; content:"\f05e"; opacity:0.3;}
            }
        }

        .count {
            font-weight: bold;
        }
        &.depth1{
            > label {
                margin-left: $spacing * 2;
            }
        }
        &.depth2{
            > label {
                margin-left: $spacing * 3;
            }
        }
        > label {
            position:relative; 
            display:flex;
            align-items: center; 
            margin:0px; 
            padding:16px 36px 16px 22px; 
            font-size:16px; 
            line-height:100%; 
            cursor:pointer;            
            .fa {position:absolute; right:20px; top:16px; font-size:14px; line-height:16px;}
            input{
                margin-right: $spacing;
            }
        }

        > i.fa {
            float: right;
            margin-top: 1rem;
            margin-right: 1rem;
        }
    }
    .bbSearchFacetItem {padding:0px 22px;
        .btn {display:inline-block; margin:10px 0px 15px 0px; padding:8px; width:170px; max-width:100%; border:1px solid $btn-text-color; border-radius:0px; color:$btn-text-color; font-size:16px; font-weight:bold; line-height:100%; text-align:center;}
    }
    .date-range {
        .panel-body{padding:0px 22px; border:none;
            .date-range-sel {
                label {margin:0px; padding:15px 0px; font-size:16px; line-height:100%;}
                .date-range-sel-input {position:relative; background:#ffffff;
                    &::before {display:block; position:absolute; right:8px; top:50%; margin-top:-10px; @include font-family-fa; color:$btn-text-color; font-size:17px; content:"\f133";}
                    input {position:relative; margin:0px; padding:8px 26px 8px 6px; width:100%; height:40px; border:1px solid $btn-text-color; background:none; z-index:1; font-size:14px; line-height:100%;}
                }
            }
        }
        .action-group {
            .btn {display:block; margin:5px 0px 15px 0px; padding:5px; width:100%; color:$facet-text-color; text-decoration:underline;}
        }
    }
    .clear-filters, .close-modal {
        margin-top: 2rem; 
        a, button {margin-bottom:30px;
            //&, &:hover {min-width: 50%; background:$secondary-accent-color; border-color:$secondary-accent-color; border-radius:0px; color:#ffffff; font-weight:600;}
        }
    }
}

.modal.mobile{
    padding:0;
    .modal-dialog{
        width:100%;
        padding:0;
        margin:0;
    }
    .modal-header{
        .close{
            margin-top: 0.5rem;
        }
        .title{
            width: 90%;
            font-size: 2rem;
            font-weight: bold;
            display: inline-block;
        }
    }
}