@use '_variables' as *;
@use '_mixins.scss' as *;

.pill {display:inline-flex; align-items:center; padding:toRem(6) toRem(12); font-size:toRem(10); font-weight:$fw_bold; line-height:1.42; letter-spacing:toRem(1.8); border-radius:toRem(13);
    &.pill-green {color:$green; background:$light-green;}
    &.pill-purple {color:$purple; background:$light-purple;}
    &.pill-buttermilk-yellow { color: $green; background-color: $buttermilk-yellow; }
}

@include idsaTabNav;

.header-byline {display:flex; flex-direction:row; flex-wrap:nowrap; margin-bottom:toRem(26); color:var(--site-accent-text-color); font-size:toRem(13); font-weight:$fw_medium; line-height:1;
    &::before {display:block; flex-grow:0; flex-shrink:0; display:block; margin-right:toRem(10); width:toRem(12); height:toRem(12); border-radius:50%; background:var(--site-accent-color); content:"";}
    @media all and (min-width:$screen_lg_min) {
        & {margin-bottom:toRem(20); font-size:toRem(16);
            &::before {margin-right:toRem(12); width:toRem(14); height:toRem(14);}
        }
    }
}

@mixin idsa-table-xl {
    td {display:table-cell; padding:toRem(18) toRem(24);
        &::before {display:none; content:none;}
        .td-content {padding:0; border:none;}
        &, &:nth-child(odd) {background:none;}
    }
    tr {display:table-row; background:$beige;
        &:nth-child(even) {background:$table-bg;}
        &:first-child {display:table-row;}
        th, &:first-child td {padding:toRem(28); text-align:left !important;}
    }
}

table.idsa-table {margin:0 0 toRem(24) 0; padding:0; border:2px solid var(--site-primary-color); border-radius:toRem(34); border-spacing:0; border-collapse:separate !important; overflow:hidden;
    %thstyling {background:var(--site-primary-color); color:$white; font-size:toRem(11); font-weight:$fw_bold; letter-spacing:toRem(1.2); text-transform:uppercase;}
    thead, th {background:var(--site-primary-color);}
    th {@extend %thstyling;}
    //&::before {position:absolute; left:0; right:0; top:0; bottom:0; border:2px solid $green; border-radius:toRem(34); content:"";}
    tr {display:block; border:none; border-bottom:8px solid $green;
        &:first-child {display:none;
            td {@extend %thstyling; vertical-align:middle;}
        }
        &:last-child {border:none;}
    }
    td {display:flex; margin:0; padding:0; border:none; color:$text-black; font-size:toRem(14); background:$beige; vertical-align:top; //@include createBodyGradientLR($green, 1, 0px, $green, 1, toRem(34), $beige, 1, toRem(35));
        &::before {flex:0 0 50%; padding:toRem(24); content:attr(data-label); @extend %thstyling; text-align:left;}
        .td-content {flex:0 0 50%; display:block; padding:toRem(24); border-top:1px solid $table-border; text-align:left;
            .td-content {width:100%; padding:0; border:none;}
        }
        p {margin:0;}
        .td-content {
            p {margin-bottom:toRem(16);
                &:last-child {margin-bottom:0;}
            }
        }
        &:first-child {border-top:0;
            &:before {border-top:0;}
        }
        &:last-child {border-bottom:0;}
        &:nth-child(odd) {background:$table-bg;} //@include createBodyGradientLR($green, 1, 0px, $green, 1, toRem(34), $table-bg, 1, toRem(35));
    }

    @media all and (max-width:$screen_lg_max) {
        & {
            td {width:100% !important;}
        }
    }

    @media all and (min-width:$screen_xl_min) {
        & {
            @include idsa-table-xl;
        }
    }
}

//table.idsa-table {border-collapse:collapse; margin:20px 0; padding:0; width:100%; max-width:100%; table-layout:fixed; border:1px solid rgba(0,0,0,.125);
//    caption {font-size:1.5em; margin:.5em 0 .75em;}
//    tr {background-color:#f7fafe;
//        &:first-child {text-align:center; background:#20365c !important; color:#fff; font-size:14px; vertical-align:top;
//            td {border:none; padding:15px; width:auto !important;}
//            p {color:#fff; font-size:14px; margin:0;}
//        }
//        &:nth-child(even) {background:$white !important;}
//    }
//
//    &.style-2 {
//        tr {background-color:#f3f9fc;
//            &:first-child {
//                td {padding:25px 15px;}
//            }
//        }
//    }
//
//    th {padding:15px; text-align:center; background:#20365c; color:#fff; font-size:14px; vertical-align:top;
//        p {color:#fff; font-size:14px;}
//    }
//
//    td {padding:15px; text-align:left; font-size:14px; vertical-align:top; border-right:0; border-top:0; border-bottom:0; border-left:1px solid #f6f6f6; width:auto; //word-break:break-word;
//        &:first-child {border:none;}
//        p {font-size:14px;}
//    }
//
//    @media all and (min-width:$screen_xl_min) {
//        & {width:calc(100% + 15px) !important;}
//    }
//
//    .step {padding:5px; width:100%; text-align:center; color:#444342; display:block; font-weight:bold;
//        &.step-1 {background:#ffe890;}
//        &.step-2 {background:#ebdd8e;}
//        &.step-3 {background:#e6e090;}
//        &.step-4 {background:#d3da8f;}
//        &.step-5 {background:#d0e7b9;}
//    }
//}
//
//table.idsa-table.double-row-border {border-collapse:separate; border-spacing:0;
//    .show-mobile {display:flex;}
//
//    @media all and (min-width:1441px) {
//        tbody > tr {
//            &:nth-child(odd):not(:first-child) td {border-bottom:1px solid $green;
//                &:first-child {border-left:none; border-bottom:1px solid $green;}
//            }
//            td:first-child {border-left:1px solid $green; border-bottom:1px solid $green;}
//            td:last-child {border-right:1px solid $green;}
//            &:last-child {
//                td {border-bottom:1px solid $green; &:first-child {border-left:none;}
//                    &:last-child {border-bottom-right-radius:8px;}
//                }
//            }
//            &.no-border-bottom {
//                td {border-bottom:none !important;}
//            }
//            &.three-row-span {
//                td:first-child {border-left:1px solid $green !important; border-bottom-left-radius:8px !important;}
//            }
//        }
//        .show-mobile {display:none;}
//    }
//}
//
//@media screen and (max-width:$screen_xl_max) {
//    table.idsa-table {border:0; box-shadow:none; overflow:auto;
//        caption {font-size:1.3em;}
//        thead {border:none; clip:rect(0 0 0 0); height:1px; margin:-1px; overflow:hidden; padding:0; position:absolute; width:1px;}
//        tr {
//            &:first-child {display:none !important;}
//            display:inline-block; overflow:hidden; border-radius:10px; margin-bottom:40px; padding:0; box-shadow:0px 0px 20px rgba(151,210,239,0.33); width:100%; //height:auto !important; background:#ffffff;}
//
//        &.style-2 {
//            tr {border-radius:0px;}
//        }
//
//        td {display:flex; font-size:0.8em; text-align:left !important; width:100% !important; height:auto !important; position:relative; border-top:1px solid rgba//(151,210,239,0.33); flex-wrap:nowrap; padding:0;
//            p {text-align:left;
//                &:last-child {margin:0;}
//            }
//            &::before {border-top:1px solid #ffffff; content:attr(data-label); font-weight:bold; width:50%; text-align:left; background:#20365c; color:#fff; //padding:15px 10px; padding-bottom:15px; padding-bottom:15px; top:0; left:0; box-sizing:border-box; display:block; float:left; position:relative; /margin:0;}
//
//            .td-content {width:50%; display:block; float:left; padding:15px 10px;
//                .td-content {width:100%; padding:0;}
//            }
//            &:first-child {border-top:0;
//                &:before {border-top:0;}
//            }
//            &:last-child {border-bottom:0;}
//       }
//   }
//}
//
//@media all and (max-width:$screen_sm_min) {
//    table.idsa-table {
//        td {font-size:0.7em;}
//    }
//}

.external-link-icon {
    transform: rotateZ(-45deg);
    font-size: 1rem;
}