@use "./idsociety/variables" as *;

.member-dashboard-page {
    .user-panel-section {
        display: flex;
        gap: 5rem;
        align-items: center;
        margin-top: 3rem;
        margin-bottom: 7.5rem;

        @media screen and (max-width: 768px) {
            flex-wrap: wrap;
            flex-direction: column-reverse;
            gap: 2.5rem;
            margin-bottom: 3rem;
        }

        .cta-section {
            .tag-section {
                list-style-type: none;
                padding-left: 0;
                display: flex;
                margin-bottom: 0;

                .tag {
                    margin-right: 1.5rem;
                    text-transform: uppercase;
                    font-weight: bold;
                    font-size: 0.75rem;
                    margin-bottom: 0.75rem;

                    .badge-text {
                        color: var(--site-primary-color);
                    }
                }
            }

            .title {
                margin-bottom: 1rem;
                // it's an H1 sized like an H2 (ADA requires sequentially descending non-skipping headings)
                font-size: 2.5rem;
                display: flex;

                >span {
                    font-style: italic;
                }
            }

            .quicklinks-block {
                margin-bottom: 2rem;
            }
        }

        .image-section {
            overflow: hidden;
            border-radius: 45rem;
            max-width: 100%;

            img {
                width: 100%;
            }
        }
    }

    .resource-list-block {
        .resource-list-block__title {
            margin-bottom: 1rem;
            font-size: 1.625rem;

            >span {
                font-style: italic;
            }
        }

        .resource-list-block__items {
            @media screen and (min-width: 992px) {
                grid-template-columns: repeat(4, 1fr);
            }

            .resource-item__description {
                color: var(--bs-body-color) !important;

                &:hover,
                &:focus {
                    color: var(--bs-green) !important;
                }
            }
        }
    }

    .rss-feed-block {
        >header {
            border-block-end: 1px solid $neutral-400;

            >h2 {
                font-size: 1.625rem; //sized like an h4
            }
        }

        .row {
            .grid-item {
                >header>h3 {
                    font-size: 1rem;
                    font-family: "Inter";
                    font-weight: bold;
                }

                >p {
                    color: var(--bs-body-color);
                }
            }
        }
    }
}