@use '_variables' as *;

#bs-search-idsa-PracticeGuidelines {
    .facet-panel-wrap {
        & > h4 {}
    }
    .search-columns {
        .results-header {}
    }
    @media all and (min-width:$screen_lg_min) {
        & {
            .facet-panel-wrap {margin-top:toRem(76);
                & > h4 {display:none;}
            }
            .search-columns {
                .results-header {margin-bottom:toRem(69);}
                .results-summary-row {align-items:center;}
                .results-summary-input {
                    & > .search--wrapper {flex:1 1 100%; width:100%; max-width:toRem(314);}
                }
                .results-summary-sort {}
            }
        }
    }
}