@use "variables" as *;

.btn-primary.search{
    color: #fff;
    &:hover {
        background-color: #febe10;
        color: #212121;
    }
}

.reset{
    margin-top: 2rem; 
    a, button {margin-bottom:30px;
        &, &:hover {max-width: 200px; background:$secondary-accent-color; border-color:$secondary-accent-color; border-radius:0px; color:#ffffff; font-weight:600;}
    }
}
a.orange, button.orange {        
    &, &:hover {width:100%; background:$secondary-accent-color; border-color:$secondary-accent-color; border-radius:0px; color:#ffffff; font-weight:600;}
    &.margin{
        margin-top:$spacing;
    }
}
