@use "include/idsociety/_variables" as *;
@use "include/idsociety/libraries/_libraries.scss" as *;
@use "include/idsociety/_bsoverrides" as *;
@use "include/idsociety/_typography" as *;
@use "include/idsociety/_utility" as *;
@use "include/idsociety/_header" as *;
@use "include/idsociety/_navigation" as *;
@use "include/idsociety/_footer" as *;
@use "include/idsociety/_common" as *;
@use "include/idsociety/_pages" as *;
@use "include/idsociety/_blocks.scss" as *;
@use "include/idsociety/_blocks_p2.scss" as *;
@use "include/idsociety/_blocks_p3" as *;
@use "include/idsociety/_legacy" as *;
@use "include/idsociety/_table-of-contents" as *;
@use "include/event-listing" as *;
@use "include/member-dashboard-page" as *;
@use "include/idsociety/_print" as *;
@use "include/findaprovider" as *;

//html {overflow-x:clip; overflow-y:scroll;}
body {}

html,
#Body {}
body {
    &.idsa-theme {
        --site-primary-color: #{$green};
        --site-accent-color: #{$green};
        --site-accent-text-color: #{$green};
        --site-accent-active-color: #{$yellow};
    }

    &.hivma-theme {
        --site-primary-color: #{$hivma-purple};
        --site-accent-color: #{$yellow};
        --site-accent-text-color: #{$text-black};
        --site-accent-active-color: #{$yellow};
    }

    &.foundation-theme {
        --site-primary-color: #{$foundation-purple};
        --site-accent-color: #{$yellow};
        --site-accent-text-color: #{$text-black};
        --site-accent-active-color: #{$yellow};
    }
}