@mixin font-family-fa() {font-family:Font Awesome\ 5 Free; font-weight:400; -moz-osx-font-smoothing:grayscale; -webkit-font-smoothing:antialiased; display:inline-block; font-style:normal; font-variant:normal; text-rendering:auto; line-height:1;}
@mixin font-family-far() {@include font-family-fa;}
@mixin font-family-fas() {@include font-family-fa; font-weight:900;}
//{display:inline-block; font:normal normal normal 14px/1 FontAwesome; font-size:inherit; text-rendering:auto; -webkit-font-smoothing:antialiased; -moz-osx-font-smoothing:grayscale;}
$spacing: 15px;
$border-color:#bfbfbf;
$primary-border: 1px solid $border-color;
$text-color: #363636;
$main-accent-color: #007bb9;
$secondary-accent-color: #ff8520;
$main-highlight-color:#eef5f7;
$grbc:#bcbcbc;

$badge-primary-color: #D6E8EC;
$date-color-text: #c2569e;
$members-only:$secondary-accent-color;
$gr48:#484848;
$gr2d:#2d2d2d;