@use '_variables' as *;

.author-page {
    .page-title {
        @media all and (min-width:$screen_lg_min) {
            justify-content: space-between;
            grid-template-columns: repeat(2, auto);
        }

        &-heading {
            &__backlink {
                margin-block-end: toRem(24);
            }

            &__title {
                line-height: 1.08;

                @media all and (min-width:$screen_xxl_min) {
                    font-size: toRem(55);
                }
            }

            &__info {
                column-gap: toRem(20);

                .info-social {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: toRem(34);
                    height: toRem(34);
                    background-color: $green;
                    border-radius: 50%;
                    color: $beige;
                    font-size: toRem(16);
                    text-decoration: none;
                }
            }
        }
    }

    .author-bio {
        &__title {
            margin-block-end: toRem(24);
        }

        p {
            margin-block-end: toRem(24);
            color: $body-alt-color;
            font-weight: $fw_medium;
            line-height: 150%;
        }

        *:last-child {
            margin-block-end: 0;
        }

        @media all and (min-width:$screen_lg_min) {
            &__title {
                margin-block-end: toRem(36);
            }

            p {
                margin-block-end: toRem(36);
            }
        }
    }

    .authored-articles {
        margin-block-start: toRem(68);
        margin-block-end: toRem(44);

        @media all and (min-width:$screen_lg_min) {
            margin-block-start: toRem(132);
            margin-block-end: toRem(108);
        }

        &__title {
            margin-block-end: toRem(24);

            @media all and (min-width:$screen_lg_min) {
                margin-block-end: toRem(36);
            }
        }
    }
}
