@use '_variables' as *;

.standardpage-container {
    margin-bottom: toRem(64);
    .container, .container-fluid, .container-xxxl, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {margin-left:0; margin-right:0; padding-left:0; padding-right:0;}
}
.standardpage-row {}
.standardpage-col-left {flex:1 1 100%;}
.standardpage-col-right {flex:1 1 100%;}

.standardpage-contact {margin-bottom:toRem(36);}
.standardpage-contact-header {color:$body-alt-color; margin-bottom:toRem(12); font-weight:$fw_semibold; line-height:.99;}
.standardpage-contact-email {display:block;
    a {color:var(--site-accent-text-color); font-weight:$fw_semibold; letter-spacing:toRem(0.36); text-decoration:none;
        &:hover, &:focus {text-decoration:underline;}
    }
}

@media all and (min-width:$screen_xl_min) {
    .standardpage-row {flex-wrap:nowrap; justify-content:space-between;}
    .standardpage-col-left {
        p, h1, h2, h3, h4, h5 { max-width:toRem(940); }}
    .standardpage-col-right {flex:0 0 toRem(400); width:toRem(400);}
}
.pagenotfound-image {
    img {max-width:100%; border-radius: 900px;}
}