@use "variables" as *;

.paging-standard {margin-top:45px;
    .pagination {display:inline-block; border-radius:0px;
        .page-item {display:block; float:left; margin:5px 0px;
            .page-link {padding:4px 8px; min-width:38px; border-color:$border-color; border-radius:0px; color:$text-color; font-size:18px; font-weight:600; text-align:center;
                &:hover {background-color:$main-highlight-color;}
                @media screen and (min-width: 992px) {
                    & {padding:14px 8px; min-width:60px;}
                }
            }
            &.active {
                .page-link {border-color:$main-accent-color; background-color:$main-accent-color; color:#ffffff;}
            }
        }
    }
    .paging-alt &, &.paging-alt {margin-top:5px; text-align:right;
        .pagination {margin-bottom:10px;
            .page-item {
                .page-link {padding:4px 8px; min-width:38px;
                    @media screen and (min-width: 992px) {
                        & {padding:4px 8px; min-width:38px;}
                    }
                }
            }
        }
    }

    .paging-alt-2 &, &.paging-alt-2 {margin-top:5px; text-align:right;
        .pagination {
            .page-item {
                .page-link {padding:4px 8px; min-width:38px;
                    @media screen and (min-width: 992px) {
                        & {padding:4px 8px; min-width:38px;}
                    }
                }
            }
        }
    }
}
.btnViewAll {float:right; margin:10px 0px 5px 10px;
    //a {
    //    &, &:hover {background:$secondary-accent-color; border-color:$secondary-accent-color; border-radius:0px; color:#ffffff !important; font-size:15px; line-//height:23px; font-weight:600;}
    //}
}