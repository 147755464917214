@use '_variables' as *;

@font-face {
    font-family: $quadrant-family;
    font-style: normal;
    font-weight: normal;
    src: url("/fonts/QuadrantText-Regular.woff2")
}

@font-face {
    font-family: $quadrant-family-italic;
    font-style: italic;
    font-weight: normal;
    src: url("/fonts/QuadrantText-RegularItalic.woff2")
}