@use '_variables' as *;
@use '_mixins' as *;
@use "typography" as *;

%commonByline {display:flex; flex-direction:row; flex-wrap:nowrap; margin-bottom:toRem(26); color:var(--site-accent-text-color); font-size:toRem(13); font-weight:$fw_medium; line-height:1;
    .byline-dot {flex-grow:0; flex-shrink:0; display:block; margin-right:toRem(10); width:toRem(12); height:toRem(12); border-radius:50%; background:var(--site-accent-color);}
    @media all and (min-width:$screen_lg_min) {
        & {margin-bottom:toRem(20); font-size:toRem(16);
            .byline-dot {margin-right:toRem(12); width:toRem(14); height:toRem(14);}
        }
    }
}
%commonTitle {color:var(--site-primary-color); margin-bottom:toRem(26);
    @media all and (min-width:$screen_lg_min) {
        & {margin-bottom:toRem(20);}
    }
}
%commonBody {margin-bottom:toRem(26);
    @media all and (min-width:$screen_lg_min) {
        & {margin-bottom:toRem(20);}
    }
}
%commonCtas {@include customGutter(12); margin-top:toRem(40);
    & > [class*="col-"] {margin-bottom:toRem(12);}
}

.home-hero-block {margin-top:toRem(10); margin-bottom:toRem(40);
    .home-hero-masonry {display:grid; grid-template-columns:1fr 1fr; grid-template-rows:1fr 1fr; gap:toRem(16);}
    .home-hero-masonry-item {overflow:hidden;
        &:nth-child(1), &.home-hero-masonry-item-0 {border-radius:toRem(24);}
        &:nth-child(2), &.home-hero-masonry-item-1 {border-radius:50%;}
        &:nth-child(3), &.home-hero-masonry-item-2 {grid-column:span 2; border-radius:toRem(168);}
        picture {display:block;
            img {width:100%; object-fit:cover;}
        }
    }
    .home-hero-body-wrap {display:flex; justify-content:center; align-items:center;}
    .home-hero-body {padding-top:toRem(32); padding-bottom:toRem(32);}
    .home-hero-byline {display:flex; flex-direction:row; flex-wrap:nowrap; margin-bottom:toRem(20); color:var(--site-accent-text-color); font-size:toRem(13); font-weight:$fw_medium; line-height:1;
        .home-hero-byline-dot {flex-grow:0; flex-shrink:0; display:block; margin-right:toRem(10); width:toRem(12); height:toRem(12); border-radius:50%; background:var(--site-accent-color);}
    }
    .home-hero-title {margin-bottom:toRem(8); color:var(--site-primary-color);}
    .home-hero-text {margin-bottom:toRem(20);}
    .home-hero-ctas {@include customGutter(12);
        & > [class*="col-"] {margin-bottom:toRem(12);}
    }

    @media all and (min-width:$screen_lg_min) {
        & {margin-top:toRem(56); margin-bottom:toRem(72);
            .home-hero-masonry {gap:toRem(20); max-width:toRem(690 + $gutter-x);}
            .home-hero-masonry-item {
                &:nth-child(1), &.home-hero-masonry-item-0 {border-radius:toRem(76);}
            }
            .home-hero-body {max-width:toRem(666);}
            .home-hero-byline {margin-bottom:toRem(25); font-size:toRem(16);
                .home-hero-byline-dot {margin-right:toRem(12); width:toRem(14); height:toRem(14);}
            }
            .home-hero-title {margin-bottom:toRem(25);}
            .home-hero-text {margin-bottom:toRem(40);}
        }
    }
}

.news-list-block {@include sectionMargins; //padding-bottom:toRem(28);
    .news-list-title {margin-bottom:toRem(21); font-size:toRem(26); color:var(--site-primary-color);}
    .news-list-item {display:block; position:relative; padding:toRem(28) 0; border-top:1px solid $neutral-400; text-decoration:none;
        &::before {display:none; position:absolute; left:0; right:0; top:0; width:100%; height:1px; background:var(--site-accent-color); content:"";}
    }
    .news-list-pills {@include customGutter(8); margin-bottom:toRem(10);
        .pill {margin-bottom:toRem(8);
            @include iconClassnames {margin-right:toRem(6); font-size:toRem(14);}
        }
    }
    .news-list-item-title {margin-bottom:0; color:$text-black; line-height:1.55; }

    .news-list-item {
        &:hover, &:focus, &:focus-within {border-color:var(--site-primary-color); outline:none;
            &::before {display:block;}
            .news-list-item-title {color:var(--site-accent-text-color);}
        }
    }

    @media all and (min-width:$screen_lg_min) {
        & {//padding-bottom:toRem(52);
            .news-list-title {margin-bottom:toRem(8); font-size:toRem(23); line-height:1.51; letter-spacing:toRem(0.46);}
            .news-list-item {padding:toRem(20) 0;}
            .news-list-pills {margin-bottom:toRem(12);}
            .news-list-item-title {margin-bottom:0; font-size:toRem(20);}
        }
    }
}

.banner-block {position:relative; @include sectionMargins; overflow:hidden; //margin-bottom:toRem(64);
    &.greenwatermark {
        .banner-block-bg, .banner-block-contents {background-color:var(--site-primary-color);}
    }
    &.green {
        .banner-block-bg, .banner-block-contents {background-color:var(--site-primary-color);}
    }
    &.purplewatermark {
        .banner-block-bg, .banner-block-contents {background-color:$purple;}
    }
    &.purple {
        .banner-block-bg, .banner-block-contents {background-color:$purple;}
    }

    $banner-gutter:32;
    .banner-block-wrap {padding-left:0; padding-right:0;}
    .banner-block-bg {position:absolute; left:0; right:0; top:0; bottom:0;}
    .banner-block-contents {padding-top:toRem(56); padding-bottom:toRem(66); position:relative; z-index:1;}
    .banner-block-contents-row {position:relative; z-index:1;}
    .banner-block-bg-icon {display:none; position:absolute; left:50%; top:50%; width:toRem(1394); transform:translate(-46%, -54%);
        svg {fill:rgba($white, 0.05); width:100%;}
    }
    &.greenwatermark, &.purplewatermark {
        .banner-block-bg-icon {display:block;}
    }

    &.banner-block-contained-static, &.banner-block-expandsfullwidth {
        .banner-block-wrap {}
        .banner-block-bg, .banner-block-contents {border-radius:toRem(29);}
        .banner-block-bg {margin:0 auto; transition:max-width 0.5s ease-in-out, border-radius 0.5s ease-in-out;
            @media (min-width:576px) {
                & {max-width:476px;}
            }
            @media (min-width:768px) {
                & {max-width:656px;}
            }
            @media (min-width:992px) {
                & {max-width:896px;}
            }
            @media (min-width:1200px) {
                & {max-width:1076px;}
            }
            @media (min-width:1400px) {
                & {max-width:1256px;}
            }
            @media (min-width:1652px) {
                & {max-width:1588px;}
            }
        }

        &.isExpanded {
            .banner-block-bg {border-radius:0; max-width:100%;}
        }
    }
    &.banner-block-fullwidth {max-width:100%;
        .banner-block-wrap {}
        .banner-block-bg {}
        //.banner-block-contents {padding-left:0; padding-right:0;}
    }

    .banner-body {
        margin-bottom:toRem(52);
        color:$white;
        padding-left: 2rem;
        padding-right: 2rem;
        ul li {
            &::marker {color:$yellow;}
        }
    }
    .banner-byline {display:flex; flex-direction:row; flex-wrap:nowrap; margin-bottom:toRem(26); font-size:toRem(13); font-weight:$fw_medium; line-height:1;
        .banner-byline-dot {flex-grow:0; flex-shrink:0; display:block; margin-right:toRem(12); width:toRem(12); height:toRem(12); border-radius:50%; background:$yellow;}
    }
    .banner-title {margin-bottom:toRem(80); color:$beige;}
    .banner-text {margin-bottom:toRem(20);}
    .banner-body-ctas {
        @include customGutter(12);
        margin-top:toRem(38);
        @media screen and (min-width: $screen_lg_min) {
            flex-wrap: nowrap;
        }
        & > [class*="col-"] {margin-bottom:toRem(12);}
    }
    .banner-cta-wrap { padding-left: 2rem; padding-right: 2rem; }
        .banner-cta-block,
        .banner-cta-link {
            display: block;
            color: var(--site-accent-text-color);
            text-decoration: none;
            .fa-arrow-right {
                color: var(--site-primary-color);
            }
        }
    .banner-cta-link {
        &.isExternalLink {
            .banner-cta-clip-corner-icon-arrow {
                @include iconClassnames {transform: rotate(-45deg);}
            }
        }
    }

    --banner-cta-bg:#{$yellow};
    .banner-cta-wrap {
        &.banner-cta-wrap-beige {--banner-cta-bg:#{$beige};}
        &.banner-cta-wrap-white {--banner-cta-bg:#{$white};}
    }
    @include cardClipAnimation($el-class:'banner-cta', $el-bg:var(--banner-cta-bg));

    $banner-cta-px:25;
    $banner-cta-py:52;
    .banner-cta-clip {min-height:toRem(388);}
    .banner-cta-clip-top {padding:toRem($banner-cta-py) toRem($banner-cta-px) 0 toRem($banner-cta-px);} 
    .banner-cta-clip-bottom-text {display:flex; align-items:center; padding-left:toRem($banner-cta-px); padding-top:toRem($banner-cta-px); padding-bottom:toRem($banner-cta-px); font-size:toRem(14); font-weight:$fw_semibold; line-height:1.43;}

    .banner-cta-block {padding:toRem($banner-cta-py) toRem($banner-cta-px); background-color: var(--banner-cta-bg); border-radius: toRem(50);}

    .banner-cta-clip-top, .banner-cta-block {display: flex; flex-direction: column;}

    .banner-cta-image, .banner-cta-headline, .banner-cta-body {width: 100%;}

    .banner-cta-body {margin-top: auto;}

    .banner-cta-image {margin-bottom:toRem(18);
        img, svg {max-width:100%; max-height:toRem(70);}
    }
    .banner-cta-headline {font-size:toRem(40); line-height:1.1; overflow-wrap: break-word;}

    .banner-cta-wrap {
        &.banner-cta-wrap-rounded {}
        &.banner-cta-wrap-arch {
            @mixin archcss {padding-top:toRem(144); padding-left:toRem(42); padding-right:toRem(42); border-top-left-radius:toRem(400); border-top-right-radius:toRem(400);}
            &.banner-cta-wrap-nocta {
                .banner-cta-clip {@include archcss; min-height:toRem(512);}
            }
            &.banner-cta-wrap-hascta {
                .banner-cta-clip {
                    .banner-cta-clip-top {@include archcss; min-height:toRem(386);}
                }
            }
        }
    }

    @media all and (min-width:$screen_sm_min) {
        & {
            .banner-block-wrap {padding-right:calc(var(--bs-gutter-x)* .5); padding-left:calc(var(--bs-gutter-x)* .5);}

            .banner-cta-wrap {
                &.banner-cta-wrap-rounded {}
                &.banner-cta-wrap-arch {
                    @mixin archcss {padding-top:toRem(144); padding-left:toRem(70); padding-right:toRem(70);}
                    &.banner-cta-wrap-nocta {
                        .banner-cta-clip {@include archcss;}
                    }
                    &.banner-cta-wrap-hascta {
                        .banner-cta-clip {
                            .banner-cta-clip-top {@include archcss;}
                        }
                    }
                }
            }
        }
    }

    @media all and (min-width:$screen_md_min) {
        & {
            .banner-block-bg-icon {width:toRem(1690); transform:translate(-41%, -39%);}
            .banner-body {margin-bottom:0;}
            .banner-cta-col {max-width:toRem(540);}
            .banner-text {margin-bottom:0;}

            .banner-cta-wrap {
                &.banner-cta-wrap-rounded {}
                &.banner-cta-wrap-arch {
                    @mixin archcss {padding-top:toRem(144); padding-left:toRem($banner-cta-px); padding-right:toRem($banner-cta-px);}
                    &.banner-cta-wrap-nocta {
                        .banner-cta-clip {@include archcss;}
                    }
                    &.banner-cta-wrap-hascta {
                        .banner-cta-clip {
                            .banner-cta-clip-top {@include archcss;}
                        }
                    }
                }
            }
        }
    }

    @media all and (min-width:$screen_lg_min) {
        & {
            .banner-body .banner-text {max-width:toRem(666);}
            .banner-byline {margin-bottom:toRem(25); font-size:toRem(16);
                .banner-byline-dot {margin-right:toRem(12); width:toRem(14); height:toRem(14);}
            }

            .banner-cta-wrap {
                &.banner-cta-wrap-rounded {}
                &.banner-cta-wrap-arch {
                    @mixin archcss {padding-top:toRem(144); padding-left:toRem($banner-cta-px); padding-right:toRem($banner-cta-px);}
                    &.banner-cta-wrap-nocta {
                        .banner-cta-clip {@include archcss;}
                    }
                    &.banner-cta-wrap-hascta {
                        .banner-cta-clip {
                            .banner-cta-clip-top {@include archcss;}
                        }
                    }
                }
            }
        }
    }

    @media all and (min-width:$screen_xl_min) {
        & {
            .banner-block-contents {padding-left:toRem(48); padding-right:toRem(48); padding-top:toRem(112); padding-bottom:toRem(132);}

            .banner-byline {margin-bottom:toRem(25); font-size:toRem(16);
                .banner-byline-dot {margin-right:toRem(12); width:toRem(14); height:toRem(14);}
            }
            .banner-title {margin-bottom:toRem(80); font-size:toRem(55);}
            .banner-text {margin-bottom:0;}

            $banner-cta-px:38;
            $banner-cta-py:42;
            .banner-cta-clip {min-height:32rem;}
            .banner-cta-clip-top {padding:toRem($banner-cta-py) toRem($banner-cta-px) 0 toRem($banner-cta-px);}
            .banner-cta-clip-bottom-text {padding-left:toRem($banner-cta-px); padding-top:toRem($banner-cta-px); padding-bottom:toRem($banner-cta-px); font-size:toRem(18);}

            .banner-cta-image {margin-bottom:toRem(45);}
            .banner-cta-headline {font-size:toRem(55);}

            .banner-cta-block {padding:toRem($banner-cta-py) toRem($banner-cta-px);}

            .banner-cta-wrap {
                &.banner-cta-wrap-rounded {}
                &.banner-cta-wrap-arch {
                    @mixin archcss {padding-top:toRem(144); padding-left:toRem($banner-cta-px); padding-right:toRem($banner-cta-px);}
                    &.banner-cta-wrap-nocta {
                        .banner-cta-clip {@include archcss;}
                    }
                    &.banner-cta-wrap-hascta {
                        .banner-cta-clip {
                            .banner-cta-clip-top {@include archcss;}
                        }
                    }
                }
            }
        }
    }
}

.multimedia-feed-block {@include sectionMargins;
    .multimedia-feed-title {margin-bottom:toRem(36); color:var(--site-primary-color); line-height:1;}
    .multimedia-feed-list {
        & > .row {@include customGutter(36);}
    }
    .multimedia-feed-featured {} // radii 29 / 23
    .multimedia-feed-featured-image {
        position:relative;
        border-radius:toRem(29);
        overflow:hidden;
        img, svg {
            width:100%;
            object-fit:cover;
            max-height: 550px;
        }
        svg.empty-image {background:var(--site-primary-color);
            rect {fill:var(--site-primary-color);}
        }
        &.hasTags {border-radius:toRem(29) toRem(29) toRem(29) 0;}
    }
    .multimedia-feed-featured-clip {position:absolute; left:0; bottom:0; padding-top:toRem(10); padding-right:toRem(8); max-width:85%; min-width:toRem(29 + 23 + 23); min-height:toRem(42); border-radius:0 toRem(23) 0 0; background:$beige;
        .multimedia-feed-featured-clip-tl, .multimedia-feed-featured-clip-br {display:block; position:absolute; overflow:hidden;
            &::before {display:block; position:absolute; width:200%; height:200%; border-radius:50%; content:""; bottom:0; left:0;}
        }
        .multimedia-feed-featured-clip-tl {left:0; top:toRem(-29); width:toRem(29); height:toRem(29);
            &::before {box-shadow:toRem(-15) toRem(15) 0 0 $beige;}
        }
        .multimedia-feed-featured-clip-br {right:toRem(-23); bottom:0; width:toRem(23); height:toRem(23);
            &::before {box-shadow:toRem(-12) toRem(12) 0 0 $beige;}
        }
    }

    .multimedia-feed-list-col {}
    .multimedia-feed-item {display:block; position:relative; padding:toRem(26) 0; border-top:1px solid $neutral-400; text-decoration:none;
        &::before {display:none; position:absolute; left:0; right:0; top:0; width:100%; height:1px; background:var(--site-primary-color); content:"";}
    }
    .multimedia-feed-item-pills {@include customGutter(8); margin-bottom:toRem(4);
        .pill {margin-bottom:toRem(8);
            @include iconClassnames {margin-right:toRem(6); font-size:toRem(14);}
        }
    }
    .multimedia-feed-item-title {margin-bottom:0; color:$text-black; line-height:1.6; margin-top: 1rem;}
    .multimedia-feed-item-info {margin-top:toRem(7); color:$dark-grey; font-size:toRem(12); line-height:1.49; overflow:hidden;
        & > .row {@include customGutter(24);
            & > [class*="col-"] {position:relative; margin-top:toRem(8);
                &::before {position:absolute; left:0; top:2px; bottom:2px; width:1px; background:currentColor; content:"";}
            }
        }
    }

    .multimedia-feed-featured {
        .multimedia-feed-item {padding-top:toRem(16); border-top:none;
            &::before {display:none; content:none;}
        }
        .multimedia-feed-item-title {font-size:toRem(26); line-height:1.49;}
        .multimedia-feed-item-info {margin-top:toRem(10); font-size:toRem(14); color:$text-black;}
        .multimedia-feed-item-pills {margin-bottom:0;
            .pill {margin-bottom:toRem(4);}
        }
    }

    .multimedia-feed-item {
        &:hover, &:focus, &:focus-within {border-color:var(--site-primary-color); outline:none;
            &::before {display:block;}
            .multimedia-feed-item-title {color:var(--site-primary-color);}
        }
    }

    .multimedia-feed-list {}

    @media all and (min-width:$screen_lg_min) {
        & {
            .multimedia-feed-title {margin-bottom:toRem(10); line-height:1.1;}
            .multimedia-feed-featured-image {border-radius:toRem(49);
                &.hasTags {border-radius:toRem(49) toRem(49) toRem(49) 0;}
            }
            .multimedia-feed-featured-clip {padding-right:toRem(12);}

            .multimedia-feed-list-col {padding-top:toRem(16);}
            .multimedia-feed-item {padding:toRem(20) 0;}
            .multimedia-feed-item-pills {margin-bottom:toRem(12);}
            .multimedia-feed-item-title {margin-bottom:0; font-size:toRem(20); line-height:1.42; letter-spacing:toRem(0.4);}
            .multimedia-feed-item-info {margin-top:toRem(12); font-size:toRem(14);}

            .multimedia-feed-featured {
                .multimedia-feed-item {padding-top:toRem(18);}
                .multimedia-feed-item-title {font-size:toRem(30); line-height:1.2;}
                .multimedia-feed-item-info {margin-top:toRem(28); font-size:toRem(16); color:$dark-grey;}
                .multimedia-feed-item-pills {margin-bottom:0;
                    .pill {margin-bottom:toRem(4);}
                }
            }
        }
    }
}

.testimonial-block {@include sectionMargins; overflow:hidden;
    .testimonial-row {justify-content:center; flex-wrap:nowrap;}
    .testimonial-body-col {display:flex;
        & > .container {--bs-gutter-x:#{toRem($gutter-x * 2)};}
    }
    .testimonial-body {display:flex; flex-direction:column; justify-content:center; align-items:center; padding:toRem(60) toRem(32) toRem(68); max-width:toRem(900); border:2px solid var(--site-primary-color); border-radius:toRem(280); color:var(--site-accent-text-color); text-align:center;}
    .testimonial-logo {fill:var(--site-primary-color); width:toRem(150);}
    .testimonial-text {margin-bottom:toRem(12); margin-top:toRem(24); font-size:toRem(30); line-height:toRem(43); letter-spacing:toRem(-0.45);}
    .testimonial-byline {display:inline-flex; align-items:center; font-size:toRem(12); font-weight:$fw_medium; line-height:1;}
    .testimonial-byline-dash {display:inline-flex; align-items:center;
        &::before {display:block; margin-right:toRem(12); width:30px; height:2px; background:var(--site-accent-text-color); content:"";}
    }
    .testimonial-image {//display:none; position:relative;
        //&.order-1 {margin-left:toRem(-$gutter-x);}
        //&.order-3 {margin-right:toRem(-$gutter-x);}
    }
    .testimonial-shape {//position:absolute; top:0; bottom:0; padding-bottom:50%; overflow:hidden;
        img {position:absolute; height:100%;}
    }
    .testimonial-image {display:none; position:relative;
        &.order-1 {
            .testimonial-shape {right:0;}
            img {right:0;}
        }
        &.order-3 {
            .testimonial-shape {left:0;}
            img {left:0;}
        }
    }
    .testimonial-shape-rounded {
        img {border-radius:toRem(104);}
    }
    .testimonial-shape-circle {
        img {border-radius:50%;}
    }
    .testimonial-shape-oval, .testimonial-shape-pill {
        img {border-radius:toRem(280);}
    }
    .testimonial-shape-archedframe {
        img {border-radius:toRem(440) toRem(440) toRem(22) toRem(22);}
    }
    .testimonial-image-byline {position:absolute; display:flex; color:var(--site-accent-text-color); font-size:toRem(16); font-weight:$fw_medium; line-height:1;
        &::before {display:block; flex-grow:0; flex-shrink:0; margin-right:toRem(12); width:toRem(16); height:toRem(16); border-radius:50%; background:var(--site-accent-color); content:"";}
    }
    .testimonial-image-byline-left {left:10%;}
    .testimonial-image-byline-center {left:50%; transform:translateX(-50%);}
    .testimonial-image-byline-right {right:10%;}
    .testimonial-image-byline-top {top:10%;}
    .testimonial-image-byline-middle {top:50%; transform:translateY(-50%);}
    .testimonial-image-byline-bottom {bottom:10%;}
    .testimonial-image-byline-center.testimonial-image-byline-middle {transform:translate(-50%, -50%);}
    .testimonial-image {
        &.order-1 {
            .testimonial-image-byline-right {right:0%;}
        }
        &.order-3 {
            .testimonial-image-byline-left {left:0%;}
        }
    }

    @media all and (min-width:$screen_lg_min) {
        & {
            &.has-image-1 {
                .testimonial-row {display: grid; grid-template-columns:1fr toRem(764);}
            }
            &.has-image-2 {
                .testimonial-row {display: grid; grid-template-columns:1fr toRem(564) 1fr;}
            }
            .testimonial-image {display:block;}
        }
    }
    @media all and (min-width:$screen_xl_min) {
        & {
            &.has-image-1 {
                .testimonial-row {grid-template-columns:1fr toRem(964);}
            }
            &.has-image-2 {
                .testimonial-row {grid-template-columns:1fr toRem(764) 1fr;}
            }
            .testimonial-body {padding:toRem(46) toRem(36) toRem(54);} //min-width:toRem(700);
        }
    }
    @media all and (min-width:$screen_xxl_min) {
        & {
            &.has-image-1 {
                .testimonial-row {grid-template-columns:1fr toRem(964);}
            }
            &.has-image-2 {
                .testimonial-row {grid-template-columns:1fr toRem(964) 1fr;}
            }
            .testimonial-logo {width:toRem(186);}
            .testimonial-text {margin-top:toRem(32); margin-bottom:toRem(24); font-size:toRem(55); line-height:toRem(70); letter-spacing:toRem(-0.825);}
            .testimonial-byline {font-size:toRem(16);}
            .testimonial-byline-dash {
                &::before {width:50px;}
            }
            .testimonial-body {padding:toRem(64) toRem(64) toRem(72);}
        }
    }
}

.quicklinks-block {
    .quicklinks-list {
        border-radius: toRem(12);
        overflow: hidden;
        $quicklinkslist-gap:16;
        &.quicklinks-two-col {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            gap: 0 toRem($quicklinkslist-gap);

            .quicklinks-listitem {
                flex: 1 1 100%;
                @media (min-width: $screen_md_min) {
                    flex:0 0 calc(50% - #{toRem($quicklinkslist-gap / 2)});

                    &:first-child {
                        border-top-right-radius: toRem(12);
                        overflow: hidden;
                    }

                    &:nth-child(2) {
                        border-top-left-radius: toRem(12);
                        overflow: hidden;
                        border-top-color: transparent;
                    }
                }

                &:nth-last-child(1):nth-child(odd) {
                    .quicklinks-item {
                        //width: 50%;
                    }
                }

                &:only-child {
                    .quicklinks-item {
                        width: 100% !important;
                    }
                }
            }
        }
    }

    .quicklinks-listitem {
        border-top: 1px solid $neutral-400;

        &:first-child {
            border-top: none;
        }
        &.isExternalLink {
            .btn-cta-arrow {
                transform: rotate(-45deg);
            }
        }
    }

    .quicklinks-item {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        padding: toRem(26) toRem(12);
        color: var(--site-primary-color);
        font-size: toRem(16);
        font-weight: $fw_semibold;
        line-height: .99;
        letter-spacing: toRem(0.32);
        text-decoration: none;
        @extend %btn-cta-arrow;

        .btn-cta-arrow {
            margin-left: toRem(12);
            color: var(--site-primary-color);
        }

        &:hover,
        &:focus,
        &:focus:not(:focus-visible),
        &:focus-visible,
        &:focus-within {
            background: $white;
            outline: none;
        }
    }

    @media all and (min-width:$screen_lg_min) {
        & {
            .quicklinks-list {
                border-radius: toRem(12) toRem(12) 0 0;
            }

            .quicklinks-listitem {
                border-top: none;
            }

            .quicklinks-item {
                position: relative;
                padding: toRem(24) toRem(18);
                border-bottom: 1px solid $neutral-400;
                color: var(--site-primary-color);
                font-size: toRem(18);
                letter-spacing: toRem(0.36);

                &::before {
                    display: none;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 1px;
                    background: $green;
                    content: "";
                }

                &:hover,
                &:focus,
                &:focus:not(:focus-visible),
                &:focus-visible,
                &:focus-within {
                    border-bottom: 1px solid var(--site-primary-color);

                    &::before {
                        display: block;
                    }
                }
            }
        }
    }

    @include sectionMargins;
    section & {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.spotlight-block {@include sectionMargins;

    .spotlight-block-row {@include customGutter(48); justify-content:space-between;}
    .spotlight-image-col {margin-bottom:toRem(28);}
    .spotlight-body-col {}

    .spotlight-image-frame {display:flex; flex-direction:row; flex-wrap:nowrap; align-items:center; overflow:hidden; position:sticky; top:toRem(120);} //top:toRem(24);
    .spotlight-image {flex:0 0 100%; width:100%; order:2;
        &.active {order:1;}
    }
    .spotlight-tab-frame {display:flex; flex-direction:row; flex-wrap:nowrap; align-items:flex-start; overflow:hidden;}
    .spotlight-tab-item {flex:0 0 100%; width:100%; order:2; user-select:none; visibility:hidden;
        &.active {order:1; user-select:auto; visibility:visible;}
    }
    .spotlight-byline {@extend %commonByline;}
    .spotlight-title {@extend %commonTitle;}
    .spotlight-bodytext {@extend %commonBody;}
    .spotlight-tab-ctas {@extend %commonCtas;}

    /*.spotlight-tab-nav {display:flex; flex-direction:row; flex-wrap:wrap; margin-left:toRem(-8); margin-right:toRem(-8); margin-bottom:toRem(26);
        .spotlight-tab-pill {margin-left:toRem(8); margin-right:toRem(8); margin-top:toRem(8); padding:toRem(14) toRem(24); border:2px solid var(--site-primary-color); border-radius:toRem(12); color:var(--site-primary-color); font-size:toRem(14); font-weight:$fw_semibold; line-height:1; transition:background 0.3s ease, color 0.3s ease, border-radius 0.3s ease; will-change:background, color, border-radius; cursor:pointer;
            &.active {border-radius:toRem(24); background:var(--site-primary-color); color:$white;} 
            &:hover, &:focus {border-radius:toRem(24); outline:none;}
        }
    }*/
    @include idsaTabNav('spotlight');

    .spotlight-image {position:relative;
        img {max-width:100%;} 
    }
    .spotlight-shape {position:relative; margin-left:auto; margin-right:auto; max-width:toRem(400); text-align:center;}
    .spotlight-shape-rounded {
        img {border-radius:toRem(104);}
    }
    .spotlight-shape-circle {
        img {border-radius:50%;}
    }
    .spotlight-shape-oval, .spotlight-shape-pill {
        img {border-radius:toRem(330);}
    }
    .spotlight-shape-archedframe {
        img {border-radius:toRem(330) toRem(330) toRem(22) toRem(22);}
    }
    .spotlight-image-byline {position:absolute; display:flex; color:var(--site-accent-text-color); font-size:toRem(16); font-weight:$fw_medium; line-height:1;
        &::before {display:block; flex-grow:0; flex-shrink:0; margin-right:toRem(12); width:toRem(16); height:toRem(16); border-radius:50%; background:var(--site-accent-color); content:"";}
    }
    .spotlight-image-byline-left {left:10%;}
    .spotlight-image-byline-center {left:50%; transform:translateX(-50%);}
    .spotlight-image-byline-right {right:10%;}
    .spotlight-image-byline-top {top:10%;}
    .spotlight-image-byline-middle {top:50%; transform:translateY(-50%);}
    .spotlight-image-byline-bottom {bottom:10%;}
    .spotlight-image-byline-center.spotlight-image-byline-middle {transform:translate(-50%, -50%);}
    .spotlight-image {
        &.order-1 {
            .spotlight-image-byline-right {right:0%;}
        }
        &.order-3 {
            .spotlight-image-byline-left {left:0%;}
        }
    }
    
    @media all and (min-width:$screen_xl_min) {
        & {
            .spotlight-image-col {margin-bottom:0;}
            
            .spotlight-image {
                img {width:auto;}
            }
            .spotlight-shape {position:static; max-width:none; text-align:left;}
            .spotlight-image-col {
                &[class*="order-"] {
                    .spotlight-shape {text-align:right;}
                }
            }
        }
    }
    
    @media all and (min-width:$screen_xl_min) {
        & {
            .spotlight-block-row {align-items:center;}
            .spotlight-image-frame {position:static; top:0;}
        }
    }
}

.multicolumn-block {@include sectionMargins;
    .multicolumn-byline {@extend %commonByline;}
    .multicolumn-title {@extend %commonTitle;}
    .multicolumn-bodytext {@extend %commonBody;}
    .multicolumn-ctas {@extend %commonCtas; margin-top:toRem(24);}

    .multicolumn-row {@include customGutter(64);}
    .multicolumn-header-body {}
    .multicolumn-header-col {margin-bottom:toRem(26);
        &.sticky-header {
            .multicolumn-header-body {}
        }
    }
    .multicolumn-content-col {
        &.sticky-header {}
        & > .row {
            & > [class*="col-"] {display:flex;
                & > * {flex-grow:1;}
            }
        }
    }
    .multicolumn-content-item {margin-bottom:toRem(32);}
    
    @media all and (min-width:$screen_lg_min) {
        & {
            $sticky-offset:240;
            .multicolumn-header-body {}
            .multicolumn-header-col {
                &.sticky-header {
                    .multicolumn-header-body {position:sticky; top:toRem($sticky-offset);}
                }
            }
            .multicolumn-content-col {
                &.sticky-header {padding-top:calc(75dvh - #{toRem($sticky-offset)}); padding-top:calc(75vh - #{toRem($sticky-offset)});}
            }
        }
    }
}

.mediacard-block {@include sectionMargins; padding: toRem(36) toRem(34);
    &[role="listitem"] {margin-bottom:0;}

    &.mediacard-block-nobackground {
        border: 2px solid rgba(255, 255, 255, 0);
        border-radius: toRem(29);
    }

    &.mediacard-block-bordered {
        border: 2px solid var(--site-primary-color);
        border-radius: toRem(29);
    }

    .mediacard-boundary {}

    .mediacard-wrap {}

    .mediacard-image {
        margin-bottom: toRem(18);
    }

    .mediacard-image-svg {

        img,
        svg {
            width: toRem(72);
        }
    }

    .mediacard-shape {
        width: toRem(156);

        img {
            max-width: 100%;
        }
    }

    .mediacard-shape-rounded {
        img {
            border-radius: toRem(29);
        }
    }

    .mediacard-shape-circle {
        img {
            border-radius: 50%;
        }
    }

    .mediacard-shape-oval, .mediacard-shape-pill {
        img {
            border-radius: toRem(78);
        }
    }

    .mediacard-shape-archedframe {
        img {border-radius:toRem(78) toRem(78) toRem(16) toRem(16);}
    }

    .mediacard-body {
        flex-grow: 1;
    }

    .mediacard-pills {
        @include customGutter(8);
        margin-bottom: toRem(8);

        .pill {
            margin-bottom: toRem(8);

            @include iconClassnames {
                margin-right: toRem(6);
                font-size: toRem(14);
            }
        }
    }

    .mediacard-title,
    .mediacard-title-link {
        display: block;
        margin-bottom: toRem(18);
        color: var(--site-primary-color);
        line-height: 1.25;
        letter-spacing: toRem(-0.52);
        text-decoration: none;
    }

    .mediacard-title-link {
        display: inline-flex;
        align-items: center;
        @extend %btn-cta-arrow-lg;

        .btn-cta-arrow {
            margin-left: toRem(12);
        }

        &.isInternalLink,
        &.isExternalLink {
            //&::after {display:inline-block; margin-left:toRem(6); @include font-family-fas(); font-size:0.65em; line-height:1.693em; content:"\f054";} //font-size:0.8em; line-height:1.375em;
        }

        &.isInternalLink {}

        &.isExternalLink {

            //&::after {margin-left:toRem(8); transform:rotate(-45deg) translateY(-0.1em);} //font-size:1em; line-height:1em;
            .btn-cta-arrow {
                transform: rotate(-45deg) translateY(-0.1em);
            }
        }

        &:hover,
        &:focus {
            @include textUnderlineCustom;
        }
    }

    .mediacard-text {
        margin-bottom: toRem(18);
    }

    .mediacard-ctas {}

    @media all and (min-width: $screen_xxl) {
        .col-12 > & {
            // when direct child of column,
            // and very high resolution
            // set the max-width to be:
            // the max-width of content
            // minus the internal padding of the mediacard * 2
            // minus the main content's padding / 2
            max-width: calc($main-content-container-max-width - 6.25rem - ($desktop-main-content-internal-side-padding / 2));
            margin-left: 0;
            margin-right: 0;
        }
    }


    @media all and (min-width:$screen_lg_min) {
        & {
            padding: toRem(50);

            .mediacard-wrap {
                display: flex;
                flex-wrap: nowrap;
                gap: toRem(20);
            }

            .mediacard-image {
                margin-bottom: 0;
            }

            .mediacard-image-svg {
                flex-grow: 0;
                flex-shrink: 0;
            }

            .mediacard-shape {
                width: toRem(72);
            }

            .mediacard-shape-rounded {
                img {
                    border-radius: toRem(16);
                }
            }

            .mediacard-shape-oval {
                img {
                    border-radius: toRem(36);
                }
            }

            //.mediacard-shape-archedframe {
            //    img {border-radius:toRem(36) toRem(36) toRem(22) toRem(22);}
            //}

            .mediacard-title,
            .mediacard-title-link {
                letter-spacing: toRem(-0.6);
            }
        }
    }

    @media all and (min-width:$screen_xl_min) {
        & {
            .mediacard-wrap {
                display: flex;
                flex-wrap: nowrap;
                gap: toRem(32);
            }

            .mediacard-shape {
                width: toRem(156);
            }

            .mediacard-shape-rounded {
                img {
                    border-radius: toRem(29);
                }
            }

            .mediacard-shape-oval {
                img {
                    border-radius: toRem(78);
                }
            }
        }
    }
}

.multicolumn-content-col { 
    &.sticky-header {
        .mediacard-block {transition:margin 0.5s ease, background 0.5s ease, color 0.5s ease, border-color 0.5s ease;
            .mediacard-wrap {transition:padding 0.5s ease, margin 0.5s ease;}
            &.mediacard-block-nobackground {}
            &.mediacard-block-bordered {
                .mediacard-title, .mediacard-title-link, .mediacard-text * {transition:color 0.5s ease;}
                .mediacard-image.mediacard-image-svg {fill:var(--site-primary-color); transition:fill 0.5s ease;}
            }
            &.isFocused {margin-left:toRem(-$gutter-x); margin-right:toRem(-$gutter-x);
                &.mediacard-block-bordered {background:var(--site-primary-color); color:$white;
                    .mediacard-title, .mediacard-title-link, .mediacard-text * {color:$white;}
                    .mediacard-ctas .btn {
                        &.btn-secondary, &.btn-inline, &.btn-text {color:$white;}
                    }
                    .mediacard-image.mediacard-image-svg {
                        img, svg {fill:var(--site-accent-active-color);}
                    }
                }
                &.mediacard-block-nobackground {background:$white; border-color:$white;}
                .mediacard-wrap {padding-left:0; padding-right:0; margin-left:toRem($gutter-x); margin-right:toRem($gutter-x);}
            }

            @media all and (min-width:$screen_lg_min) {
                .mediacard-wrap {margin-left:0; margin-right:0; transition:none;}
                .mediacard-body {transition:margin 0.5s ease;}
                &.isFocused {
                    .mediacard-wrap {margin-left:0; margin-right:0;
                        &.imageLeft {
                            .mediacard-body {margin-left:toRem($gutter-x * 2);}
                        }
                        &.imageRight, &.noImage {
                            .mediacard-body {margin-right:toRem($gutter-x * 2);}
                        }
                    }
                }
            }
        }
    }
}

.menu-item-content-block {
    .menu-item-content-image {position:relative; border-radius:toRem(16) toRem(16) toRem(16) 0; overflow:hidden;
        img, svg {width:100%; object-fit:cover;}
    }
    .menu-item-content-clip {position:absolute; left:0; bottom:0; padding-top:toRem(10); padding-right:toRem(8); max-width:85%; min-width:toRem(16 + 16 + 16); min-height:toRem(42); border-radius:0 toRem(16) 0 0; background:$white;
        .menu-item-content-clip-tl, .menu-item-content-clip-br {display:block; position:absolute; overflow:hidden;
            &::before {display:block; position:absolute; width:200%; height:200%; border-radius:50%; content:""; bottom:0; left:0;}
        }
        .menu-item-content-clip-tl {left:0; top:toRem(-16); width:toRem(16); height:toRem(16);
            &::before {box-shadow:toRem(-8) toRem(8) 0 0 $white;}
        }
        .menu-item-content-clip-br {right:toRem(-16); bottom:0; width:toRem(16); height:toRem(16);
            &::before {box-shadow:toRem(-8) toRem(8) 0 0 $white;}
        }
    }

    .menu-item-content-item {display:block; position:relative; padding:toRem(16) 0; text-decoration:none;}
    .menu-item-content-pills {@include customGutter(8); margin-bottom:0;
        .pill {margin-bottom:toRem(4);
            @include iconClassnames {margin-right:toRem(6); font-size:toRem(14);}
        }
    }
    .menu-item-content-title {margin-bottom:0; color:$text-black; line-height:1.38;}
    a.menu-item-content-item {
        &:hover, &:focus, &:focus-within {
            .menu-item-content-title {color:var(--site-primary-color);}
        }
    }
}

.accordionsection-block {@include sectionMargins;} //padding-top:toRem(36); padding-bottom:toRem(36);
.accordionsection-header-body {margin-bottom:toRem(40);}
.accordionsection-byline {@extend %commonByline;}
.accordionsection-title {@extend %commonTitle;}
.accordionsection-bodytext {@extend %commonBody;}
.accordionsection-ctas {@extend %commonCtas;}
.accordionsection-row {@include customGutter(38);}


.accordion-block {border-bottom:1px solid $neutral-400;}
.accordion-block-header {position:relative;}
.accordion-block-button {display:flex; align-items:center; margin:0; padding:toRem(22) toRem(6); width:100%; border:none; background:none; color:var(--site-primary-color); font-size:toRem(18); font-weight:$fw_semibold; line-height:.99; letter-spacing:toRem(0.36); text-align:left;
    &::before {position:absolute; left:0; right:0; top:-1px; height:1px; background:var(--site-primary-color); content:"";}
    .fas {margin-left:auto; padding:0 toRem(6) 0 toRem(12); font-size:toRem(14); color:var(--site-primary-color);}
    &.collapsed {color:$text-black;
        &::before {background:$neutral-400;}
        .fas {
            &::before {color:$text-black; content:"\+";}
        }
    }
}
.accordion-block-body {}
.accordion-block-text {margin-bottom:toRem(38); padding:0 toRem(6);}

$cta-gutter:36;
.ctasection-block {@include sectionMargins; //margin-top:toRem(68); margin-bottom:toRem(44);
    .ctasection-header {}
    .ctasection-byline {@extend %commonByline;}
    .ctasection-title {@extend %commonTitle;}
    .ctasection-bodytext {@extend %commonBody;}
    .ctasection-row {@include customGutter($cta-gutter);}
    .ctasection-content {}
    @media all and (min-width:$screen_lg_min) {
        & {} //margin-top:toRem(132); margin-bottom:toRem(112);
    }
}
.ctablock-main {margin-bottom:toRem($cta-gutter); overflow:hidden;
    .ctablock-image {position:relative; margin-bottom:toRem(16);
        picture {display:block; border-radius:toRem(30); overflow:hidden;}
        img {width:100%; object-fit:cover;}
    }
    .ctablock-pills-clip {position:absolute; left:0; bottom:0; padding-top:toRem(10); padding-right:toRem(8); max-width:85%; min-width:toRem(29 + 23 + 23); min-height:toRem(42); border-radius:0 toRem(23) 0 0; background:$beige;
        .ctablock-pills-clip-tl, .ctablock-pills-clip-br {display:block; position:absolute; overflow:hidden;
            &::before {display:block; position:absolute; width:200%; height:200%; border-radius:50%; content:""; bottom:0; left:0;}
        }
        .ctablock-pills-clip-tl {left:0; top:toRem(-29); width:toRem(29); height:toRem(29);
            &::before {box-shadow:toRem(-15) toRem(15) 0 0 $beige;}
        }
        .ctablock-pills-clip-br {right:toRem(-23); bottom:0; width:toRem(23); height:toRem(23);
            &::before {box-shadow:toRem(-12) toRem(12) 0 0 $beige;}
        }
    }
    .ctablock-pills {@include customGutter(8); margin-bottom:0;
        .pill {margin-bottom:toRem(4);
            @include iconClassnames {margin-right:toRem(6); font-size:toRem(14);}
        }
    }
    .ctablock-title {margin-bottom:toRem(12); font-weight:$fw_semibold; line-height:1.48; letter-spacing:0;}
    .ctablock-titlelink {color:$text-black; text-decoration:none;
        &:hover, &:focus {color:var(--site-primary-color);}
    }
    
    .ctablock-ctas {@extend %commonCtas; @include customGutter(24); margin-top:toRem(32);
        .btn {
            &.btn-secondary, &.btn-text {padding-left:0; padding-right:0;}
        }
    }
    @media all and (min-width:$screen_lg_min) {
        & {
            .ctablock-pills-clip {padding-right:toRem(12);}
            //.ctablock-pills {margin-bottom:0;
            //    .pill {margin-bottom:toRem(4);}
            //}
        }
    }
}


.events-featuredlisting-block {@include sectionMargins; //margin-top:toRem(68); margin-bottom:toRem(80);
    .events-featuredlisting-title {margin-bottom:toRem(38); color:var(--site-primary-color);}
    .events-featuredlisting-list {@include customGutter(36);}
    .events-featuredlisting-col {display:flex;}
    .events-featuredlisting-bodycol {display:flex; align-items:center; margin-bottom:toRem(36);}
    .events-featuredlisting-item {flex:0 0 100%; width:100%; display:flex; position:relative; margin-bottom:toRem(36); color:$text-black; text-decoration:none; overflow:hidden;}
    
    .events-featuredlisting-item {
        @include cardArrowAnimated();
    }
    
    @media all and (min-width:$screen_lg_min) {
        & {//margin-top:toRem(96); margin-bottom:toRem(146);
        }
    }
}

.events-featuredlisting-item-body {flex:0 0 100%; width:100%; padding:toRem(30); background:$white; border-radius:toRem(35);}
.events-featuredlisting-calendar {display:inline-block; min-width:toRem(88); margin-bottom:toRem(74); border:2px solid var(--site-primary-color); border-radius:toRem(23); overflow:hidden;
    &.calendar-span {min-width:toRem(134);}
}
.events-featuredlisting-calendar-mm {display:flex; flex-wrap:nowrap; justify-content:space-around; padding:toRem(8) toRem(24); background:var(--site-primary-color); color:$white; font-size:toRem(14); font-weight:$fw_semibold; line-height:1.11;
}
.events-featuredlisting-calendar-dd {padding:toRem(6) toRem(16); @include quadrant; color:var(--site-primary-color); font-size:toRem(35); font-weight:$fw_regular; line-height:1.11; text-align:center;}
.events-featuredlisting-calendar-yy {margin-bottom:toRem(8); font-size:toRem(14); font-weight:$fw_medium; line-height:.99;}
.events-featuredlisting-item-title {padding-right:toRem(102); font-size:toRem(22); font-weight:$fw_semibold; line-height:1.41; letter-spacing:toRem(0.44);}

$gridlisting-gutter:36;
.gridlisting-block {@include sectionMargins; //margin-top:toRem(68); margin-bottom:toRem(44);
    .gridlisting-header {}
    .gridlisting-byline {@extend %commonByline;}
    .gridlisting-title {@extend %commonTitle;}
    .gridlisting-bodytext {@extend %commonBody;}

    @media all and (min-width:$screen_lg_min) {
        & {}//margin-top:toRem(132); margin-bottom:toRem(112);
    }
}

.gridlisting-content {
    .gridlisting-row {@include customGutter($gridlisting-gutter);
        & > div[class*="col-"] {display:flex;}
    }

    .gridlisting-card {display:flex; flex-direction:column; justify-content:space-between; flex:1 1 100%; width:100%; position:relative; margin-bottom:toRem(28); padding:toRem(30); background:$white; border-radius:toRem(29); overflow:hidden; text-decoration:none;}
    .gridlisting-card-topwrap {}
    .gridlisting-card-image {margin-bottom:toRem(30);
        img {width:100%; border-radius:toRem(280);}
    }
    .gridlisting-card-body {flex-grow:1;}
    .gridlisting-card-pills {@include customGutter(8); margin-bottom:toRem(4);
        .pill {margin-bottom:toRem(8);
            @include iconClassnames {margin-right:toRem(6); font-size:toRem(14);}
        }
    }
    .gridlisting-card-title, .gridlisting-card-title-link {margin-top: toRem(14); width:100%; color:$text-black; text-decoration:none;}
    .gridlisting-card-title-link {
        &.isInternalLink, &.isExternalLink {
            &::after {display:inline-block; margin-left:toRem(6); @include font-family-fas(); font-size:0.65em; line-height:1.693em; content:"\f054";} //font-size:0.8em; line-height:1.375em;
        }
        &.isInternalLink {}
        &.isExternalLink {
            &::after {margin-left:toRem(8); transform:rotate(-45deg) translateY(-0.1em);} //font-size:1em; line-height:1em;
        }
    }
    .gridlisting-card-summary {margin-top:toRem(14); color:$body-alt-color; font-size:toRem(16); font-weight:$fw_regular;}
    .gridlisting-card-text {margin-bottom:toRem(18);}
    .gridlisting-card-info {margin-top: auto; padding-right:toRem(72); padding-top:toRem(20); min-height:toRem(72); font-weight:$fw_regular;}
    .gridlisting-card-info-row {display:flex; flex-wrap:nowrap; margin-bottom:toRem(9); gap:toRem(6); color:$dark-grey; font-size:toRem(14);}
    .gridlisting-card-info-icon {flex:0 0 toRem(24); display:flex; justify-content:center; align-items:center; width:toRem(24); height:toRem(24); background:$light-purple; color:$purple; border-radius:toRem(12);}
    .gridlisting-card-info-text {line-height:toRem(24); text-wrap: nowrap; overflow: hidden; text-overflow: ellipsis;}

    .gridlisting-card-clip {}
    .gridlisting-card {
        @include cardArrowAnimated;
        .card-arrow-animated {transform:translate(0%, 0%); transition:none;}
    }
    .gridlisting-morebtn {margin-top:toRem(8); font-size:toRem(18); font-weight:$fw_semibold;}
    
    @media all and (min-width:$screen_md_min) {
        & {
            .gridlisting-card {}
            .gridlisting-card-topwrap {display:flex; flex-wrap:nowrap; gap:toRem(36);}
            .gridlisting-card-image {order:2; flex:0 0 33%; margin-bottom:toRem(24); width:33%; max-width:toRem(205);}
            .gridlisting-card-body {order:1; flex:1 1 100%;}
            //.gridlisting-card-info {padding-right:0; min-height:auto;}
            .gridlisting-card-info {padding-top:toRem(38);}
            .gridlisting-card-pills {margin-bottom:toRem(10);}
            .gridlisting-card-title, .gridlisting-card-title-link {margin-top: toRem(14);}
            //.gridlisting-card-clip {display:none;}
            .gridlisting-cart-summary { font-weight: $fw_regular; color: $text-grey}
            .gridlisting-card {transition:border-radius 0.35s ease; will-change:border-radius;
                .card-arrow-animated {transform:translate(101%, 101%); transition:transform 0.35s ease; will-change:transform;}
                &:hover, &:focus, &:focus-within {border-radius:toRem(29) toRem(29) toRem(14) toRem(29);
                    .card-arrow-animated {transform:translate(0%, 0%);}
                }
            }
        }
    }
    
    @media all and (min-width:$screen_lg_min) {
        & {
            .gridlisting-card {padding:toRem(43) toRem(45) toRem(34) toRem(37);}
            .gridlisting-card-title, .gridlisting-card-title-link {}
            .gridlisting-card-topwrap {display:block;}
            .gridlisting-card-image {order:1; margin-bottom:toRem(30); width:auto; max-width:none;}
            .gridlisting-card-body {order:2;}
            .gridlisting-card-info {padding-right:toRem(102 - 45); min-height:toRem(102 - 34)}
        }
    }
    
    @media all and (min-width:$screen_xl_min) {
        & {
            .gridlisting-card-topwrap {display:flex;}
            .gridlisting-card-image {order:2; flex:0 0 33%; margin-bottom:toRem(24); width:33%; max-width:toRem(205);}
            .gridlisting-card-body {order:1; flex:1 1 100%;}
            .gridlisting-card-info {}
        }
    }
}

.multimedia {
    .gridlisting-card {position: relative;}
    .gridlisting-card-info {display:flex; gap:toRem(16); align-items: end;}
    .gridlisting-card-description {color: $body-alt-color; font-weight: $fw_regular; font-size: toRem(16); margin-bottom: toRem(14);}
    .gridlisting-card-info {padding-top: 0;}
    .gridlisting-card-body {display:flex; flex-direction:column;}
    .gridlisting-cart-title-info-row {margin-top: auto; display:flex; gap: toRem(16);}
    .gridlisting-card-image img {border-radius: toRem(32);}
}
