@use '_variables' as *;

.idsa-header-main-nav {
    $nav-trigger-w:48;

    &.ddExpanded {
        .idsa-header-main-nav-head {display:none;}
        .idsa-header-utility-links-nav {display:none;}
        nav#idsa-main-nav {
            li {
                &.lvl-0 {display:none;
                    &.ddExpanded {display:block;}
                }
            }
        }
    }

    nav#idsa-main-nav {
        ul, li {display:block; margin:0; padding:0; list-style-type:none;}
        ul {
            &.lvl-0 {}
            &.lvl-1 {}
            &.lvl-2 {margin-bottom:toRem(24);
                &.collapse:not(.show) {display:none;}
            }
        }
        li {
            &.lvl-0 {}
            &.lvl-0.hasChildren {
                &:hover, &:focus, &:focus-within {}
            }
            &.lvl-1 {
                a {
                    &:hover, &:focus {@include textUnderlineCustom;}
                }
            }
            &.lvl-2 {}
        }
                .main-nav-item-mobile-lvl-1 {
                    display: flex;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    position: relative;
                }
        
                .main-nav-item-mobile-lvl-1-trigger {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    position: absolute;
                    right: 0;
                    top: calc(50% - 2rem);
                    width: 100%;
                    padding-top: 1.5rem;
                    padding-bottom: 1.5rem;

                    &.collapsed {
                        @include iconClassnames() {
                            &::before {
                                content: "\f078";
                            }
                        }
                    }
                }
        .main-nav-item-desktop-lvl-1 {display:none;}

        a, span {display:flex; align-items:center; text-decoration:none;
            @include iconClassnames() {margin-left:toRem(6); font-size:0.65em;}
            &.lvl-0 {position:relative; padding:toRem(24) 0; color:$text-black; font-size:toRem(16); font-weight:$fw_semibold; line-height:.99; letter-spacing:toRem(0.32);}
            &.lvl-0.hasChildren {}
            &.lvl-1 {padding:toRem(24) 0; color:$text-black; font-size:toRem(16); font-weight:$fw_semibold; line-height:.99; letter-spacing:toRem(0.32);}
            &.lvl-2 {padding:toRem(8) 0; color:$text-black; font-size:toRem(16); font-weight:$fw_medium; line-height:1.5; letter-spacing:toRem(-0.352);}
        }
        .main-nav-dropdown {display:none;}
        .main-nav-dropdown-body {display:none;}
        .main-nav-dropdown-auxlinks {margin-top:toRem(24); padding-top:0; padding-bottom:0; background:$light-green;
            .btn {color:$green; font-size:toRem(14); font-weight:$fw_semibold; letter-spacing:toRem(-0.308);}
        }
.main-nav-item-mobile {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    width: 100%;
    position: relative;
    &.lvl-0.hasChildren {

        .main-nav-trigger-open,
        .main-nav-trigger-close {
            display: none;
            flex: 0 0 auto;
            align-items: center;
            width: toRem($nav-trigger-w);

            &::before {
                @include font-family-fas;
            }
        }

        .main-nav-trigger-open {
            justify-content: flex-end;
            margin-left: auto;
            position: absolute;
            top: calc(50% - 2rem);
            width: 100%;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;

            &::before {
                content: "\f054";
            }
        }

        .main-nav-trigger-close {
            justify-content: flex-start;

            &::before {
                display: flex;
                justify-content: center;
                align-items: center;
                width: toRem(32);
                height: toRem(32);
                border-radius: 50%;
                background: $green;
                color: $white;
                font-size: toRem(14);
                content: "\f053";
            }
        }

        a,
        span {
            &.lvl-0.hasChildren {
                pointer-events: none;
            }
        }

        &.ddExpanded {
            .main-nav-trigger-close {
                display: flex;
            }

            .main-nav-trigger-open {
                display: none;
            }

            &~.main-nav-dropdown {
                display: block;

                .main-nav-dropdown-auxlinks {
                    position: absolute;
                    width: 100%;
                    left: 0;
                    bottom: 0;
                    overflow: hidden;
                }
            }

            a {
                &.lvl-0.hasChildren {
                    margin-right: toRem($nav-trigger-w);
                }
            }
        }

        &:not(.ddExpanded) {
            .main-nav-trigger-open {
                display: flex;
            }
        }
    }
}
        .main-nav-item-desktop {display:none;}
    }

    @media all and (min-width:$desktop_min)
    {
        & {
            $nav-gutter:22;
            $lvl1-gutter:12;
            nav#idsa-main-nav {display:flex; //padding-left:toRem($nav-gutter); padding-right:toRem($nav-gutter);
                ul, li {display:flex;}
                ul {
                    &.lvl-0 {flex-wrap:nowrap;} //@include customLegacyGutter($nav-gutter, 'li');
                    &.lvl-1 {flex-wrap:wrap; @include customLegacyGutter($lvl1-gutter, 'li.lvl-1');}
                    &.lvl-2 {
                        &, &.collapse:not(.show) {display:block;}
                    }
                }
                li {
                    &.lvl-0 {flex:0 1 auto;}
                    &.lvl-0.hasChildren {
                        &:hover, &:focus, &:focus-within {
                            & > .main-nav-dropdown {display:block;}
                        }
                    }
                    &.lvl-1 {flex:0 1 auto; display:block; margin-bottom:toRem(24); width:100%; max-width:toRem(272);}
                    &.lvl-2 {display:block;}
                }
                .main-nav-item-mobile {display:none;}
                .main-nav-item-desktop {display:flex;}

                .main-nav-item-mobile-lvl-1 {display:none;}
                .main-nav-item-desktop-lvl-1 {display:flex;}

                a, span {
                    &.lvl-0 {padding:toRem(12) toRem($nav-gutter); font-weight:$fw_medium;}
                    &.lvl-0.hasChildren {
                        &::after {display:inline-block; margin-left:toRem(6); @include font-family-fas(); font-size:0.65em; line-height:1.693em; content:"\f078";}
                        &:hover, &:focus, &:focus-within {
                            & + .main-nav-dropdown {visibility:visible; transform:translateX(0);}
                        }
                    }
                    &.lvl-1 {margin-bottom:toRem(17); padding:0; color:var(--site-primary-color); font-size:toRem(18); line-height:1.38; letter-spacing:toRem(0.36);}
                    &.lvl-2 {margin-bottom:toRem(12); padding:0; line-height:1.51; letter-spacing:toRem(-0.308);} //font-size:toRem(14);
                }
                .main-nav-dropdown {display:block; position:absolute; left:0; right:0; top:100%; z-index:999; background:$white; @include boxshadow(3px, 15px, 33.8px, -11px, rgba($black, 0.12)); visibility:hidden; transform:translateX(-100%); transition:visibility 0s linear 0.15s, transform 0s linear 0.15s;
                    &:hover, &:focus, &:focus-within {visibility:visible; transform:translateX(0);}
                }
                $dd-body-gutter:17;
                                .main-nav-dropdownwrap {
                                    display: flex;
                                    flex-wrap: nowrap;
                                    justify-content: space-between;
                                    padding: toRem(20) toRem(72) toRem(52);
                                    max-width: 1760px;
                                    margin: 0 auto;
                                }
                                .main-nav-dropdown-auxlinks {
                                    margin-top: 0;
                                    padding-top: 0;
                                    padding-bottom: 0;
                                    margin-left: 0;
                                    margin-right: 0;
                                    max-width: none;
                                    width: 100%;

                                    .btn {
                                        padding-left: 0;
                                        padding-right: 0;
                                    }
                                }
                .main-nav-dropdown-children {margin-top:toRem(4); flex:1 1 100%;}
                .main-nav-dropdown-body-header {margin-top:toRem(4); margin-bottom:toRem(27); color:$green;}
                .main-nav-dropdown-body {flex:0 0 25.8%; padding-left:toRem($dd-body-gutter * 2); border-left:1px solid $neutral-400;
                    .main-nav-dropdown-body-col {flex:0 0 100%;}
                    .main-nav-dropdown-body-row {display:flex; @include customLegacyGutter($dd-body-gutter);}
                    &.main-nav-dropdown-body-2-wide {flex:0 0 51.6%;
                        .main-nav-dropdown-body-col {flex:0 0 50%;}
                    }
                }
            }
        }
    }

    @media all and (min-width:$screen_xl_min)
    {
        & {
            nav#idsa-main-nav {
                .main-nav-dropdown-body {display:block;}
            }
        }
    }
}

.foundation-theme {
    .idsa-header-main-nav {
        nav#idsa-main-nav {
            a.lvl-0, span.lvl-0 {width:100%; pointer-events:auto;}
            .main-nav-item-mobile {
                &.lvl-0.hasChildren {
                    a, span {
                        &.lvl-0.hasChildren {width:100%; pointer-events:auto;}
                    }
                }
            }
        }
    }
}