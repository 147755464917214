@use '_variables' as *;
@use "sass:math";

@mixin cardClipAnimation ($el-class:'card', $el-animation-duration:0.35s, $el-animation-timing-function:ease, $el-bg:$yellow, $el-card-radius:50, $el-clip-radius:26, $el-clip-inner-radius:28, $el-clip-dim:90, $el-clip-gap:14, $el-clip-arrow-radius:19) {
    //$el-clip-dim must be at least double the $el-clip-radius value
    $el-clip-full:toRem($el-clip-dim + $el-clip-radius);

    .#{$el-class}-clip {position:relative; display:flex; flex-direction:column;
        .#{$el-class}-clip-top {width:100%; flex-grow:1; flex-shrink:1; background:$el-bg; border-radius:toRem($el-card-radius) toRem($el-card-radius) 0 0;}
        .#{$el-class}-clip-bottom {width:100%; min-height:$el-clip-full; flex-grow:0; flex-shrink:0; border-radius:0 0 toRem($el-card-radius) toRem($el-card-radius); display:flex; flex-wrap:nowrap; overflow:hidden; transition:border-radius math.div($el-animation-duration, 2) $el-animation-timing-function;}
        .#{$el-class}-clip-grid {display:grid; width:100%;
            grid-template-columns:1fr $el-clip-full;
            grid-template-areas:"cardtext cardicon";
        }
        .#{$el-class}-clip-corner {display:flex; flex-direction:column; flex-wrap:nowrap; width:100%;}
        .#{$el-class}-clip-bottom-text {width:100%; flex-grow:1; flex-shrink:1; background:$el-bg; transition:border-radius $el-animation-duration $el-animation-timing-function;}
        .#{$el-class}-clip-corner-head {width:100%; height:0px; flex-grow:1; flex-shrink:1; background:$el-bg; transition:height $el-animation-duration $el-animation-timing-function, border-radius $el-animation-duration $el-animation-timing-function;}
        .#{$el-class}-clip-corner-arrow {position:relative; overflow:hidden; width:0; height:0; transition:width $el-animation-duration $el-animation-timing-function, height $el-animation-duration $el-animation-timing-function;}
        .#{$el-class}-clip-corner-icon {flex-grow:0; flex-shrink:0; display:flex; flex-direction:row; flex-wrap:nowrap; width:100%; position:relative; overflow:hidden;}

        .#{$el-class}-clip-corner-icon-clip {position:absolute; left:0; top:0; width:toRem(math.div($el-clip-inner-radius, 4)); height:toRem(math.div($el-clip-inner-radius, 4)); overflow:hidden; transition:width $el-animation-duration $el-animation-timing-function, height $el-animation-duration $el-animation-timing-function;}
        .#{$el-class}-clip-corner-icon-clip::before {display:block; position:absolute; width:200%; height:200%; border-radius:50%; content:""; top:0; left:0; box-shadow:toRem(math.div(-$el-clip-inner-radius, 2)) toRem(math.div(-$el-clip-inner-radius, 2)) 0 0 $el-bg;}

        .#{$el-class}-clip-corner-icon-l-fill {flex-grow:1; flex-shrink:1; width:toRem($el-clip-radius); background:$el-bg; transition:border-radius $el-animation-duration $el-animation-timing-function;}

        .#{$el-class}-clip-corner-icon-arrow {display:flex; justify-content:center; align-items:center; margin-left:toRem($el-clip-gap); margin-top:toRem($el-clip-gap); width:0; height:0; background:$el-bg; border-radius:toRem($el-clip-arrow-radius); transition:width $el-animation-duration $el-animation-timing-function, height $el-animation-duration $el-animation-timing-function; font-size:toRem(26);}

        &:hover, &:focus, &:focus-within {
            .#{$el-class}-clip-bottom {border-radius:0 0 toRem($el-clip-arrow-radius) toRem($el-card-radius);}
            .#{$el-class}-clip-corner {}
            .#{$el-class}-clip-corner-head {border-radius:0 0 toRem($el-clip-radius) 0; height:toRem($el-clip-radius);}
            .#{$el-class}-clip-corner-icon-l-fill {border-radius:0 0 toRem($el-clip-radius) 0;}
            .#{$el-class}-clip-corner-arrow {width:toRem($el-clip-dim); height:toRem($el-clip-dim);}
            .#{$el-class}-clip-corner-icon-clip {width:toRem($el-clip-inner-radius); height:toRem($el-clip-inner-radius);}
            .#{$el-class}-clip-corner-icon-arrow {width:toRem($el-clip-dim - $el-clip-gap); height:toRem($el-clip-dim - $el-clip-gap);}
        } 
    }
}

// this default argument looks ugly
// but will allow interopability between native css variables
// and sass variables
@mixin cardArrowAnimated ($color: var(--site-primary-color))
{
    .card-arrow-animated {position:absolute; right:0; bottom:0; transform:translate(101%, 101%); transition:transform 0.35s ease; will-change:transform;}
    .card-arrow-animated-bl {position:absolute; left:toRem(-26); bottom:0; width:toRem(26); height:toRem(26); background:$beige; overflow:hidden;
        &::before {display:block; position:absolute; width:200%; height:200%; border-radius:50%; content:""; bottom:0; right:0; background:$white;}
    }
    .card-arrow-animated-tr {position:absolute; right:0; top:toRem(-26); width:toRem(26); height:toRem(26); background:$beige; overflow:hidden;
        &::before {display:block; position:absolute; width:200%; height:200%; border-radius:50%; content:""; bottom:0; right:0; background:$white;}
    }
    .card-arrow-animated-in {}
        .card-arrow-animated-icon {
            padding: toRem(12) 0 0 toRem(12);
            border-radius: toRem(28) 0 0 0;
            background: $beige;
            font-size: toRem(19);
            color: $color;

            @include iconClassnames {
                display: flex;
                justify-content: center;
                align-items: center;
                width: toRem(64);
                height: toRem(64);
                background: $white;
                border-radius: toRem(19);
            }
        }
    .card-arrow-link {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        color: transparent;
    }
    & {
        &:hover, &:focus, &:focus-within {
            .card-arrow-animated {transform:translate(0%, 0%);}
        }
    }
}

@mixin idsaTabNav($el-class:'idsa') {
    .#{$el-class}-tab-nav {display:flex; flex-direction:row; flex-wrap:wrap; margin-left:toRem(-8); margin-right:toRem(-8); margin-bottom:toRem(26);
        .#{$el-class}-tab-pill:not(li) {margin-left:toRem(8); margin-right:toRem(8); margin-top:toRem(8); padding:toRem(14) toRem(24); border:2px solid var(--site-primary-color); border-radius:toRem(12); color:var(--site-primary-color); font-size:toRem(14); font-weight:$fw_semibold; line-height:1; transition:background 0.3s ease, color 0.3s ease, border-radius 0.3s ease; will-change:background, color, border-radius; cursor:pointer;
            &.active {border-radius:toRem(24); background:var(--site-primary-color); color:$white;}
            &:hover, &:focus {border-radius:toRem(24); outline:none;}
        }
    
        @media all and (min-width:$screen_lg_min) {
            & {margin-top:toRem(40); margin-bottom:toRem(54);
                .#{$el-class}-tab-pill:not(li) {padding:toRem(11) toRem(24);
                    &.active {}
                    &:hover, &:focus {}
                }
            }
        }
    }
    ul.#{$el-class}-tab-nav {
        & {padding:0; list-style-type:none;}
        & > li {margin:0; padding:0; list-style-type:none;}

        li.#{$el-class}-tab-pill {position:relative; margin-left:toRem(8); margin-right:toRem(8); margin-top:toRem(8); padding:0; color:var(--site-primary-color); font-size:toRem(14); font-weight:$fw_semibold; line-height:1; cursor:pointer;
            label {display:block; padding:toRem(14) toRem(24); border:2px solid var(--site-primary-color); border-radius:toRem(12); transition:background 0.3s ease, color 0.3s ease, border-radius 0.3s ease; will-change:background, color, border-radius;}
            input {position:absolute; left:0; right:0; top:0; bottom:0; width:100%; height:100%; opacity:0;
                &:checked {
                    & + label {border-radius:toRem(24); background:var(--site-primary-color); color:$white;}
                }
                &:hover, &:focus {
                    & + label {border-radius:toRem(24); outline:none;}
                }
            }
        }
    
        @media all and (min-width:$screen_lg_min) {
            & {
                li.#{$el-class}-tab-pill {
                    label {padding:toRem(11) toRem(24);}
                }
            }
        }
    }
}

// preferabily use this inside a ::before or ::after pseudo-element
// because it has opinion on display properties
// as well as width and height.
@mixin svg($url: "", $fill: null) {
    $final-url: if(str-slice($url, -4)==".svg", $url, $url + ".svg");
    content: "";
    background-color: transparent;

    @if $fill {
        mask-image: url($final-url);
        mask-size: contain;
        mask-position: center;
        mask-repeat: no-repeat;
        background-color: $fill;
    }

    @else {
        background-image: url($final-url);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    display: inline-block;
    width: 100%;
    height: 100%;

    @at-root {
        #{"&"} {
            @content; // Add nested styling from the provided block
        }
    }
}