@use "variables" as *;

.find-a-provider-page {
    .search-provider-input-section {
        width: 50%;
        margin-bottom: 2rem;

        @media all and (max-width: 768px) {
            width: 100%;
        }
    }

    .search-provider-input-section>h2 {
        font-weight: 600;
        font-size: 1rem;
        font-family: var(--bs-body-font-family);
        margin-bottom: 0.5rem;
        color: var(--site-accent-text-color);
    }

    .text-input-container {
        position: relative;

        .search-icon {
            position: absolute;
            top: calc(50% - 0.75rem);
            left: 0.75rem;

            &::before {
                font-size: 1rem;
            }
        }
    }

    .search-provider-input-section input[type="text"] {
        width: 100%;
        border-radius: 1.5rem;
        padding: 0.5rem;
        padding-left: 2rem;
        margin-bottom: 0.5rem;
        color: var(--site-accent-text-color);
    }

    .search-provider-disclaimer-section {
        color: var(--site-accent-text-color);
    }

    #mapCanvas {
        height: 100%;
        width: 100%;
        overflow: hidden;
        border-radius: 2rem;

        #mapCanvas>div:nth-child(2)>div.gmnoprint.gm-style-cc>div:nth-child(2)>a {
            display: none;
        }

        #mapCanvas>div:nth-child(2)>div:nth-child(2)>a>div>img {
            display: none;
        }

        #mapCanvas>div:nth-child(2)>div:nth-child(3)>div>div:nth-child(2)>span {
            display: none;
        }

        #mapCanvas>div:nth-child(2)>div:nth-child(7)>div:nth-child(2)>a {
            display: none;
        }

        #mapCanvas>div:nth-child(2)>div:nth-child(7)>div:nth-child(2) {
            display: none;
        }
    }

    .search-provider-content-section {
        display: flex;
        width: 100%;

        >div {
            width: 50%;
            height: 446px;
            overflow: auto;

            // Scrollbar styling
            &::-webkit-scrollbar {
                width: 10px;
            }

            &::-webkit-scrollbar-track {
                background: transparent;
                border-radius: 10px; // Rounded corners
            }

            &::-webkit-scrollbar-thumb {
                background: #b0b0b0; // Medium gray thumb
                border-radius: 10px; // Rounded corners
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #909090; // Darker gray on hover
            }
        }

        @media all and (max-width: 768px) {
            flex-wrap: wrap-reverse;

            >div {
                width: 100%;
                &.FndASrgnResults {
                    margin-top: 1rem;
                }
            }
        }
    }

    .FndASrgnResultsItem {
        display: inline-block;
        width: 100%;
        text-decoration: none;
        border-radius: 2rem;
        padding: 1rem;
        margin-bottom: 1rem;
        color: var(--site-primary-color);

        &:hover,
        &:active,
        &:focus-within {
            background-color: $white;
        }
    }

    .name-section {
        display: flex;
        align-items: center;

        .index {
            >span {
                width: 30px;
                height: 30px;
                background: #C2BDED;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 0.5rem;
                color: var(--site-primary-color);
                border: 3px solid white;
                font-size: 12px;
            }
        }

        .name {
            margin-left: 0.5rem;
        }
    }

    .find-me-section {
        color: var(--site-accent-text-color);
        display: flex;
        flex-wrap: wrap;

        .physical-section {
            font-weight: 400;
            flex: 1;
        }

        .remote-section {
            padding-right: 1rem;
            font-weight: 600;

            @media all and (max-width:$screen-md) {
                width: 100%;
                margin-top: 0.5rem;
            }
            >div {
                display: flex;
                margin-bottom: 0.33rem;
                >i {
                    width: 1rem;
                    margin-right: 0.5rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: var(--site-primary-color);
                }
            }
        }
    }

    .additional-info-section {

        .more-btn:hover,
        .more-btn:active,
        .more-btn:focus {
            text-decoration: underline;
        }
    }

    .name-section,
    .find-me-section,
    .additional-info-section {
        font-size: 14px;
        margin-bottom: 0.5rem;
    }


    .FndASrgnResultsItemImage {
        float: left;
        margin-right: 2%;
        width: 15%;
        background: #8b8e83;
    }

    .FndASrgnResultsItemImage img {
        width: 100%;
    }

    .FndASrgnDtlsCntct {
        float: left;
        margin-bottom: 15px;
        width: 50%;
        min-height: 260px;
    }

    .FndASrgnDtlsCntctLeft {
        float: left;
        width: 24%;
        background: #8b8e83;
    }

    .FndASrgnDtlsCntctLeft img {
        width: 50%;
    }

    .FndASrgnDtlsCntctRight {
        float: right;
        width: 50%;
    }

    .FndASrgnDtlsCntct h3 {
        margin-bottom: 15px;
    }

    .FndASrgnDtlsCntct table {
        width: 100%;
    }

    .FndASrgnDtlsCntct th,
    .FndASrgnDtlsCntct td {
        padding: 0px 0px 10px 0px;
        text-align: left;
        vertical-align: top;
    }

    .FndASrgnDtlsCntct th,
    .FndASrgnDtlsCntct td,
    .FndASrgnDtlsCntct div {
        color: #000000;
        font-size: 14px;
        font-weight: normal;
    }

    .FndASrgnHeader {
        color: #1f365c;
    }

    .FndASrgnHeader1 {
        width: 75%;
        float: right;
    }

    .FndASrgnDtlsCntct th {
        padding-right: 10px;
        white-space: nowrap;
        color: #404040;
    }

    .FndASrgnDtlsCntct td {
        width: 100%;
    }

    .FndASrgnDtlsMap {
        position: relative;
        /* float: right; */
        margin-bottom: 15px;
        width: 0;
        padding: 10%;
        background: #fff;
        padding-top: 10px;
        padding-bottom: 10px;
        height: 250px;
        margin: 5%;
        display: inline;
    }

    .FndASrgnDtlsMapFrm {
        position: absolute;
        left: 10px;
        right: 10px;
        top: 10px;
        bottom: 10px;
        height: 250px;
    }

    .FndASrgnDtlsMapFrm iframe {
        position: absolute;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
        width: 100%;
        height: 100%;
    }

    .noResultsMini {
        display: none;
    }

    .noResultsLarge {
        display: block;
    }

    .FndASrgnHeader {
        color: #1f365c;
    }

    @media screen and (max-width: 991px) and (min-width: 0) {
        .FndASrgnDtlsMap {
            position: relative;
            float: none;
            width: 0;
            padding: 45% !important;
            background: #fff;
            display: inherit;
            margin: 0% 5%;
        }

        .FndASrgnHeader1 {
            padding: 0% 5%;
            width: auto;
            float: none;
        }

        .FndASrgnDtlsCntct {
            padding: 0% 5%;
            width: auto;
            min-height: unset;
            margin-bottom: 0;
        }

        .FndASrgnDtlsCntctRight {
            float: none;
            width: 100%;
        }
    }

    @media screen and (min-width:0px) and (max-width:991px) {
        .FndASrgnDtlsCntctLeft {
            display: none;
        }

        .FndASrgnDtlsCntctRight {
            float: none;
            width: auto;
        }
    }

    @media screen and (min-width:0px) and (max-width:991px) {
        .noResultsMini {
            display: block;
        }

        .noResultsLarge {
            display: none;
        }

        .FndASrgnDtlsCntct {
            display: inline-block;
            float: none;
            width: 100%;
        }

        .FndASrgnDtlsMap {
            position: relative;
            float: none;
            width: 0px;
            padding: 50%;
            background: #ffffff;
        }
    }

    .HighlightedSurgeon {
        border: 2px solid var(--site-primary-color) !important;
    }

    .FndASrgnMn .requiredTooltip {
        left: 10%;
        bottom: 40px !important;
    }
}