@use '_variables' as *;
@use "typography" as *;

html, body {
    background: $beige;
}

.idsa-main-content {
    position: relative;
    z-index: 1;
    background: $beige;
    border-radius: 0 0 toRem(38) toRem(38);

    &::before, &::after {
        display: table;
        width: 100%;
        content: "";
    }
}

.main-content-container {
    padding-bottom: $main-content-container-padding-bottom;
}
.idsaAdvertisement {
    text-align: center;

    iframe {
        max-width: 100%;
    }

    &.idsaAdvertisement-banner {
        background: #313131;
        position: relative;
        padding: toRem(32) 0;
        z-index: 1;

        .idsa-ad-close {
            position: absolute;
            top: .75rem;
            right: 1rem;
            cursor: pointer;
        }

        .idsaAdvertisement-header {
            margin-bottom: toRem(20);
            color: $beige;
            font-size: toRem(12);
            font-weight: $fw_bold;
            line-height: 1.68;
            text-transform: uppercase;
            letter-spacing: toRem(1.92);
        }
    }

    &.idsaAdvertisement-interstitial {
        margin: toRem(48) 0;
        padding: toRem(28) 0;
        background: $dark-beige;
    }

    &.idsaAdvertisement-sidebar {
        margin: 0 0 toRem(36) 0;
        padding: toRem(24);
        background: $dark-beige;
        border-radius: toRem(35);

        .idsaAdvertisement-header {
            margin-bottom: toRem(16);
            color: $dark-grey;
            font-size: toRem(16);
            font-weight: $fw_medium;
            line-height: .99;
            text-transform: uppercase;
        }
    }
}
.bravoSearchResults {
    .idsaAdvertisement {
        &.idsaAdvertisement-sidebar {margin-top:toRem(36);}
    }
}

.two-column-page-container {
    margin-bottom: toRem(64);

    .container, .container-fluid, .container-xxxl, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
    }

    .two-column-page-row {
    }

    .two-column-page-col-left {
        flex: 1 1 100%;
    }

    .two-column-page-col-right {
        flex: 1 1 100%;
    }

    @media all and (min-width:$screen_lg_min) {
        .two-column-page-row {
            flex-wrap: nowrap;
            justify-content: space-between;
        }

        .two-column-page-col-left {
            max-width: toRem(900);
        }

        .two-column-page-col-right {
            flex: 0 0 toRem(368);
            width: toRem(368);
        }
    }
}

.page-title {
    margin-block: toRem(34) toRem(56);
    display: grid;
    gap: toRem(32);
    color: var(--site-primary-color);

    @media all and (min-width:$screen_lg_min) {
        grid-template-columns: repeat(2, 1fr);
        margin-block: toRem(146);
    }

    &-heading {
        display: flex;
        flex-direction: column;
        justify-content: center;
        grid-row: 2;

        @media all and (min-width:$screen_lg_min) {
            grid-row: auto;
        }

        &__backlink {
            margin-block-end: toRem(16);

            a {
                cursor: pointer;
                font-weight: $fw_semibold;
                color: var(--site-accent-text-color);
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }

                i.fa {
                    margin-inline-end: toRem(8);
                }
            }
        }

        &__title {
            margin-block: 0;
            text-wrap: balance;
        }

        &__summary {margin-top:toRem(20); color:$body-alt-color; text-wrap: balance;}

        &__info {
            margin-block-start: toRem(24);
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            column-gap: toRem(36);
            row-gap: toRem(20);

            .info-item {
                display: grid;
                grid-template-columns: toRem(36) auto;
                column-gap: toRem(8);

                &__icon {
                    background-color: $grey-purple;
                    border-radius: 50%;
                    width: toRem(36);
                    height: toRem(36);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-self: center;
                    grid-column: 1;
                    grid-row: 1 / span 2;
                }

                &__heading {
                    grid-column: 2;
                    margin-block-end: 0;
                    line-height: 1.49;
                }

                &__content {
                    grid-column: 2;
                    margin-block-end: 0;
                    color: $dark-grey;
                    line-height: 1.49;
                }
            }
        }
    }

    &-masonry {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        align-items: center;
        gap: toRem(16);
        grid-row: 1;

        @media all and (min-width:$screen_lg_min) {
            grid-row: auto;
        }

        &__item {
            overflow: hidden;
            border-radius: toRem(32);

            &:only-child {
                @media all and (min-width:$screen_lg_min) {
                    grid-column-start: 2;
                }
            }

            &:first-child {
                border-radius: 50%;
            }

            img {
                aspect-ratio: 1 / 1;
                object-fit: cover;
                max-width: 100%;
                width: 100%;
            }
        }
    }
}

.blog-listing-page-title {
    margin-block: toRem(34) toRem(56);
    display: grid;
    gap: toRem(32);
    color: var(--site-primary-color);

    @media all and (min-width:$screen_lg_min) {
        grid-template-columns: repeat(2, 1fr);
        margin-block: toRem(146);
    }

    &-heading {
        display: flex;
        flex-direction: column;
        justify-content: center;
        grid-row: 2;

        @media all and (min-width:$screen_lg_min) {
            grid-row: auto;
        }

        &__title {
            margin-block: 0;
            text-wrap: balance;
        }

        &__info {
            margin-block-start: toRem(24);
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            column-gap: toRem(36);
            row-gap: toRem(20);

            .info-item {
                display: grid;
                grid-template-columns: toRem(36) auto;
                column-gap: toRem(8);

                &__icon {
                    background-color: $grey-purple;
                    border-radius: 50%;
                    width: toRem(36);
                    height: toRem(36);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-self: center;
                    grid-column: 1;
                    grid-row: 1 / span 2;
                }

                &__heading {
                    grid-column: 2;
                    margin-block-end: 0;
                    line-height: 1.49;
                }

                &__content {
                    grid-column: 2;
                    margin-block-end: 0;
                    color: $dark-grey;
                    line-height: 1.49;
                }
            }
        }
    }
    &-links {
        list-style-type: none;
        padding-left: 0;
        margin-top: 1.8rem;
        margin-left: 0.75rem;
        display: flex;
        flex-wrap: wrap;
        a {
            @extend %btn-cta-arrow;
            display: flex;
            align-items: center;
            margin-right: 1rem;
            margin-bottom: 1rem;
            > span {
                transform: rotate(90deg);
            }
        }
    }
    &-masonry {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        align-items: center;
        gap: 2rem;
        grid-row: 1;

        @media all and (min-width:$screen_lg_min) {
            grid-row: auto;
        }

        &__item {
            overflow: hidden;
            border-radius: toRem(32);

            &:only-child {
                @media all and (min-width:$screen_lg_min) {
                    grid-column-start: 2;
                }
            }

            &:first-child {
                border-radius: 50%;
            }

            img {
                aspect-ratio: 1 / 1;
                object-fit: cover;
                max-width: 100%;
                width: 100%;
            }
        }
    }
}

.page-anchors-wrapper {
    margin-block-end: toRem(36);

    @media all and (min-width:$screen_lg_min) {
        margin-block-end: toRem(42);
    }

    .page-anchors {
        padding-block-start: toRem(20);
        border-block-start: 1px solid $neutral-400;
        display: flex;
        white-space: nowrap;
        gap: toRem(46);

        &-byline {
            display: none;
            font-weight: $fw_semibold;
            color: $body-alt-color;
            align-items: center;

            @media all and (min-width:$screen_md_min) {
                display: inline-flex;
            }
        }

        &-content {
            --btn-gutter-x: #{toRem(2)};
            --btn-gutter-y: #{toRem(8)};
            --btn-height: #{toRem(32)};
            --btn-width: #{toRem(32)};
            display: flex;
            align-items: center;
            position: relative;
            overflow: hidden;

            @media all and (min-width:$screen_md_min) {
                height: calc(var(--btn-height) + var(--btn-gutter-y) * 2);
            }

            &__btn {
                color: $green;
                position: absolute;
                top: var(--btn-gutter-y);
                padding: 0;
                margin: 0;
                border-radius: 50%;
                border: 1px solid $green;
                background-color: $beige;
                width: var(--btn-width);
                height: var(--btn-height);
                transition: transform 0.1s ease-in, box-shadow 0.1s ease-in, opacity 0.1s ease-in;
                transform: scale(0);
                z-index: 10;
                pointer-events: none;
                display: none;

                @media all and (min-width:$screen_md_min) {
                    display: block;
                }

                &-prev {
                    left: var(--btn-gutter-x);

                    &:hover {
                        box-shadow: calc(var(--btn-gutter-x) * 1) var(--btn-gutter-x) calc(var(--btn-gutter-y) * 0.5) rgba(0,0,0,0.12)
                    }
                }

                &-next {
                    right: var(--btn-gutter-x);

                    &:hover {
                        box-shadow: calc(var(--btn-gutter-x) * -1) var(--btn-gutter-x) calc(var(--btn-gutter-y) * 0.5) rgba(0,0,0,0.12)
                    }
                }
            }

            &__list {
                list-style-type: none;
                margin: 0;
                padding: 0;
                display: inline-flex;
                align-items: center;
                gap: toRem(24);
                max-width: 100%;
                white-space: nowrap;
                overflow-x: auto;
                scrollbar-width: none;
                scroll-snap-type: x proximity;

                &::before, &::after {
                    pointer-events: none;
                    content: '';
                    position: absolute;
                    height: 100%;
                    width: toRem(80);
                    top: 0;
                    opacity: 0;

                    @media all and (min-width:$screen_md_min) {
                        width: toRem(160);
                    }
                }

                &::before {
                    left: 0;
                }

                &::after {
                    right: 0;
                }

                &::-webkit-scrollbar {
                    display: none; /* Safari and Chrome */
                }

                li {
                    display: inline;
                    scroll-snap-align: start;

                    a {
                        cursor: pointer;
                        font-weight: $fw_semibold;
                        color: var(--site-accent-text-color);
                        text-decoration: none;
                        vertical-align: middle;
                        display: inline-flex;
                        align-items: baseline;

                        &:hover {
                            color: var(--site-accent-text-color);
                        }

                        span {
                            display: inline-block;
                            max-width: 20ch;
                            overflow: hidden;
                            text-overflow: ellipsis;

                            &:hover {
                                text-decoration: underline;
                            }
                        }

                        i.fa {
                            margin-inline-start: toRem(8);
                        }
                    }
                }
            }

            &.has-overflow-start {
                .page-anchors-content__btn {
                    &-prev {
                        transform: scale(1);
                        pointer-events: initial;

                        &:hover {
                            transform: scale(1.1);
                        }
                    }
                }

                .page-anchors-content__list {
                    &::before {
                        opacity: 1;
                        background: linear-gradient(90deg, $beige 0%, transparent 100%);
                    }
                }
            }

            &.has-overflow-end {
                .page-anchors-content__btn {
                    &-next {
                        transform: scale(1);
                        pointer-events: initial;

                        &:hover {
                            transform: scale(1.1);
                        }
                    }
                }

                .page-anchors-content__list {
                    &::after {
                        opacity: 1;
                        background: linear-gradient(270deg, $beige 0%, transparent 100%);
                    }
                }
            }
        }
    }
}

.page-title-wrapper {
    //margin-block: toRem(64);

    .page-title {
    	margin-block: toRem(64);
        min-height: 415px;

        &-heading {

            &__updated {
                margin-block-start: toRem(24);
                display: grid;
                grid-template-columns: toRem(36) auto;
                column-gap: toRem(8);

                .updated-icon {
                    background-color: $grey-purple;
                    border-radius: 50%;
                    width: toRem(36);
                    height: toRem(36);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-self: center;
                    grid-column: 1;
                    grid-row: 1 / span 2;
                }

                .updated-heading {
                    grid-column: 2;
                    margin-block-end: 0;
                    line-height: 1.49;
                }

                .updated-date {
                    grid-column: 2;
                    margin-block-end: 0;
                    color: $dark-grey;
                }
            }
        }

        &-register {
            display: flex;
            align-items: center;
            gap: toRem(32);
            margin-top: toRem(32);

            &-button {
                display: flex;
                padding: toRem(20) toRem(32);
                justify-content: center;
                gap: toRem(8);
                background: $yellow;
                border-radius: toRem(12);

                &::after {
                    @include font-family-fas();
                    display: inline-block;
                    align-self: center;
                    font-size: 0.65em;
                    line-height: 1.693em;
                    content: "\f054";
                }
            }

            &-link {
                display: flex;
                justify-content: center;
                gap: toRem(8);

                &::after {
                    @include font-family-fas();
                    display: inline-block;
                    align-self: center;
                    font-size: 0.65em;
                    line-height: 1.693em;
                    content: "\f054";
                    transform: rotate(-45deg) translateY(-0.2rem);
                    margin-inline-start: toRem(8);
                }
            }
        }

        &-multimedia {
            display: flex;
            align-items: center;

            @media all and (min-width:$screen_md_min) {
                grid-row: auto;
            }

            &__item {
                overflow: hidden;
                border-radius: toRem(32);

                &-pill {
                    img {
                        width: 100%;
                        border-radius: toRem(181);
                    }
                }

                &-rounded {
                    img {
                        width: 100%;
                        border-radius: toRem(50);
                    }
                }
            }
        }
    }
}

*:has(> iframe.embed-responsive-item) {
    position: relative;
    padding-bottom: 50%;
    height: 0;
    overflow: hidden;
    max-width: 100%;

    > iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    // maybe this works
}